import styled, { css } from 'styled-components';
import { TableRow } from '@material-ui/core';

export const Container = styled(TableRow)`
  && {
    position: relative;

    > a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    ${(props) =>
      props.clickable &&
      css`
        cursor: pointer;

        &:hover {
          background: #bbb;
        }
      `}
  }
`;
