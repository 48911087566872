import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setDisplay2 } from '@/Store/Actions/infoDisplayAction';

import DecimalNumber from '@/Components/DecimalNumber';

import { Container, Header, Content, RowDiv } from './styles';
import BreakLine from '@/Components/BreakLine';

function GeralInfo({ props }) {
  const dispatch = useDispatch();
  const display = useSelector((state) => state.infoDisplay.display2);

  function handleDisplay() {
    dispatch(setDisplay2(false));
  }

  return (
    <Container
      visible={display}
      onClose={handleDisplay}
      top={120}
      left={11}
    >
      <Header>
        <RowDiv>
          <strong>OP</strong>
          <span style={{ color: '#fff' }}>{props.op}</span>
        </RowDiv>
        <RowDiv>
          <strong>Cultura</strong>
          <span style={{ color: '#fff' }}>{props.Cultura}</span>
        </RowDiv>
      </Header>

      <BreakLine />

      <Content>
        <RowDiv>
          <strong>Orçado R$</strong>
          <DecimalNumber type="colored">
            {props.totalOrcado}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>Realizado R$</strong>
          <DecimalNumber type="colored">
            {props.custoTotal}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>Receita R$</strong>
          <DecimalNumber type="colored">
            {props.totalReceita}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>Resultado R$</strong>
          <DecimalNumber type="colored">
            {props.lucroRealizado}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>Qtd. Unid. Com.</strong>
          <DecimalNumber type="colored">{props.TCX}</DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>{props.unidadeComercial}</strong>
          <DecimalNumber type="colored">{props.VLRTCX}</DecimalNumber>
        </RowDiv>

        <BreakLine />

        <RowDiv>
          <strong>Orçado/ha R$</strong>
          <DecimalNumber type="colored">
            {props.totalOrcadoha}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>Realizado/ha R$</strong>
          <DecimalNumber type="colored">
            {props.custoha}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>Receita/ha R$</strong>
          <DecimalNumber type="colored">
            {props.receitaha}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>Resultado/ha R$</strong>
          <DecimalNumber type="colored">
            {props.lucroRealizadoha}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>{props.unidadeComercial}</strong>
          <DecimalNumber type="colored">{props.TCXHA}</DecimalNumber>
        </RowDiv>
      </Content>
    </Container>
  );
}

export default GeralInfo;
