import styled from 'styled-components';

export const Container = styled.div``;

export const SubmitArea = styled.div`
  display: flex;
  flex-direction: column;

  padding: 10px 20px;
`;

export const Date = styled.div`
  display: flex;

  > strong {
    margin-right: 8px;
  }

  @media (max-width: 800px) {
    justify-content: space-between;
  }
`;

export const ChooseDate = styled.div`
  display: flex;
  justify-content: space-between;

  > input {
    width: 145px;
  }

  @media (max-width: 800px) {
    flex-direction: column;

    > div + div {
      margin-top: 8px;
    }
  }
`;

export const ChooseOthers = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 8px;

  @media (max-width: 800px) {
    flex-direction: column;

    > div + div {
      margin-top: 8px;
    }
  }
`;

export const Option = styled.div`
  display: flex;

  > strong {
    margin-right: 8px;
  }

  > select {
    width: 145px;
  }

  @media (max-width: 800px) {
    justify-content: space-between;
  }
`;
