import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Map from '@/Components/Map';
import Trap from '@/Components/Trap';
import LoadPage from '@/Components/LoadPage';
import ArmadilhasInfo from '@/Components/ArmadilhasInfo';

import { setDisplay } from '@/Store/Actions/infoDisplayAction';
import api from '@/Services/api';

import { Container, OpenInfoDisplay, EyeIcon } from './styles';
import Subarea from '@/Components/Subarea';

function Armadilhas(props) {
  const [trapsVerified, setTrapsVerified] = useState(null);
  const [trapsNotVerified, setTrapsNotVerified] = useState(null);
  const [center, setCenter] = useState([0, 0]);
  const [areas, setAreas] = useState([]);

  const token = localStorage.getItem('token');
  const dispatch = useDispatch();
  const search = props.location.search.split('&');

  const data1 = search[0].slice(7);
  const data2 = search[1].slice(6);
  const farms = decodeURIComponent(search[2].slice(6));

  useEffect(() => {
    dispatch(setDisplay(false));

    api
      .get(
        `/Armadilhas/getTraps?data1=${data1}&data2=${data2}&farms=${farms}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const { verified, notVerified, areas } = response.data;

        setAreas(areas);
        setTrapsVerified(verified);
        setTrapsNotVerified(notVerified);
        setCenter(
          verified[0] !== undefined
            ? verified[0].position
            : notVerified[0].position
        );
      });
    //eslint-disable-next-line
  }, [token, data1, data2]);

  if (trapsVerified === null || trapsNotVerified === null) {
    return <LoadPage />;
  }

  return (
    <Container>
      <Map props={{ center, zoom: 13 }}>
        {areas.map((area) => (
          <Subarea
            key={area.id}
            positions={area.geo}
            name={area.subarea}
          />
        ))}

        {trapsVerified.length > 0 &&
          trapsVerified.map((trap) => (
            <Trap
              key={trap.id}
              props={{
                position: trap.position,
                status: trap.qtd,
                id: trap.id,
                armadilha: trap.armadilha,
                data1: data1,
                data2: data2,
              }}
            />
          ))}

        {trapsNotVerified.length > 0 &&
          trapsNotVerified.map((trap) => (
            <Trap
              key={trap.id}
              props={{
                status: 'notVerified',
                position: trap.position,
                id: trap.id,
                armadilha: trap.armadilha,
                data1: data1,
                data2: data2,
              }}
            />
          ))}
      </Map>

      <OpenInfoDisplay onClick={() => dispatch(setDisplay(true))}>
        <EyeIcon />
      </OpenInfoDisplay>

      <ArmadilhasInfo props={{ data1, data2, farms }} />
    </Container>
  );
}

export default Armadilhas;
