import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import api from '@/Services/api';
import { setDisplay } from '@/Store/Actions/infoDisplayAction';

import Map from '@/Components/Map';
import LoadPage from '@/Components/LoadPage';

import GeralInfo from './Components/GeralInfo';
import Eye from './Components/Eye';
import IntervalValues from './Components/IntervalValues';
import InfoArea from './Components/InfoArea';

import { Container, Area, Identifier } from './styles';

function Agros(props) {
  const [data, setData] = useState([]);
  const [geralInfo, setGeralInfo] = useState([]);
  const [infoArea, setInfoArea] = useState([]);
  const [center, setCenter] = useState([0, 0]);
  const [baseValues, setBaseValues] = useState({});

  const token = localStorage.getItem('token');
  const search = decodeURIComponent(props.location.search).split('&');
  const dispatch = useDispatch();

  const numero = search[0].slice(8);
  const controle = search[1].slice(9);
  const cultura = search[2].slice(8);

  useEffect(() => {
    getBaseValues(cultura, token, controle, numero);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cultura, token, controle, numero]);

  useEffect(() => {
    api
      .get(
        `/Mapas/Agros/getGeralInfo?numero=${numero}&controle=${controle}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then(({ data }) => setGeralInfo(data));
  }, [token, numero, controle]);

  async function getBaseValues(cultura, token, controle, numero) {
    const { data } = await api.get(
      `/getBaseValues?cultura=${cultura}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    setBaseValues(data);

    await getAreas(controle, numero, token, data);
  }

  async function getAreas(controle, numero, token, baseValues) {
    await api
      .get(
        `/Mapas/Agros/getAreas?numero=${numero}&controle=${controle}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
          params: {
            baseValues,
          },
        }
      )
      .then((response) => {
        setData(response.data);
        setCenter(response.data[0].geo[0]);
      });
  }

  function getInfoArea(subarea) {
    setInfoArea([]);

    api
      .get(
        `/Mapas/Agros/getInfoArea?numero=${numero}&controle=${controle}&subarea=${subarea}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then(({ data }) => setInfoArea(data));
  }

  if (data.length === 0) {
    return <LoadPage />;
  }

  return (
    <Container>
      <Map props={{ center, zoom: 13 }}>
        {data.map((value) => (
          <Area
            key={value.subarea}
            level={value.media}
            positions={value.geo}
            onClick={() => {
              dispatch(setDisplay(true));
              getInfoArea(value.subarea);
            }}
          >
            <Identifier>{value.subarea}</Identifier>
          </Area>
        ))}
      </Map>
      <Eye />
      <GeralInfo props={geralInfo} />
      <InfoArea props={infoArea} />
      <IntervalValues values={baseValues} />
    </Container>
  );
}

export default Agros;
