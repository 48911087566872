const INITIAL_STATE = {
  farms: [],
};

export default function farmsSelected(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'setSelectedFarms':
      return {
        ...state,
        farms: action.selectedFarms,
      };

    default:
      return state;
  }
}
