import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { setDisplay } from '../../Store/Actions/infoDisplayAction';
import api from '../../Services/api';
import formatdate from '../../Utils/formatDate';

import {
  Container,
  Header,
  ColumnDiv,
  RowDiv,
  Button,
  CloseIcon,
} from './styles';

function ArmadilhasInfo({ props }) {
  const [data, setData] = useState([]);

  const token = localStorage.getItem('token');
  const dispatch = useDispatch();
  const display = useSelector((state) => state.infoDisplay.display);

  useEffect(() => {
    api
      .get(
        `/Armadilhas/getInfoArea?data1=${props.data1}&data2=${props.data2}&farms=${props.farms}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => setData(response.data));
  }, [token, props]);

  if (data.length === 0) {
    return null;
  }

  return (
    <Container display={String(display)}>
      <CloseIcon onClick={() => dispatch(setDisplay(false))} />

      <ColumnDiv outlined="true">
        <RowDiv>
          <strong>Data Inicial</strong>
          <span>{formatdate(props.data1)}</span>
        </RowDiv>

        <RowDiv>
          <strong>Data Final</strong>
          <span>{formatdate(props.data2)}</span>
        </RowDiv>

        <RowDiv>
          <strong>Dias</strong>
          <span>{data.days}</span>
        </RowDiv>
      </ColumnDiv>

      <hr />

      <ColumnDiv>
        <Header>Armadilhas</Header>

        <RowDiv>
          <strong>Instaladas</strong>
          <span>{data.total}</span>
        </RowDiv>

        <RowDiv>
          <strong>Verificações</strong>
          <span>{data.verified}</span>
        </RowDiv>

        <RowDiv>
          <strong>Não Verificadas</strong>
          <span>{data.notVerified}</span>
        </RowDiv>

        <RowDiv>
          <strong>Detectadas</strong>
          <span>{data.detected}</span>
        </RowDiv>
      </ColumnDiv>

      <hr />

      <ColumnDiv>
        <Header>Pragas</Header>

        <RowDiv>
          <strong>Pragas Encontradas</strong>
          <span>{data.plagues}</span>
        </RowDiv>
      </ColumnDiv>

      <Link
        to={`/Mapas/Armadilhas/Resumo?data1=${props.data1}&data2=${props.data2}&days=${data.days}&farms=${props.farms}`}
        target="blank"
      >
        <Button>Resumo</Button>
      </Link>
    </Container>
  );
}

export default ArmadilhasInfo;
