const INITIAL_STATE = {
  display: false,
  loading: true,
  error: false,
  display2: false,
};

export default function infoDisplay(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'setDisplay':
      return {
        ...state,
        display: action.display,
      };

    case 'setDisplay2':
      return {
        ...state,
        display2: action.display2,
      };

    case 'setLoading':
      return {
        ...state,
        loading: action.loading,
      };

    case 'setError':
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}
