import styled, { keyframes } from 'styled-components';
import { Loader } from '../../Styles/Icons';

const spin = keyframes`
  0% {
    transform: rotateZ(0deg);
    color: rgba(4, 139, 3, 1);
  }
  50% {
    color: rgba(166, 166, 0, 1);
    transform: rotateZ(1080deg);
  }
  100% {
    transform: rotateZ(1440deg);

    color: rgba(4, 139, 3, 1);
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const LoadIcon = styled(Loader)`
  margin-left: 8px;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;

  animation: ${spin} 3s cubic-bezier(0.46, 0.7, 0.26, 0.52) infinite;
`;
