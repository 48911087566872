import React from 'react';

import Header from '../Header';

import { Container, Content, Main } from './styles';

function PreMapContainer({ children }) {
  return (
    <Container>
      <Header />
      <Content>
        <Main>{children}</Main>
      </Content>
    </Container>
  );
}

export default PreMapContainer;
