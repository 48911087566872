import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setDisplay2 } from '@/Store/Actions/infoDisplayAction';

import { Container, EyeIcon } from './styles';

function Eye() {
  const dispatch = useDispatch();
  const display = useSelector((state) => state.infoDisplay.display2);

  return (
    <Container
      onClick={() => dispatch(setDisplay2(true))}
      visible={!display}
    >
      <EyeIcon />
    </Container>
  );
}

export default Eye;
