import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import api from '@/Services/api';
import { setSelectedAnimals } from '@/Store/Actions/animals';

import {
  Container,
  Face,
  SearchBox,
  SearchIcon,
  Results,
} from './styles';

function Search() {
  const [display, setDisplay] = useState(false);
  const [search, setSearch] = useState(null);
  const [animals, setAnimals] = useState([]);

  const token = localStorage.getItem('token');
  const dispatch = useDispatch();
  const ref = useRef(null);

  useEffect(() => {
    searchAnimals();

    //eslint-disable-next-line
  }, [search]);

  function searchAnimals() {
    api
      .get(`/Mapas/Animais/getFilteredAnimals?search=${search}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAnimals(response.data);
      });
  }

  return (
    <Container>
      <Face onClick={() => setDisplay(true)}>
        <SearchIcon />
      </Face>

      <SearchBox
        ref={ref}
        visible={display}
        placeholder="Nome, Número, Sigla"
        list="animals"
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />

      <Results id="animals" visible={display}>
        {animals.map((animal) => (
          <li
            key={animal.nome}
            value={animal.nome}
            onClick={() => {
              dispatch(setSelectedAnimals(animal.subarea));
              setDisplay(false);
            }}
          >
            {animal.nome}
          </li>
        ))}
      </Results>
    </Container>
  );
}

export default Search;
