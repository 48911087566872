import { toast } from 'react-toastify';

export function toastWarning({ message }) {
  return toast.warning(message, {
    position: 'top-center',
    bodyStyle: {
      color: 'black',
      fontWeight: 600,
    },
  });
}
