import React from 'react';
import { Provider } from 'react-redux';
import { GlobalStyle } from './Styles/GlobalStyles';
import { QueryClient, QueryClientProvider } from 'react-query';

import Routes from './Routes.js';
import store from './Store';

import './fonts.css';

export const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <GlobalStyle />
        <Routes />
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
