import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { setSelectedFarms } from '../../../../Store/Actions/selectedFarms';

import Container from '../../../../Components/PreMapContainer';
import FarmsContainer from '../../../../Components/FarmsContainer';
import StyledButton from '../../../../Components/Buttons';
import { toastWarning } from '@/Utils/toast';

import { SubmitArea } from './styles';

const FarmRealtime = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const farmsSelected = useSelector(
    (state) => state.selectedFarms.farms
  );

  useEffect(() => {
    dispatch(setSelectedFarms([]));
  }, [dispatch]);

  async function handleSubmit() {
    if (farmsSelected.length === 0) {
      toastWarning({ message: 'Selecione alguma fazenda!' });
    } else {
      history.push(`/Mapas/Realtime?farms=${farmsSelected}`);
    }
  }

  return (
    <Container>
      <ToastContainer />

      <FarmsContainer>
        <SubmitArea>
          <StyledButton onClick={handleSubmit}>Carregar</StyledButton>
        </SubmitArea>
      </FarmsContainer>
    </Container>
  );
};

export default FarmRealtime;
