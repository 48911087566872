import { combineReducers } from 'redux';

import infoDisplay from './infoDisplay';
import tractor from './tractor';
import selectedFarms from './selectedFarms';
import selectedMips from './selectedMips';
import selectedAnimal from './selectedAnimal';

export default combineReducers({
  infoDisplay,
  tractor,
  selectedFarms,
  selectedMips,
  selectedAnimal,
});
