import styled, { keyframes } from 'styled-components';
import { CircleMarker, Tooltip } from 'react-leaflet';
import { Popup } from 'react-leaflet';

const alert = keyframes`
  0%{
    fill: var(--bad)
  }
  50%{
    fill: #4c0303;
  }
  100%{
    fill: var(--bad)

  }
`;

export const Container = styled(CircleMarker).attrs({})`
  fill-opacity: 1;
  stroke-width: 1px;
  stroke: #000;

  fill: ${(props) => {
    if (props.status === 'notVerified') return 'var(--medium)';
    if (props.status > 0) return 'var(--bad)';
    else {
      return 'var(--good)';
    }
  }};

  animation: ${(props) => props.status > 0 && alert} 2s linear
    infinite;
`;

export const Identifier = styled(Tooltip)`
  background: transparent;
  font-weight: bold;
  border: none;
  color: #000;
  box-shadow: none;
`;

export const LPopup = styled(Popup)`
  && {
    display: flex;
    flex-direction: column;

    align-items: center;

    > div > div > div {
      margin-bottom: 8px;
      color: #fff;
      font-size: 16px;

      > strong {
        margin-right: 8px;
      }
    }
  }
`;
