import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import logo from '../../Assets/Logo.png';

import {
  Container,
  Logo,
  LogoUser,
  MenuBurger,
  MenuOptions,
  BurgerIcon,
  Title,
  HomeIcon,
  ExitIcon,
  ManageIcon,
  MapIcon,
} from './styles';

const Header = () => {
  const [toggle, setToggle] = useState(false);

  const currentUser = localStorage.getItem('user');
  const history = useHistory();

  function handleLogout() {
    localStorage.clear();
    history.push('/Login');
  }

  return (
    <Container>
      <LogoUser>
        <Logo src={logo} />
        <span>{currentUser}</span>
      </LogoUser>

      <MenuBurger onClick={() => setToggle(!toggle)}>
        <BurgerIcon />
      </MenuBurger>

      <MenuOptions toggle={toggle} onClick={() => setToggle(true)}>
        <Title to="/Farm">
          <HomeIcon />
          Início
        </Title>
        <Title to="/Mapeamentos">
          <MapIcon />
          Mapas
        </Title>
        <Title to="/compras">
          <MapIcon />
          Compras
        </Title>
        <Title to="/Gestao">
          <ManageIcon />
          Gestão
        </Title>
        <Title onClick={handleLogout} to="/Login">
          <ExitIcon />
          Sair
        </Title>
      </MenuOptions>
    </Container>
  );
};

export default Header;
