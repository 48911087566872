import styled from 'styled-components';

export const RowDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + div {
    margin-top: 10px;
  }

  > strong {
    color: #fff;
    margin-right: 20px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;
