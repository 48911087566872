import styled from 'styled-components';

export const SubmitArea = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 5px 10px;
`;

export const InputGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > strong {
    margin-right: 8px;
  }
`;
