import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { setDisplay } from '@/Store/Actions/infoDisplayAction';

import Button from '@/Components/Buttons';
import IntNumber from '@/Components/IntNumber';
import DecimalNumber from '@/Components/DecimalNumber';

import {
  Container,
  Header,
  RowDiv,
  Content,
  CloseIcon,
} from './styles';

function InfoArea({ props }) {
  const display = useSelector((state) => state.infoDisplay.display);
  const dispatch = useDispatch();
  const valorBoi = localStorage.getItem('valorBoi');

  return (
    <Container visible={display}>
      <Header>
        <CloseIcon onClick={() => dispatch(setDisplay(false))} />
        <strong>Dados da Área</strong>
      </Header>
      <Content>
        <RowDiv>
          <strong>Fazenda</strong>
          <span>{props.fazenda}</span>
        </RowDiv>
        <RowDiv>
          <strong>Subarea</strong>
          <span>{props.subarea}</span>
        </RowDiv>
        <RowDiv>
          <strong>Total</strong>
          <IntNumber type="colored">{props.qtdTotal}</IntNumber>
        </RowDiv>
        <RowDiv>
          <strong>Fêmeas</strong>
          <IntNumber type="colored">{props.qtdFemea}</IntNumber>
        </RowDiv>
        <RowDiv>
          <strong>Machos</strong>
          <IntNumber type="colored">{props.qtdMacho}</IntNumber>
        </RowDiv>
        <RowDiv>
          <strong>Peso Total</strong>
          <DecimalNumber type="colored">
            {props.totalPeso}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>UA Total</strong>
          <DecimalNumber type="colored">
            {props.totalUA}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>@ Total</strong>
          <DecimalNumber type="colored">
            {props.totalArroba}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>Média de Peso</strong>
          <DecimalNumber type="colored">
            {props.mediaPeso}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>Média de UA</strong>
          <DecimalNumber type="colored">
            {props.mediaUA}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>Média @</strong>
          <DecimalNumber type="colored">
            {props.mediaArroba}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>Cotação @</strong>
          <DecimalNumber type="colored">
            {parseFloat(valorBoi)}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>Valor Médio</strong>
          <DecimalNumber type="colored">
            {props.mediaValor}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>Valor Total</strong>
          <DecimalNumber type="colored">
            {props.valorTotal}
          </DecimalNumber>
        </RowDiv>

        <Link
          target="blank"
          to={`/Mapas/Animais/Resumo?especie=${props.especie}&raca=${props.raca}&subarea=${props.subarea}`}
        >
          <Button style={{ width: '100%' }}>Ver Resumo</Button>
        </Link>
      </Content>
    </Container>
  );
}

export default InfoArea;
