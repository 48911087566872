import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  padding: 70px;

  @media (max-width: 700px) {
    padding: 70px 15px 30px;
  }
`;

export const Resumo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 80vh;
  overflow: auto;
  border-radius: 10px;
  border: 1px solid var(--dark-green);
`;

export const Header = styled.div`
  width: 100%;
  display: flex;

  > strong {
    color: #fff;
    background: var(--dark-green);
    min-width: 120px;
    padding: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  width: fit-content;
`;

export const Amostra = styled.div`
  display: flex;
  width: 100%;

  > span {
    width: 100%;
    min-width: 120px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &:nth-child(even) {
    > span {
      background: #bbb;
    }
  }
`;
