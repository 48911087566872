import styled, { keyframes } from 'styled-components';

import { IoIosSearch } from '@/Styles/Icons';

export const Container = styled.div`
  display: block;

  position: absolute;
  left: 11px;
  top: 82px;
  z-index: 10000;
`;

export const Face = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 26px;
  height: 26px;
  border-radius: 5px;
  background: #fff;

  position: absolute;
  top: 0;
  left: 0;

  cursor: pointer;
`;

const expanse = keyframes`
  0%{width: 26px;}
  50%{width: 102px;}
  100%{width: 179px;}
`;

export const SearchBox = styled.input`
  position: absolute;
  top: 0px;
  left: 0;

  display: ${(props) => (props.visible ? 'flex' : 'none')};

  border: none;
  border-radius: 5px;
  height: 26px;
  padding: 5px;

  animation: ${expanse} 0.3s linear;
  transition: 0.2s;

  &:focus {
    border: 2px solid aqua;
  }
`;

export const Results = styled.ul`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: absolute;
  top: 28px;

  border-radius: 3px;
  padding: 6px;
  background: #eee;
  width: 179px;

  > li {
    font-weight: 500;
    color: #000;

    display: flex;
    align-items: center;

    padding: 3px;
    cursor: pointer;

    &:hover {
      background: #bff7f3;
    }
  }
`;

export const SearchIcon = styled(IoIosSearch)`
  color: #000;
  width: 80%;
  height: 80%;
`;
