import styled from 'styled-components';
import { Polygon, Tooltip } from 'react-leaflet';

export const Container = styled.div``;

export const Area = styled(Polygon)`
  fill: ${(props) => {
    if (props.level === 'level1') return '#ff0000';
    if (props.level === 'level2') return '#ff7f00';
    if (props.level === 'level3') return '#ff0';
    if (props.level === 'level4') return '#ace201';
    if (props.level === 'level5') return '#1ee201';
    else return '#fff';
  }};

  stroke: ${(props) => {
    if (props.level === 'level1') return '#ff0000';
    if (props.level === 'level2') return '#ff7f00';
    if (props.level === 'level3') return '#ff0';
    if (props.level === 'level4') return '#ace201';
    if (props.level === 'level5') return '#1ee201';
    else return '#fff';
  }};

  fill-opacity: 0.4;
  transition: 0.2s;

  &:hover {
    fill-opacity: 0.8;
  }
`;

export const Identifier = styled(Tooltip).attrs(() => ({
  direction: 'center',
  permanent: true,
}))`
  background: rgb(0, 0, 0, 0.4);
  border: 1px solid #000;
  border-radius: 5px;
  box-shadow: 3px 3px 5px #000;

  color: #fff;
  font-weight: bold;
  font-size: 10px;

  padding: 3px;
`;
