const INITIAL_STATE = {
  subarea: '',
};

export default function selectedAnimal(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'setSelectedAnimal':
      return {
        ...state,
        subarea: action.subarea,
      };

    default:
      return state;
  }
}
