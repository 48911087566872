import React, { useEffect, useState } from 'react';

import api from '@/Services/api';

import Container from '@/Components/Map';
import Cocho from '@/Components/Cocho';

function Cochos(props) {
  const [cochos, setCochos] = useState([]);
  const [center, setCenter] = useState([0, 0]);

  const token = localStorage.getItem('token');
  const search = decodeURIComponent(props.location.search).split('&');

  const farms = search[0].slice(7);

  useEffect(() => {
    api
      .get(`/Mapas/Cochos?farms=${farms}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setCochos(response.data);
        setCenter(response.data[0]);
      });
  }, [token, farms]);

  return (
    <Container props={{ center, zoom: 13 }}>
      {cochos.map((cocho) => (
        <Cocho position={cocho} />
      ))}
    </Container>
  );
}

export default Cochos;
