/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';

import formatDate from '@/Utils/formatDate';
import { setSelectedMips } from '@/Store/Actions/setSelectedMips';

import {
  Mip,
  ArrowUp,
  Content,
  Container,
  MipHeader,
  MipContent,
  Triangle,
  Circle,
} from './styles';

function MIPContainer({ props }) {
  const [allSelected, setAllSelected] = useState(false);

  const date = formatDate(props[0].data);
  const dispatch = useDispatch();

  const selectedMips = useSelector(
    (state) => state.selectedMips.mips
  );

  function setAllDate() {
    const isAlreadyAllSelected = selectedMips.length === props.length;

    if (isAlreadyAllSelected) {
      dispatch(setSelectedMips([]));
      return;
    }

    const mips = props?.map((mip) => {
      const parsedDate = mip.data.slice(0, 10);

      return {
        mip: `${mip.os}${mip.praga}${mip.subarea}${parsedDate}`,
        data: {
          os: mip.os,
          praga: mip.praga,
          result: mip.resultado,
          subarea: mip.subarea,
        },
      };
    });

    dispatch(setSelectedMips([...selectedMips, ...mips]));
  }

  function handleSelected(os, subarea, praga, data, result) {
    const parsedDate = data.slice(0, 10);

    const alreadySelected = selectedMips.findIndex(
      ({ mip }) => mip === `${os}${praga}${subarea}${parsedDate}`
    );

    if (alreadySelected >= 0) {
      const filteredMips = selectedMips.filter(
        ({ mip }) => mip !== `${os}${praga}${subarea}${parsedDate}`
      );

      dispatch(setSelectedMips(filteredMips));
    } else {
      const newData = {
        mip: `${os}${praga}${subarea}${parsedDate}`,
        data: {
          os,
          praga,
          result,
          subarea,
        },
      };

      dispatch(setSelectedMips([...selectedMips, newData]));
    }
  }

  return (
    <Container style={{ width: '100%' }}>
      <Content>
        <AccordionSummary expandIcon={<ArrowUp />}>
          <input
            style={{ marginRight: 10 }}
            type="checkbox"
            checked={allSelected}
            onChange={(e) => {
              e.stopPropagation();

              setAllDate(e.currentTarget.checked);
              setAllSelected(!allSelected);
            }}
          />

          <Typography>{date}</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Mip>
            <MipHeader>
              <span style={{ width: '8.6rem' }}></span>
              <span>OS</span>
              <span>Fazenda</span>
              <span>Subarea</span>
              <span>Previstas</span>
              <span>Vistoriadas</span>
              <span>% Vistoriadas</span>
              <span>Praga</span>
              <span>Encontradas</span>
              <span>Amostras</span>
              <span>População %</span>
              <span>População/Fator</span>
              <span>Variedade</span>
              <span>Enxerto</span>
              <span>Resultado</span>
            </MipHeader>

            {props.map((mip, index) => {
              const parsedDate = mip.data.slice(0, 10);
              const checkboxKey = `${mip.os}${mip.praga}${mip.subarea}${parsedDate}`;

              const isChecked = selectedMips.find(
                ({ mip }) => mip === checkboxKey
              );

              return (
                <MipContent key={index} status={mip.resultado}>
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={() =>
                      handleSelected(
                        mip.os,
                        mip.subarea,
                        mip.praga,
                        mip.data,
                        mip.resultado
                      )
                    }
                  />
                  {mip?.status === 'Pós Pulverização' ? (
                    <Triangle status={mip.resultado} />
                  ) : (
                    <Circle status={mip.resultado} />
                  )}
                  <span>{mip.os}</span>
                  <span>{mip.fazenda}</span>
                  <span>{mip.subarea}</span>
                  <span>{mip.previstas}</span>
                  <span>{mip.vistoriadas}</span>
                  <span>{mip.vistoriadasPercent.toFixed(2)}%</span>
                  <span>{mip.praga}</span>
                  <span>{mip.totalPraga}</span>
                  <span>{mip.totalAmost}</span>
                  <span>{(mip.pragaPercent * 1).toFixed(2)}%</span>
                  <span>{mip.popFator}</span>
                  <span>{mip.variedade}</span>
                  <span>{mip.enxerto}</span>
                  <span>{mip.resultado}</span>
                </MipContent>
              );
            })}
          </Mip>
        </AccordionDetails>
      </Content>
    </Container>
  );
}

export default MIPContainer;
