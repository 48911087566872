import React from 'react';
import pdf from './Document/Politica.pdf';

function PoliticaPrivacidade() {
  return (
    <div>
      <iframe src={pdf} title="Política" width="100%" height="700" />
    </div>
  );
}

export default PoliticaPrivacidade;
