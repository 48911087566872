import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { formatDateToSql } from '@/Utils/dateFunctions';
import { setDisplay } from '@/Store/Actions/infoDisplayAction';
import { formatHourToSql } from '@/Utils/hourFunctions';
import getAppIcon from '@/Utils/getAppIcon';

import {
  Container,
  Content,
  RowDiv,
  HeaderApp,
  BoxBordered,
  Operator,
} from './styles';

function InfoContainer({ props }) {
  const display = useSelector((state) => state.infoDisplay.display);
  const dispatch = useDispatch();

  function handleDisplay() {
    dispatch(setDisplay(false));
  }

  let photo = null;

  if (props.funcionario_foto) {
    photo = btoa(
      String.fromCharCode(
        ...new Uint8Array(props.funcionario_foto.data)
      )
    );
  }

  if (props.length === 0) {
    return (
      <Container
        visible={display}
        top={20}
        right={10}
        onClose={handleDisplay}
        loading={true}
      />
    );
  }

  const APPIcon = getAppIcon({
    iconName: props.Nome_aplicativo,
  });

  return (
    <Container
      visible={display}
      top={20}
      right={10}
      onClose={handleDisplay}
    >
      <HeaderApp direction="row">
        <h2>{props.Nome_aplicativo}</h2>
        <img
          alt=""
          src={require(`../../../../../Assets/appIcons/${props.Nome_aplicativo}.png`)}
          height={30}
          width={30}
        />
      </HeaderApp>

      <Operator>
        {photo && (
          <img
            src={`data:image/png;base64,${photo}`}
            alt=""
            height={100}
            width={100}
            style={{ borderRadius: 10 }}
          />
        )}

        <strong>{props.funcionario}</strong>
        <span>IMEI: {props.IMEI}</span>
      </Operator>

      <Content>
        <RowDiv>
          <strong>Data:</strong>
          <h3>{formatDateToSql(props.Data)}</h3>
        </RowDiv>

        <RowDiv>
          <strong>Horário:</strong>
          <h3>{formatHourToSql(props.Data)}</h3>
        </RowDiv>
      </Content>

      <Content>
        <RowDiv>
          <strong>Fazenda:</strong>
          <span>{props.fazenda}</span>
        </RowDiv>

        <RowDiv>
          <strong>Área:</strong>
          <span>{props.area}</span>
        </RowDiv>

        <RowDiv>
          <strong>Subárea:</strong>
          <span>{props.subarea}</span>
        </RowDiv>
      </Content>

      <BoxBordered>
        <RowDiv>
          <strong>Operação:</strong>
          <span>{props.Operacao}</span>
        </RowDiv>

        <RowDiv>
          <strong>Fase:</strong>
          <span>{props.Fase}</span>
        </RowDiv>

        <RowDiv>
          <strong>OP:</strong>
          <span>{props.OP}</span>
        </RowDiv>

        <RowDiv>
          <strong>OS:</strong>
          <span>{props.OS}</span>
        </RowDiv>
      </BoxBordered>
    </Container>
  );
}

export default InfoContainer;
