import React from 'react';
import { Marker } from 'react-leaflet';
import { icon } from 'leaflet';

function MarkerApp({ coords, foto, onClick, appName }) {
  let photo = null;

  if (foto) {
    photo = btoa(String.fromCharCode(...new Uint8Array(foto.data)));
  }

  const photoUrl = photo
    ? `data:image/png;base64,${photo}`
    : require(`../../../../../Assets/appIcons/${appName}.png`);

  return (
    <Marker
      position={coords}
      onclick={onClick}
      icon={icon({
        iconUrl: photoUrl,
        iconSize: [40, 40],
        className: 'icon-pulse-realtime',
      })}
      alt={appName}
    />
  );
}

export default MarkerApp;
