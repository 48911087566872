import { useDispatch } from 'react-redux';
import { LayersControl, LayerGroup } from 'react-leaflet';
import React, { useState, useEffect } from 'react';

import api from '@/Services/api';
import Map from '@/Components/Map';
import Bica from '@/Components/Bica';
import Info from './Components/InfoContainer';
import Tractor from './Components/Tractor';
import Subarea from '@/Components/Subarea';
import LoadPage from '@/Components/LoadPage';
import arrayPrepare from '@/Utils/arrayPrepare';
import { setDisplay } from '@/Store/Actions/infoDisplayAction';
import polygonToJson from '@/Utils/polygonToJson';
import tractorAction from '@/Store/Actions/tractorAction';

import {
  Play,
  Stop,
  Pause,
  Filters,
  PlayIcon,
  StopIcon,
  PauseIcon,
  Container,
  GlobalStyle,
  TractorGroup,
  PlayControlBox,
  StyledPolyline,
  SelectContainer,
} from './styles';
import { MdCancel } from 'react-icons/md';
import { FaFilter } from 'react-icons/fa';

const Mecanizado = (props) => {
  const [zoom, setZoom] = useState(13);
  const [load, setLoad] = useState(true);
  const [bicas, setBicas] = useState([]);
  const [areas, setAreas] = useState([]);
  const [coords, setCoords] = useState([null]);
  const [center, setCenter] = useState([0, 0]);
  const [osInfo, setOsInfo] = useState([]);
  const [tractor, setTractor] = useState(null);
  const [allChecked, setAllChecked] = useState(true);
  const [appointment, setAppointment] = useState(null);

  const token = localStorage.getItem('token');
  const search = props.location.search;

  const dispatch = useDispatch();

  useEffect(() => {
    api
      .get(`/Mapas/Mecanizado/Bicas${search}`, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then((response) => setBicas(response.data));
  }, [search, token]);

  useEffect(() => {
    api
      .get(`Mapas/Mecanizado${search}`, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const { rastros, areas } = response.data;

        setAreas(areas);

        rastros.sort((a, b) => a.idcor - b.idcor);

        const parsedData = arrayPrepare(rastros);

        const centerPoint = polygonToJson(rastros[0].geo);

        setCenter(centerPoint[0]);
        setCoords(parsedData);
        setLoad(false);
      })
      .catch((err) => {
        console.error(err);
        return;
      });
    // eslint-disable-next-line
  }, []);

  function getInfoData(apontamento) {
    setOsInfo([]);
    handleDispatch(setDisplay(true));

    api
      .get(
        `/Mapas/Mecanizado/GetInfoData${search}&apontamento=${apontamento}`,
        { headers: { authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        const { data } = response;
        setOsInfo(data);
      });
  }

  function handleDispatch(action) {
    dispatch(action);
  }

  if (load) return <LoadPage />;

  return (
    <Container>
      <GlobalStyle />

      <Map props={{ center, zoom }}>
        <PlayControlBox>
          <Play onClick={() => handleDispatch(tractorAction('PLAY'))}>
            <PlayIcon />
          </Play>
          <Stop
            onClick={() => handleDispatch(tractorAction('PAUSE'))}
          >
            <StopIcon />
          </Stop>
          <Pause
            onClick={() => handleDispatch(tractorAction('STOP'))}
          >
            <PauseIcon />
          </Pause>
        </PlayControlBox>

        {areas.map((area) => (
          <Subarea
            key={area.id}
            positions={area.geo}
            name={area.subarea}
          />
        ))}

        <SelectContainer>
          <main>
            <select
              onChange={(e) => {
                setTractor(e.target.value);
                setAppointment(null);
              }}
            >
              <option
                label="Máquina..."
                value=""
                data-default
                disabled
                selected={!tractor}
              ></option>

              {coords.map((coord) => (
                <option
                  key={coord[0].implemento}
                  value={coord[0].implemento}
                  onChange={() => setTractor(coord[0].implemento)}
                >
                  {coord[0].implemento}
                </option>
              ))}
            </select>

            <select
              onChange={(e) => {
                const data = e.target.value.split('&');
                const centerPoint = polygonToJson(data?.[1]);
                setCenter(centerPoint[0]);
                setZoom(18);

                getInfoData(data?.[0]);
                setAppointment(Number(data?.[0]) || null);
              }}
            >
              <option
                value=""
                label="Apontamento..."
                data-default
                disabled
                selected={!appointment}
              ></option>

              {coords.map((coord) => {
                return coord
                  .filter((c) => c.implemento === tractor)
                  .map((c) => {
                    const os = c?.os ? `- OS: ${c?.os}` : '';
                    const name = c?.ajudante
                      ? `- ${(c?.ajudante || '')?.split(' ')?.[0]}`
                      : '';

                    const op = c?.operacao ? `- ${c?.operacao}` : '';
                    const text = `${c?.apontamento} ${name} ${os} ${op}`;

                    return (
                      <option
                        id={c.apontamento}
                        key={c.apontamento}
                        value={`${c.apontamento}&${c.geo}`}
                      >
                        {text?.slice(0, 40) + '...'}
                      </option>
                    );
                  });
              })}
            </select>
          </main>

          <div
            onClick={() => {
              setTractor(null);
              setAppointment(null);
            }}
          >
            <FaFilter size={15} color="green" />
            <MdCancel size={20} color="red" />
          </div>
        </SelectContainer>

        <Filters>
          <input
            style={{
              width: 13,
              height: 13,
            }}
            type="checkbox"
            onChange={() => setAllChecked(!allChecked)}
            checked={allChecked}
          />
          <h3>Todos</h3>
        </Filters>

        <LayersControl position="topleft">
          <LayersControl.Overlay
            name={`Bicas <img src=${require(`../../../Assets/water-drop.png`)} >`}
            checked={allChecked}
          >
            <LayerGroup>
              {bicas.map((bica) => (
                <Bica key={String(bica.latitude)} props={bica} />
              ))}
            </LayerGroup>
          </LayersControl.Overlay>

          {coords.map((coord) => (
            <LayersControl.Overlay
              key={coord[0].implemento}
              name={`${coord[0].implemento} <div id="tractorIdentifier" style="background: ${coord[0].color}"></div>`}
              checked={allChecked}
            >
              <LayerGroup>
                {coord.map((index) => {
                  const show =
                    index.apontamento === appointment || !appointment;

                  if (!show) return null;

                  return (
                    <TractorGroup key={index.apontamento}>
                      <StyledPolyline
                        style={{ zIndex: 30 }}
                        positions={polygonToJson(index.geo)}
                        lineColor={index.status}
                        onclick={() => getInfoData(index.apontamento)}
                      />

                      <Tractor
                        props={[
                          polygonToJson(index.geo),
                          index.idcor,
                        ]}
                        color={index.color}
                      />
                    </TractorGroup>
                  );
                })}
              </LayerGroup>
            </LayersControl.Overlay>
          ))}
        </LayersControl>
      </Map>
      <Info props={osInfo} />
    </Container>
  );
};

export default Mecanizado;
