import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import api from '../../../../Services/api';
import { setSelectedFarms } from '../../../../Store/Actions/selectedFarms';
import Container from '../../../../Components/PreMapContainer';
import FarmsContainer from '../../../../Components/FarmsContainer';
import Button from '../../../../Components/Buttons';
import { FaBug } from '../../../../Styles/Icons';
import { toastWarning } from '../../../../Utils/toast';

import { InfoArea, SubmitArea, Date, Plagues } from './styles';

function Pulverizado() {
  const [pragas, setPragas] = useState([]);
  const [selectedPraga, setSelectedPraga] = useState('Nenhum');
  const [date, setDate] = useState('');

  const token = localStorage.getItem('token');
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedFarms = useSelector(
    (state) => state.selectedFarms.farms
  );

  useEffect(() => {
    dispatch(setSelectedFarms([]));

    api
      .get('/Pragas', {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((response) => setPragas(response.data));
  }, [dispatch, token]);

  function handleSubmit() {
    if (selectedPraga === 'Nenhum') {
      toastWarning({ message: 'Selecione uma praga!' });
    } else if (selectedFarms.length === 0) {
      toastWarning({ message: 'Selecione uma fazenda!' });
    } else if (date === '') {
      toastWarning({ message: 'Selecione uma data!' });
    } else {
      api
        .get(
          `/Mapas/Pulverizado/VerifyData?date=${date}&farms=${selectedFarms}&praga=${selectedPraga}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (!response.data) {
            toastWarning({ message: 'Não encontramos nenhum dado!' });
          } else {
            history.push(
              `/Mapas/Diário-de-Pulverização?date=${date}&farms=${selectedFarms}&praga=${selectedPraga}`
            );
          }
        });
    }
  }

  return (
    <Container>
      <ToastContainer />

      <FarmsContainer>
        <SubmitArea>
          <InfoArea>
            <Date>
              <span>Data:</span>
              <input
                type="date"
                onChange={(e) => setDate(e.target.value)}
              />
            </Date>

            <Plagues>
              <span>
                <FaBug /> Pragas:
              </span>
              <select
                onChange={(e) => setSelectedPraga(e.target.value)}
              >
                <option value="Nenhum">Nenhum</option>
                {pragas.map((praga) => (
                  <option key={praga.nome} value={praga.nome}>
                    {praga.nome}
                  </option>
                ))}
              </select>
            </Plagues>
          </InfoArea>

          <Button onClick={handleSubmit}>Carregar</Button>
        </SubmitArea>
      </FarmsContainer>
    </Container>
  );
}

export default Pulverizado;
