import styled from 'styled-components';

export const Container = styled.div`
  box-shadow: 0 0 10px rgb(0, 0, 0, 0.6);
  position: relative;
  border-radius: 10px;
  margin: 30px auto 0;
  overflow: hidden;

  width: 90%;

  > h3 {
    width: 100%;
    display: flex;
    justify-content: center;

    padding: 10px;
    background: var(--dark-green);
    color: var(--white);
  }

  > button {
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    color: var(--white);
    font-weight: 600;
  }
`;

export const Farms = styled.div`
  max-height: 47vh;
  overflow-y: auto;

  > #select-all {
    background: #010d03;
    color: #fff;
    border-top: 1px dotted #00b43661;
  }

  > div:nth-child(even) {
    background: #ddd;
  }

  > div {
    padding: 10px 15px;

    > input {
      margin-right: 12px;
    }

    > span {
      font-weight: 500;
      font-size: 18px;
    }
  }
`;

export const DateArea = styled.div`
  && {
    width: 100%;
    background: var(--dark-green);
    color: var(--white);

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 10px 10vw;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    > span {
      margin: 0 8px;
      font-weight: 500;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 10px 20vw;
    align-items: flex-start;

    > div + div {
      margin: 10px 0;
    }
  }
`;
