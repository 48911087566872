import styled from 'styled-components';
import { CircleMarker, Tooltip } from 'react-leaflet';

export const Container = styled(CircleMarker)`
  fill-opacity: 1;
  fill: none;
  stroke: none;
`;

export const Identifier = styled(Tooltip)`
  border: none;
  box-shadow: none;
  background: ${(props) =>
    props.qtd > 0 ? 'var(--bad)' : 'var(--good)'};
  box-shadow: 0 0 5px 3px #000;
  width: 20px;
  height: 20px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 12px;
  font-weight: bold;
`;

export const TooltipContainer = styled.div`
  position: relative;
`;

export const TooltipTriangle = styled.div`
  width: 0;
  height: 0;
  filter: drop-shadow(5px 3px 3px #000);
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-bottom: 24px solid
    ${(props) => (props.qtd > 0 ? 'var(--bad)' : 'var(--good)')};
  position: absolute;
  bottom: -8px; /* Altura do triângulo */
  left: 50%; /* Posição horizontal do triângulo */
  transform: translateX(-50%);

  span {
    padding: 2px;
    position: fixed;
    top: 8px;
    right: ${(props) => (props?.id > 9 ? '-8.5px' : '-6px')};
    font-size: 12px;
    font-weight: bold;
  }
`;
