import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
} from '@material-ui/core';

import api from '@/Services/api';

import Header from '@/Components/Header';
import Row from './Components/Row';

import {
  Container,
  Content,
  TBody,
  TitleCell,
  BackArea,
  Button,
} from './styles';
import Cell from './Components/Cell';

function ManageProduto(props) {
  const [data, setData] = useState([]);

  const history = useHistory();
  const token = localStorage.getItem('token');
  const search = decodeURIComponent(props.location.search).split('&');

  const produto = search[0].slice(9);
  const cultura = search[1].slice(8);

  useEffect(() => {
    api
      .get(
        `/Orcamentos/getProdutoManage?produto=${produto}&cultura=${cultura}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then(({ data }) => setData(data));
  }, [cultura, produto, token]);

  return (
    <Container>
      <Header />

      <Content>
        <BackArea>
          <Button onClick={() => history.goBack()}>Voltar</Button>
        </BackArea>

        <TableContainer
          component={Paper}
          style={{ maxHeight: '70vh' }}
        >
          <Table
            aria-label="customized table"
            style={{
              background: 'rgb(199 199 199 / 15%)',
            }}
          >
            <TableHead>
              <Row>
                <TitleCell align="center">Area - SubArea</TitleCell>
                <TitleCell align="center">ha</TitleCell>
                <TitleCell align="center">Produção</TitleCell>
                <TitleCell align="center">
                  Produção Estimada
                </TitleCell>
                <TitleCell align="center">Produção / ha</TitleCell>
                <TitleCell align="center">Estimativa / ha</TitleCell>
                <TitleCell align="center">%</TitleCell>
                <TitleCell align="center">Desconto</TitleCell>
                <TitleCell align="center">Prod. Líquida</TitleCell>
                <TitleCell align="center">
                  Prod. Líquida / ha
                </TitleCell>
                <TitleCell align="center">%</TitleCell>
                <TitleCell align="center">Total</TitleCell>
                <TitleCell align="center">Total / ha</TitleCell>
                <TitleCell align="center">Custo / Produção</TitleCell>
                <TitleCell align="center">
                  Custo / Prod. / Líquida
                </TitleCell>
                <TitleCell align="center">Variação</TitleCell>
                <TitleCell align="center">Plantas</TitleCell>
                <TitleCell align="center">Pop. Plantas</TitleCell>
                <TitleCell align="center">Produção Bruta</TitleCell>
                <TitleCell align="center">Produção Líquida</TitleCell>
                <TitleCell align="center">
                  Produção Estimada
                </TitleCell>
              </Row>
            </TableHead>

            <TBody>
              {data.map((value) => (
                <Row key={value.areaNome}>
                  <Cell>{value.areaNome}</Cell>
                  <Cell>{value.areaTotal}</Cell>
                  <Cell>{value.producao}</Cell>
                  <Cell>{value.producaoEstimada}</Cell>
                  <Cell>{(value.producaoHa * 1).toFixed(3)}</Cell>
                  <Cell>{value.estimativaHa}</Cell>
                  <Cell>
                    {(value.producaoHa / value.estimativaHa) * 100}
                  </Cell>
                  <Cell>{value.desconto}</Cell>
                  <Cell>{value.prodLiq}</Cell>
                  <Cell>{value.prodLiqHa}</Cell>
                  <Cell>{value.percentDesconto}</Cell>
                  <Cell>{value.valorTotal}</Cell>
                  <Cell>{value.valorTotalHa}</Cell>
                  <Cell>
                    {value.producaoHa > 0
                      ? value.valorTotalHa / value.producaoHa
                      : 0}
                  </Cell>
                  <Cell>
                    {value.prodLiqHa > 0
                      ? value.valorTotalHa / value.prodLiqHa
                      : 0}
                  </Cell>
                  <Cell>{value.variedade}</Cell>
                  <Cell>{value.totalPlantas}</Cell>
                  <Cell>{value.popPlantas}</Cell>
                  <Cell>{value.producaoBruta}</Cell>
                  <Cell>{value.producaoLiq}</Cell>
                  <Cell>{value.prodEstimadaPlantas}</Cell>
                </Row>
              ))}
            </TBody>
          </Table>
        </TableContainer>
      </Content>
    </Container>
  );
}

export default ManageProduto;
