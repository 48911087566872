import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
`;

export const Main = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  background-image: url(${(props) => props.background});
  background-clip: border-box;
  background-position: center;
  background-size: 35%;
  background-repeat: no-repeat;

  @media (max-width: 999px) {
    background-size: 50%;
  }
  @media (max-width: 425px) {
    background-size: 70%;
  }
`;
