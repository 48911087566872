import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Container } from './styles';

const Home = () => {
  const history = useHistory();

  useEffect(() => {
    history.push('/Login');
    // eslint-disable-next-line
  }, []);

  return <Container />;
};

export default Home;
