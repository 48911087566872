import styled from 'styled-components';
import { Button } from '@material-ui/core';

const StyledButton = styled(Button)`
  && {
    background: rgb(10, 171, 70);
    background: linear-gradient(
      19deg,
      rgba(10, 171, 70, 1) 0%,
      rgba(19, 215, 75, 0.9794292717086834) 49%,
      rgba(0, 255, 164, 1) 99%
    );

    color: #000;
    font-weight: bold;

    border: 1px solid var(--dark-green);
  }

  &&:hover {
    opacity: 0.7;
  }
`;

export default StyledButton;
