import styled, { keyframes } from 'styled-components';
import { Tooltip, Polygon } from 'react-leaflet';

export const Container = styled.div``;

const execution = (color) => keyframes`
  0%{
    fill-opacity: 1;
    stroke: #d3d3d3;
  }

  50%{
    fill-opacity: 0;
    stroke: #d3d3d3;
  }

  100%{
    fill-opacity: 1  ;
    stroke: #d3d3d3;
  }
`;

export const Area = styled(Polygon).attrs((props) => ({
  dashArray: props.situation === 'Execução' && '10, 10',
}))`
  fill: ${(props) => props.fill};
  stroke: ${(props) => props.fill};
  transition: 0.2s;
  fill-opacity: 0.5;

  animation: ${(props) =>
      props.situation === 'Execução' && execution(props.fill)}
    6s infinite;

  &:hover {
    fill-opacity: 1;
  }
`;

export const AreaTitle = styled(Tooltip).attrs((props) => ({
  permanent: true,
  direction: 'center',
}))`
  background: rgb(0, 0, 0, 0.4);
  border: 1px solid #000;
  color: #fff;
  font-weight: 600;
  font-size: 9px;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 1px;
`;
