import styled, { keyframes } from 'styled-components';

import { IoMdCloseCircle } from '../../Styles/Icons';
import LoadSpinner from '../LoadSpinner';

const appear = keyframes`
  0%{opacity: 0;}
  100%{opacity: 1;}
`;

export const Container = styled.div`
  && {
    width: 300px;
    height: 70vh;

    display: ${(props) => (props.visible ? 'flex' : 'none')};
    flex-direction: column;
    overflow: hidden;

    background: rgb(15, 15, 15);
    background: linear-gradient(
      180deg,
      rgba(15, 15, 15) 13%,
      rgba(19, 19, 20) 36%,
      rgba(21, 21, 22) 66%,
      rgba(19, 19, 19) 88%
    );

    box-shadow: 0 10px 10px rgb(0, 0, 0, 0.5);

    position: absolute;
    top: ${(props) => (props.top ? `${props.top}px` : '10px')};
    right: ${(props) => (props.right ? `${props.right}px` : 'auto')};
    left: ${(props) => (props.left ? `${props.left}px` : 'auto')};
    z-index: 100000;

    border-radius: 5px;

    animation: ${appear} 0.3s linear;

    @media (max-width: 655px) {
      width: 100vw;
      left: 0;
      top: 0;
      z-index: 100000;

      margin: 0;
      max-height: 100vh;
      height: 100vh;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px;
  position: relative;
  background: rgb(62, 62, 62);

  font-size: 24px;
  font-weight: 600;
  color: #fff;

  svg {
    color: red;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  padding: 8px;
  overflow-y: auto;

  height: 100%;
  max-height: 80vh;

  position: relative;

  @media (max-width: 655px) {
    max-height: 90vh;
  }
`;

export const Loader = styled(LoadSpinner)`
  position: absolute;
  top: 50%;
  margin: 0 auto;
`;

export const CloseIcon = styled(IoMdCloseCircle)`
  width: 27px;
  height: 27px;

  color: #000;
  border: none;
  border-radius: 50%;

  cursor: pointer;

  position: absolute;
  left: 10px;

  &:hover {
    color: #ccc;
  }
`;
