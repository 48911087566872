import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import api from '@/Services/api';
import { setDisplay } from '@/Store/Actions/infoDisplayAction';

import Map from '@/Components/Map';
import LoadPage from '@/Components/LoadPage';
import InfoContainer from '@/Components/PulverizadoInfo';

import { Container, AreaTitle, Area } from './styles';
import { pulverizadoContext } from '@/Contexts/pulverizadoContext';

function Pulverizado(props) {
  const [areas, setAreas] = useState([]);
  const [center, setCenter] = useState([0, 0]);

  const { getInfoData, isActive } = useContext(pulverizadoContext);

  const token = localStorage.getItem('token');
  const search = props.location.search;
  const dispatch = useDispatch();

  const dateChoosen = search.split('&')[0].slice(6);
  const plagueChoosen = search.split('&')[2].slice(6);

  useEffect(() => {
    dispatch(setDisplay(false));

    api
      .get(`/Mapas/Pulverizado${search}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setCenter(response.data[0].geo[0][0]);
        setAreas(response.data);
      });
  }, [dispatch, search, token]);

  function setSituationArea(days, tolerance, pulv, subarea) {
    if (days <= pulv - tolerance) {
      return 'var(--good)';
    } else if (days > pulv) {
      return 'var(--bad)';
    } else {
      return 'var(--medium)';
    }
  }

  if (areas.length === 0) {
    return <LoadPage />;
  }

  return (
    <Container>
      <Map props={{ center, zoom: 12 }}>
        {areas.map((area) => (
          <Area
            key={String(area.subarea)}
            positions={area.geo}
            fill={() =>
              setSituationArea(
                area.dias,
                area.tolerancia,
                area.pulverizacao,
                area.subarea
              )
            }
            situation={area.situacao}
            onClick={() => {
              getInfoData(
                area.fazenda,
                area.subarea,
                area.os,
                plagueChoosen,
                dateChoosen
              );
            }}
          >
            <AreaTitle>
              <span>{area.subarea}</span>
              <span>{(area.dias * 1).toFixed(0)} dias</span>
            </AreaTitle>
          </Area>
        ))}
      </Map>

      {isActive && <InfoContainer props={{ date: dateChoosen }} />}
    </Container>
  );
}

export default Pulverizado;