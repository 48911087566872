import React from 'react';

import Header from '@/Components/Header';
import MapContainer from './Components/MapContainer';

import {
  FaCalendarDay,
  GiWolfTrap,
  FaBug,
  GiCow,
  FaEye,
  FaBalanceScaleLeft,
  Cash,
  MdPerson,
  FaTractor,
} from '@/Styles/Icons';

import { Container, Description, Content } from './styles';

function ChooseMap() {
  const farm = localStorage.getItem('farmName');

  return (
    <Container>
      <Header />

      <Description>
        Consulte os mapas para análise de dados de suas áreas.
      </Description>

      <Content>
        <MapContainer
          title="Custos"
          image={require('./Assets/agros.png')}
          to="/Farm/Agros"
          icon={<Cash />}
        />

        <MapContainer
          title="Mecanizado"
          image={require('./Assets/mecanizado.png')}
          to="/Farm/Mecanizado"
          icon={<FaTractor />}
        />
        <MapContainer
          title="Pulverização"
          image={require('./Assets/pulverizado.png')}
          to="/Farm/Pulverização"
          icon={<FaCalendarDay />}
        />
        <MapContainer
          title="Armadilhas"
          image={require('./Assets/armadilhas.png')}
          to="/Farm/Armadilhas"
          icon={<GiWolfTrap />}
        />
        <MapContainer
          title="Ordem de Serviço"
          image={require('./Assets/os.png')}
          to="/Farm/OS"
          icon={<FaCalendarDay />}
        />
        <MapContainer
          title="M.I.P.D 1"
          image={require('./Assets/mip1.png')}
          to="/Farm/MIP1"
          icon={<FaBug />}
        />
        <MapContainer
          title="M.I.P.D 2"
          image={require('./Assets/mip2.png')}
          to="/Farm/MIP2"
          icon={<FaBug />}
        />
        <MapContainer
          title="Animais"
          image={require('./Assets/animais.png')}
          to="/Farm/Animais"
          icon={<GiCow />}
        />
        <MapContainer
          title="Cochos"
          image={require('./Assets/cochos.png')}
          to="/Farm/Cochos"
          icon={<FaBalanceScaleLeft />}
        />
        <MapContainer
          title="Minhas Áreas"
          image={require('./Assets/areas.png')}
          to="/Farm/Minhas-Areas"
          icon={<FaEye />}
        />
        <MapContainer
          title="Tempo Real"
          image={require('./Assets/realtime.png')}
          to="/Farm/Realtime"
          icon={<MdPerson />}
        />
      </Content>
    </Container>
  );
}

export default ChooseMap;
