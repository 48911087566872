import styled from 'styled-components';

import { FaEye } from '@/Styles/Icons';

export const Container = styled.div``;

export const OpenInfoDisplay = styled.div`
  position: absolute;
  top: 85px;
  left: 10px;
  z-index: 1000;

  width: 35px;
  height: 35px;
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #fff;
`;

export const EyeIcon = styled(FaEye)`
  width: 80%;
  height: 80%;
`;
