import styled from 'styled-components';
import {
  TableCell,
  Table as UITable,
  TableBody,
} from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 40px 10px 50px;
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const Table = styled(UITable)``;

export const TBody = styled(TableBody)`
  > tr:nth-child(even) {
    background: #ddd;
  }
`;

export const TitleCell = styled(TableCell)`
  white-space: nowrap;
`;

export const Title = styled.h2`
  display: flex;
  justify-content: center;
  padding: 15px;
  text-align: center;
  margin: 30px auto 50px;
  width: 100%;
`;

export const FilterArea = styled.div`
  padding: 18px;
  border-radius: 5px;
  box-shadow: 0 0 3px rgb(0, 0, 0, 0.6);

  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 30px;

  > strong {
    margin-right: 17px;
  }

  > select {
    padding: 3px;
  }
`;

export const Description = styled.h2`
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-bottom: 15px;
`;

export const OpGroup = styled.div`
  width: 85vw;

  & + div {
    margin-top: 70px;
  }
`;
