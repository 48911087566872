import styled from 'styled-components';
import { Polygon, Tooltip } from 'react-leaflet';
import { FaEye } from '@/Styles/Icons';
import { Marker } from 'react-leaflet';

export const Container = styled.div``;

export const Area = styled(Polygon)`
  fill: ${(props) => {
    if (props.media >= 2.01) return 'var(--good)';
    else if (props.media >= 1.51 && props.media <= 2)
      return '#ace201';
    else if (props.media >= 1.01 && props.media <= 1.5)
      return 'var(--medium)';
    else if (props.media >= 0.51 && props.media <= 1)
      return '#ff7f00';
    else return 'var(--bad)';
  }};

  stroke: ${(props) => {
    if (props.media >= 2.01) return 'var(--good)';
    else if (props.media >= 1.51 && props.media <= 2)
      return '#ace201';
    else if (props.media >= 1.01 && props.media <= 1.5)
      return 'var(--medium)';
    else if (props.media >= 0.51 && props.media <= 1)
      return '#ff7f00';
    else return 'var(--bad)';
  }};

  fill-opacity: 0.5;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    fill-opacity: 0.9;
  }
`;

export const Identifier = styled(Tooltip).attrs({
  direction: 'center',
  permanent: true,
})`
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgb(0, 0, 0, 0.3);
  box-shadow: 3px 3px 4px rgb(0, 0, 0);
  border: 1px solid #000;

  font-weight: bold;
  color: #fff;
`;

export const Marcador = styled(Marker)`
  > img {
    width: 45px;
    height: 75px;
  }
`;

export const Eye = styled.div`
  width: 26px;
  height: 26px;

  position: absolute;
  top: 130px;
  left: 11px;
  z-index: 10000;

  border-radius: 5px;
  background: #fff;

  display: ${(props) => (props.visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

export const EyeIcon = styled(FaEye)`
  width: 20px;
  height: 20px;
`;
