import React from 'react';

import { Container, Identifier } from './styles';

function IntervalValues({ values }) {
  return (
    <Container>
      <Identifier color="#1ee201">
        <div></div>
        <span>+{values.otimo}</span>
      </Identifier>

      <Identifier color="#ace201">
        <div></div>
        <span>
          {values.bom} - {values.otimo}
        </span>
      </Identifier>

      <Identifier color="#ff0">
        <div></div>
        <span>
          {values.regular} - {values.bom}
        </span>
      </Identifier>

      <Identifier color="#ff7f00">
        <div></div>
        <span>
          {values.ruim} - {values.regular}
        </span>
      </Identifier>

      <Identifier color="#ff0000">
        <div></div>
        <span>0 - {values.ruim}</span>
      </Identifier>
    </Container>
  );
}

export default IntervalValues;
