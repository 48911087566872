import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from '@/Components/Header';
import Button from '@/Components/Buttons';
import api from '@/Services/api';
import { toastWarning } from '../../../../Utils/toast';

import {
  Container,
  Content,
  Op,
  MapIcon,
  FilterContainer,
  Filter,
  FilterGroup,
} from './styles';

function Agros() {
  const [ops, setOps] = useState([]);
  const [culturas, setCulturas] = useState([]);
  const [situacao, setSituacao] = useState('Aberta');
  const [cultura, setCultura] = useState('');

  const token = localStorage.getItem('token');
  const history = useHistory();

  useEffect(() => {
    api
      .get('/Mapas/Agros/getOp', {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((response) => setOps(response.data));
  }, [token]);

  useEffect(() => {
    api
      .get('/Mapas/Agros/getCulturas', {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => setCulturas(data));
  }, [token]);

  useEffect(() => {
    getFilteredOps(situacao, cultura);
    //eslint-disable-next-line
  }, [cultura, situacao]);

  function handleSubmit(numero, controle, produto) {
    api
      .get(
        `/Mapas/Agros/verifyData?numero=${numero}&controle=${controle}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (!response.data) {
          toastWarning({ message: 'Não encontramos nenhum dado!' });
        } else {
          history.push(
            `/Mapas/Agros?numero=${numero}&controle=${controle}&cultura=${produto}`
          );
        }
      });
  }

  function getFilteredOps(situacao, cultura) {
    api
      .get(
        `/Mapas/Agros/getFilteredOp?cultura=${cultura}&situacao=${situacao}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then(({ data }) => setOps(data));
  }

  return (
    <Container>
      <ToastContainer />

      <Header />
      <Content>
        <FilterContainer>
          <FilterGroup>
            <strong>Cultura</strong>

            <Filter onChange={(e) => setCultura(e.target.value)}>
              <option value="">Todas</option>

              {culturas.map((cultura) => (
                <option key={cultura.nome} value={cultura.nome}>
                  {cultura.nome}
                </option>
              ))}
            </Filter>
          </FilterGroup>

          <FilterGroup>
            <strong>Situação</strong>

            <Filter onChange={(e) => setSituacao(e.target.value)}>
              <option value="Aberta">Aberta</option>
              <option value="Fechada">Fechada</option>
            </Filter>
          </FilterGroup>
        </FilterContainer>

        {ops.map((op) => (
          <Op key={op.nome}>
            <span>{op.nome}</span>
            <Button
              onClick={() =>
                handleSubmit(op.numero, op.controle, op.cultura)
              }
            >
              <span>Ver Mapa</span>
              <MapIcon />
            </Button>
          </Op>
        ))}
      </Content>
    </Container>
  );
}

export default Agros;
