import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import api from '@/Services/api';
import Button from '@/Components/Buttons';
import LoadPage from '@/Components/LoadPage';
import Container from '@/Components/PreMapContainer';
import { setLoading } from '@/Store/Actions/infoDisplayAction';
import FarmsContainer from '@/Components/FarmsContainer';
import { toastWarning } from '../../../../Utils/toast';

import {
  Date,
  Option,
  SubmitArea,
  ChooseDate,
  ChooseOthers,
} from './styles';

export default function MIP1() {
  const [data1, setData1] = useState('');
  const [data2, setData2] = useState('');
  const [pragas, setPragas] = useState([]);
  const [result, setResult] = useState(null);
  const [status, setStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPraga, setSelectedPraga] = useState(null);

  const token = localStorage.getItem('token');

  const history = useHistory();
  const selectedFarms = useSelector(
    (state) => state.selectedFarms.farms
  );

  useEffect(() => {
    getPragas();
  }, []);

  async function getPragas() {
    try {
      const response = await api.get('/Pragas', {
        headers: { authorization: `Bearer ${token}` },
      });

      setPragas(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function getData() {
    try {
      setIsLoading(true);

      const query = `${
        result !== null ? `&result=${result}` : '&result='
      }${
        selectedPraga !== null ? `&praga=${selectedPraga}` : '&praga='
      }${status !== null ? `&status=${status}` : '&status='}`;

      const response = await api.get(
        `/Mapas/MIP1/verifyData?farms=${selectedFarms}&data1=${data1}&data2=${data2}${query}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data) {
        toastWarning({ message: 'Não encontramos nenhum dado!' });
        return;
      }

      history.push(
        `/Farm/MIP/Results?farms=${selectedFarms}&data1=${data1}&data2=${data2}&result=${result}&praga=${selectedPraga}&metodo=1&status=${status}`
      );
    } catch (error) {
      console.log(error);
      toastWarning({ message: 'Não encontramos nenhum dado!' });
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit() {
    if (selectedFarms.length === 0) {
      toastWarning({ message: 'Selecione uma fazenda!' });
      return;
    }

    if (data1 === '' || data2 === '') {
      toastWarning({ message: 'Selecione um intervalo de data!' });
      return;
    }

    getData();
  }

  if (isLoading) {
    return <LoadPage message="Aguarde alguns minutos..." />;
  }

  return (
    <Container>
      <ToastContainer />

      <FarmsContainer>
        <SubmitArea>
          <ChooseDate>
            <Date>
              <strong>Inicial</strong>
              <input
                type="date"
                onChange={(e) => setData1(e.target.value)}
              />
            </Date>
            <Date>
              <strong>Final</strong>
              <input
                type="date"
                onChange={(e) => setData2(e.target.value)}
              />
            </Date>
          </ChooseDate>

          <ChooseOthers>
            <Option>
              <strong>Praga</strong>
              <select
                onChange={(e) => setSelectedPraga(e.target.value)}
              >
                <option value="">Todos</option>

                {pragas.map((praga, index) => (
                  <option key={index} value={praga.nome}>
                    {praga.nome}
                  </option>
                ))}
              </select>
            </Option>

            <Option>
              <strong>Situação</strong>
              <select onChange={(e) => setStatus(e.target.value)}>
                <option value="">Todos</option>
                <option value="Pré Pulverização">
                  Pré Pulverização
                </option>
                <option value="Pós Pulverização">
                  Pós Pulverização
                </option>
              </select>
            </Option>

            <Option>
              <strong>Resultado</strong>
              <select onChange={(e) => setResult(e.target.value)}>
                <option value="">Todos</option>
                <option value="Baixo">Baixo</option>
                <option value="Medio">Médio</option>
                <option value="Alto">Alto</option>
              </select>
            </Option>
          </ChooseOthers>
        </SubmitArea>
        <Button onClick={handleSubmit}>Carregar dados</Button>
      </FarmsContainer>
    </Container>
  );
}
