import styled from 'styled-components';

import InfoContainer from '@/Components/InfoContainer';

import { FaSadTear, FaBug } from '@/Styles/Icons';

export const Container = styled(InfoContainer)``;

export const RowDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: #fff;
  font-size: 14px;

  & + div {
    margin-top: 8px;
  }

  > strong {
    margin-right: 20px;
  }
`;

export const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Operator = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 20px;
  color: #fff;

  > strong {
    font-size: 20px;
    max-width: 300px;
    text-align: center;
  }

  > span {
    text-align: center;
  }
`;

export const Header = styled.div.attrs((props) => {})`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  color: #fff;

  margin-bottom: 20px;
  padding: 10px 0;
  border-radius: 5px;
  background: #313131;
`;

export const Tractor = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  background: #313131;

  > h2 {
    color: #fff;
  }

  > #tractor {
    height: 20px;
    width: 20px;

    margin-left: 20px;
    border-radius: 50%;
    box-shadow: 0 0 5px;
    background: ${(props) => props.tractorBackground};
  }
`;

export const BoxBordered = styled.div`
  display: flex;
  flex-direction: column;

  box-shadow: 0 0 5px inset rgb(0, 0, 0);
  padding: 5px;
  margin: 15px 0;
  border-right: 2px solid aqua;

  color: #fff;

  div + div {
    margin-top: 10px;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 7px;
    }
  }
`;

export const Number = styled.span.attrs((props) => {})`
  color: ${(props) =>
    props.variation >= 0
      ? '#2cff05'
      : props.variation === undefined
      ? '#0dd7ec'
      : '#ff2424'};

  font-weight: 600;
  margin-right: 5px;
`;

export const ErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h2 {
    text-align: center;
    font-size: 26px;
    margin-bottom: 20px;
  }

  > svg {
    width: 40px;
    height: 40px;
  }
`;

export const SadIcon = styled(FaSadTear)``;
export const Bug = styled(FaBug)``;
