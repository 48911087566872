import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Container from '../../../../Components/PreMapContainer';
import FarmsContainer from '../../../../Components/FarmsContainer';
import Button from '../../../../Components/Buttons';
import api from '../../../../Services/api';
import { toastWarning } from '../../../../Utils/toast';

function Cochos() {
  const selectedFarms = useSelector(
    (state) => state.selectedFarms.farms
  );
  const history = useHistory();
  const token = localStorage.getItem('token');

  function handleSubmit() {
    if (selectedFarms.length === 0) {
      toastWarning({ message: 'Selecione uma fazenda!' });
    } else {
      api
        .get(`/Mapas/Cochos/verifyData?farms=${selectedFarms}`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (!response.data) {
            toastWarning({ message: 'Não encontramos nenhum dado!' });
          } else {
            history.push(`/Mapas/Cochos?farms=${selectedFarms}`);
          }
        });
    }
  }

  return (
    <Container>
      <ToastContainer />

      <FarmsContainer>
        <Button onClick={handleSubmit}>Carregar Mapa</Button>
      </FarmsContainer>
    </Container>
  );
}

export default Cochos;
