export default function arrayPrepare(data) {
  let result = [];
  let store = [];

  for (
    let i = 0;
    data.length === 1 ? i <= data.length - 1 : i < data.length - 1;
    i++
  ) {
    let aux = 0;

    for (let j = i + 1; j < data.length; j++) {
      if (data[i].idcor === data[j].idcor) {
        aux++;
      }
    }

    if (aux > 0) {
      store.push(data[i]);
    } else {
      store.push(data[i]);
      result.push(store);
      store = [];
    }
    if (i === data.length - 2) {
      store.push(data[data.length - 1]);
      result.push(store);
    }
  }

  return result;
}
