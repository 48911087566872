const INITIAL_STATE = {
  mips: {
    os: [],
    subarea: [],
    praga: [],
    data: [],
  },
};

export default function selectedMips(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'setSelectedMips':
      return {
        ...state,
        mips: action.selectedMip,
      };
    default:
      return state;
  }
}
