import styled from 'styled-components';

import { FaCalendarDay } from '../../../../Styles/Icons';

export const SubmitArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 830px) {
    flex-direction: row;
    justify-content: space-around;
  }
`;

export const DateGroup = styled.div``;

export const Date = styled.div`
  display: flex;
  justify-content: space-between;

  margin: 5px 0;

  > h2 {
    font-size: 16px;
    margin-right: 5px;
  }
`;

export const CalendarIcon = styled(FaCalendarDay)`
  margin-right: 6px;
  color: #403f3f;
`;
