import styled from 'styled-components';

import { FaEye } from '../../../../../Styles/Icons';

export const Container = styled.div`
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 2px;

  position: absolute;
  top: 85px;
  left: 12px;
  z-index: 1000;

  display: ${(props) => (props.visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

export const EyeIcon = styled(FaEye)`
  width: 80%;
  height: 80%;

  color: #000;
`;
