import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { LoadIcon } from '../LoadSpinner/styles';
import { useFetchFarms } from '../../Services/fetchs';
import { setSelectedFarms } from '../../Store/Actions/selectedFarms';

import { Container, Farms } from './styles';

function FarmsContainer({ children }) {
  const [allSelected, setAllSelected] = useState(true);

  const token = localStorage.getItem('token');

  const { data: farms, isLoading } = useFetchFarms(token);

  const farmsSelected = useSelector(
    (state) => state.selectedFarms.farms
  );

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) history.push('/Login');
  }, [history, token]);

  function handleSelectedFarm(farmName) {
    const alreadySelected = farmsSelected.findIndex(
      (farm) => farm === farmName
    );

    if (alreadySelected >= 0) {
      const filteredFarms = farmsSelected.filter(
        (farm) => farm !== farmName
      );

      dispatch(setSelectedFarms(filteredFarms));
    } else {
      dispatch(setSelectedFarms([...farmsSelected, farmName]));
    }
  }

  function selectAll() {
    const farmsInput = document.querySelector('.farms').children;
    const length = farmsInput.length;

    setAllSelected(!allSelected);

    for (var i = 0; i < length; i++) {
      farmsInput[i].children[0].checked = allSelected;
    }

    if (allSelected) {
      var farmsArray = [];

      for (var j = 1; j < length; j++) {
        farmsArray.push(farmsInput[j].children[0].value);
      }

      dispatch(setSelectedFarms(farmsArray));
    } else {
      dispatch(setSelectedFarms([]));
    }
  }

  return (
    <Container>
      <h3>Selecione uma fazenda</h3>

      <Farms className="farms">
        <div id="select-all">
          <input
            onClick={selectAll}
            type="checkbox"
            name="Farm"
            value={'farm.razaosocial'}
          />
          <span>Selecionar todas</span>
        </div>

        {isLoading && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <LoadIcon />
          </div>
        )}

        {farms.map((farm) => (
          <div key={farm.razaosocial}>
            <input
              onClick={() => handleSelectedFarm(farm.razaosocial)}
              type="checkbox"
              name="Farm"
              value={farm.razaosocial}
            />
            <span>{farm.razaosocial}</span>
          </div>
        ))}
      </Farms>

      {children}
    </Container>
  );
}

export default FarmsContainer;
