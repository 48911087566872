import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import formatDate from '@/Utils/formatDate';
import { setDisplay } from '@/Store/Actions/infoDisplayAction';

import DecimalNumber from '@/Components/DecimalNumber';

import {
  Container,
  Header,
  RowDiv,
  ColumnDiv,
  Tractor,
  Bug,
  Operator,
  BoxBordered,
} from './styles';

function InfoContainer({ props }) {
  const display = useSelector((state) => state.infoDisplay.display);
  const dispatch = useDispatch();

  function handleDisplay() {
    dispatch(setDisplay(false));
  }

  if (props.length === 0) {
    return (
      <Container
        visible={display}
        top={20}
        right={10}
        onClose={handleDisplay}
        loading={true}
      />
    );
  }

  return (
    <Container
      visible={display}
      top={20}
      right={10}
      onClose={handleDisplay}
    >
      <Header>
        <RowDiv>
          <strong>Data</strong>
          <h3>{formatDate(props.data)}</h3>
        </RowDiv>

        <RowDiv>
          <strong>OS:</strong>
          <span>{props.os}</span>
        </RowDiv>

        <RowDiv>
          <strong>Apontamento:</strong>
          <span>{props.apontamento}</span>
        </RowDiv>
      </Header>

      <ColumnDiv>
        <RowDiv>
          <span>{props.operacao}</span>
        </RowDiv>

        {props.areas.map((area) => (
          <RowDiv>
            <strong
              style={{
                fontSize: 10,
                maxWidth: 120,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {area.fazenda}
            </strong>
            <span>
              {area.subarea} - {area.totalArea} ha
            </span>
          </RowDiv>
        ))}
      </ColumnDiv>

      <BoxBordered>
        {props.alvos.length > 0 &&
          props.alvos.map((alvo) => (
            <div key={String(alvo.alvo)}>
              <Bug />
              <strong>{alvo.alvo}</strong>
            </div>
          ))}
      </BoxBordered>

      <Tractor tractorBackground={props.color} direction="row">
        <h2>Recursos</h2>
        <div id="tractor" />
      </Tractor>

      <Operator>
        <strong>{props.ajudante}</strong>
        <span>
          {props.inicio}hrs - {props.final}hrs Total:{' '}
          {props.totalTrabalhado}hrs
        </span>
      </Operator>

      {props.implementos.length > 0 &&
        props.implementos.map((implemento) => (
          <RowDiv key={String(implemento.implemento)}>
            <strong>{implemento.implemento}:</strong>
            <div>
              <DecimalNumber type="colored">
                {implemento.total}
              </DecimalNumber>
              <span> horas</span>
            </div>
          </RowDiv>
        ))}

      <BoxBordered>
        {props.insumos.length > 0 &&
          props.insumos.map(
            (insumo) =>
              insumo !== undefined && (
                <RowDiv key={String(insumo.insumo)}>
                  <strong>{insumo.insumo}:</strong>
                  <div>
                    <DecimalNumber type="colored">
                      {insumo.qtde * 1}
                    </DecimalNumber>
                  </div>
                </RowDiv>
              )
          )}
      </BoxBordered>

      {props.caldaTotal !== null && (
        <>
          <RowDiv>
            <strong>Calda:</strong>
            <div>
              <div>
                <DecimalNumber type="colored">
                  {props.caldaTotal}
                </DecimalNumber>{' '}
                <span>Litros</span>
              </div>
            </div>
          </RowDiv>
          <RowDiv>
            <strong>Área Apontada:</strong>
            <div>
              <DecimalNumber type="colored">
                {props.areaApontada * 1}
              </DecimalNumber>
              <span> ha</span>
            </div>
          </RowDiv>
          <RowDiv>
            <strong>Área Coberta:</strong>

            <div>
              <DecimalNumber type="colored">
                {props.caldaTotal / props.caldaha}
              </DecimalNumber>
              <span> ha</span>
            </div>
          </RowDiv>
          <RowDiv>
            <strong>Calda/ha Sugerida:</strong>
            <div>
              <DecimalNumber type="colored">
                {props.caldaha * 1}
              </DecimalNumber>

              <span> ha</span>
            </div>
          </RowDiv>
          <RowDiv>
            <strong>Calda/ha Apontada:</strong>
            <div>
              <DecimalNumber type="colored">
                {props.caldahaApontada * 1}
              </DecimalNumber>
              <span> ha</span>
            </div>
          </RowDiv>
          <RowDiv>
            <strong>Porcentagem</strong>
            <div>
              <DecimalNumber type="colored">
                {props.porcentagemCaldaha * 1}
              </DecimalNumber>
              <span>%</span>
            </div>
          </RowDiv>
          <RowDiv>
            <strong>Variação</strong>
            <div>
              <DecimalNumber
                type="colored"
                variation={props.porcentagemCaldaha - 100}
              >
                {props.porcentagemCaldaha - 100}
              </DecimalNumber>
              <span>%</span>
            </div>
          </RowDiv>
        </>
      )}

      <RowDiv>
        <strong>Obs:</strong>
        <span>{props.observacao}</span>
      </RowDiv>
    </Container>
  );
}

export default InfoContainer;
