import styled from 'styled-components';
import { Tooltip as LeafletTooltip, Polygon } from 'react-leaflet';

export const Tooltip = styled(LeafletTooltip).attrs((props) => ({
  permanent: true,
  direction: 'center',
}))`
  background: transparent;
  font-weight: bold;
  font-size: 20px;
  color: #fff;
  box-shadow: none;

  border: none;
`;

export const PolygonArea = styled(Polygon).attrs((props) => ({
  dashArray: '10, 10',
}))`
  fill-opacity: 0.05;
`;
