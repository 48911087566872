import { useQuery } from 'react-query';

export function useQueryFetch(props) {
  const { isFetching, ...query } = useQuery(
    props.queryKey,
    props.handler,
    props.config
  );

  return { ...query, isLoading: isFetching };
}
