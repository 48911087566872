import React, { useEffect, useState } from 'react';
import { LayersControl, LayerGroup, Popup } from 'react-leaflet';
import { useDispatch } from 'react-redux';

import {
  setDisplay,
  setLoading,
} from '@/Store/Actions/infoDisplayAction';

import Map from '@/Components/Map';
import LoadPage from '@/Components/LoadPage';
import Info from './Components/InfoContainer';

import api from '@/Services/api';
import polygonToJson from '@/Utils/polygonToJson';

import {
  Container,
  Rastro,
  colors,
  Filters,
  GlobalStyle,
  TractorPopup,
} from './styles';
import Subarea from '@/Components/Subarea';

function Os(props) {
  const [data, setData] = useState([]);
  const [osInfo, setOsInfo] = useState([]);
  const [center, setCenter] = useState([0, 0]);
  const [areas, setAreas] = useState([]);

  const [allChecked, setAllChecked] = useState(true);

  const token = localStorage.getItem('token');
  const search = props.location.search;
  const dispatch = useDispatch();

  useEffect(() => {
    api
      .get(`/Mapas/OS/Rastros${search}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        const { rastros, areas } = data;

        const geo = rastros[0]
          .filter(({ geo }) => geo !== 'POLYGON())')
          .map(({ geo }) => {
            return geo;
          });

        setAreas(areas);
        setData(rastros);
        setCenter(polygonToJson(geo[0])[0]);
      });
  }, [search, token]);

  function handleDispatch(action) {
    dispatch(action);
  }

  function getInfo(apontamento) {
    setOsInfo([]);

    api
      .get(
        `/Mapas/Mecanizado/GetInfoData?apontamento=${apontamento}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setOsInfo(response.data);
        dispatch(setLoading(false));
      });
  }

  if (data.length === 0) {
    return <LoadPage />;
  }

  return (
    <Container>
      <GlobalStyle />
      <Map props={{ center, zoom: 13 }}>
        {areas.map((area) => (
          <Subarea
            key={area.id}
            positions={area.geo}
            name={area.subarea}
          />
        ))}

        <Filters>
          <input
            style={{
              width: 13,
              height: 13,
            }}
            type="checkbox"
            onChange={() => setAllChecked(!allChecked)}
            checked={allChecked}
          />
          <h3>Todos</h3>
        </Filters>

        <LayersControl position="topleft">
          {data.map((osGroup) => (
            <LayersControl.Overlay
              checked={allChecked}
              key={osGroup[0].os}
              name={`${
                osGroup[0].os
              }<div class="identifier" style="background:${
                colors[osGroup[0].color]
              }"></div>`}
            >
              <LayerGroup>
                {osGroup.map((rastro) => (
                  <Rastro
                    key={rastro.apontamento}
                    color={colors[rastro.color]}
                    positions={polygonToJson(rastro.geo)}
                    onClick={() => {
                      handleDispatch(setDisplay(true));
                      getInfo(rastro.apontamento);
                    }}
                  >
                    <Popup>
                      <TractorPopup background={rastro.tractorColor}>
                        <div id="tractor"></div>
                        <span>{rastro.implemento}</span>
                      </TractorPopup>
                    </Popup>
                  </Rastro>
                ))}
              </LayerGroup>
            </LayersControl.Overlay>
          ))}
        </LayersControl>
      </Map>

      <Info props={osInfo} />
    </Container>
  );
}

export default Os;
