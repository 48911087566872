import React from 'react';
import { LayersControl, LayerGroup } from 'react-leaflet';

import MarkerApp from '../MarkerApp';

function AppControl({ realtimeData, onClick, apps, appsCount }) {
  const appsData = ['TODOS', ...apps];

  function getCount(currentApp) {
    const count = appsCount
      .filter((app) => app.name === currentApp)
      .map((app) => app.count);

    return count.toString() || '1';
  }

  function getName(currentApp) {
    if (currentApp === 'TODOS') {
      return 'TODOS';
    }

    const count = getCount(currentApp);
    const name = `${count} - ${currentApp} <img src=${require(`../../../../../Assets/appIcons/${currentApp}.png`)}>`;

    return name;
  }

  return (
    <LayersControl position="topleft">
      {appsData.map((app, index) => (
        <LayersControl.Overlay key={app} name={getName(app)}>
          <LayerGroup>
            {index > 0 && realtimeData
              ? realtimeData
                  .filter((data) => data.Nome_aplicativo === app)
                  .map((data) => (
                    <MarkerApp
                      key={data.Codigo}
                      coords={data.geo}
                      foto={data.funcionario_foto}
                      appName={data.Nome_aplicativo}
                      onClick={() => onClick(data)}
                    />
                  ))
              : realtimeData.map((data) => (
                  <MarkerApp
                    key={data.Codigo}
                    coords={data.geo}
                    foto={data.funcionario_foto}
                    appName={data.Nome_aplicativo}
                    onClick={() => onClick(data)}
                  />
                ))}
          </LayerGroup>
        </LayersControl.Overlay>
      ))}
    </LayersControl>
  );
}

export default AppControl;
