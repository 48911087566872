import styled from 'styled-components';
import { FaCalendarDay } from '../../../../Styles/Icons';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  height: 100%;
  width: 100%;
`;

export const ChooseDate = styled.div`
  display: flex;
  flex-direction: column;

  margin: 20px auto 0;
  width: 100%;
  max-width: 500px;

  > button {
    margin-top: 20px;
    max-width: 500px;
    color: var(--white);
  }

  @media (max-width: 800px) {
    && {
      width: 85%;
    }
  }

  @media (min-width: 426px) {
    margin-top: 30px;
  }

  @media (max-width: 425px) {
    && {
      height: 90%;
      justify-content: ${(props) =>
        props.visible === 'true' ? 'space-around' : 'flex-start'};
    }
  }
`;

export const DateGroup = styled.div`
  display: ${(props) => (props.visible === 'true' ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-between;

  @media (max-width: 425px) {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    > div + div {
      margin-top: 12vh;
    }
  }
`;

export const Date = styled.div`
  width: 40%;

  > h2 {
    font-size: 16px;
    color: var(--gray);
  }

  > input {
    font-size: 16px;
    display: flex;
    align-items: center;
    width: 100%;

    border: none;
    border-bottom: 1px solid #000;
  }

  @media (max-width: 425px) {
    width: 100%;
  }
`;

export const OsGroup = styled.div`
  display: ${(props) => (props.visible === 'true' ? 'flex' : 'none')};
  flex-direction: column;

  width: 85%;
  max-width: 500px;
  max-height: 60vh;

  padding: 10px 0;

  overflow-y: auto;
  position: absolute;
  top: 170px;
`;

export const Os = styled.div`
  display: flex;
  align-items: center;

  padding: 12px;
  width: 100%;
  height: 100%;
  min-height: 185px;

  border: 1px solid var(--dark-green);
  overflow: visible;

  & + div {
    margin-top: 10px;
  }
`;

export const OsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;

  > div {
    display: flex;
    align-items: center;
  }

  > div > strong {
    margin-right: 8px;
  }

  > div > span {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  > div + div {
    margin-top: 6px;
  }

  @media (max-width: 425px) {
    > div > strong,
    > div > span {
      font-size: 14px;
    }
  }
`;

export const CheckArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CalendarIcon = styled(FaCalendarDay)`
  margin-right: 6px;
  color: #403f3f;
`;

export const LoadButton = styled.button`
  && {
    display: ${(props) =>
      props.visible === 'true' ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;

    height: 36px;
    border: 2px solid var(--light-green-hover);

    cursor: pointer;

    color: var(--light-green-hover);
    font-size: 16px;
    font-weight: 600;
    background: transparent;

    &:hover {
      opacity: 0.6;
    }
  }
`;
