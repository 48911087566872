import styled from 'styled-components';

import { FaArrowLeft } from '../../Styles/Icons';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 20px 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 70px;
  width: 100%;
`;

export const Title = styled.h2`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 20px 0;
  position: relative;

  position: absolute;
  top: 0;
  left: 0;
`;

export const Back = styled.div`
  position: absolute;
  left: 5px;

  display: flex;
  align-items: center;

  padding: 10px;
  border-radius: 6px;
  cursor: pointer;

  > span {
    font-weight: 600;
    font-size: 18px;
    color: #333;
  }

  &:hover {
    border: 1px solid var(--light-green-hover);
  }

  @media (max-width: 460px) {
    > span {
      display: none;
    }
    > svg {
      margin: 0;
    }
  }
`;

export const LeftIcon = styled(FaArrowLeft)`
  width: 26px;
  height: 26px;
  color: var(--light-green-hover);
  margin-right: 15px;
`;
