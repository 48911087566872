/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LayerGroup, LayersControl } from 'react-leaflet';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import api from '@/Services/api';
import Map from '@/Components/Map';
import Tree from '@/Components/Tree';
import LoadPage from '@/Components/LoadPage';
import Subarea from '@/Components/Subarea';
import { toastWarning } from '../../../Utils/toast';

import { Container, Line, GlobalStyle } from './styles';

function MIP1(props) {
  const [trees, setTrees] = useState([]);
  const [areas, setAreas] = useState([]);
  const [center, setCenter] = useState([0, 0]);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const selectedMips = useSelector(
    (state) => state.selectedMips.mips
  );

  const token = localStorage.getItem('token');
  const search = props.location.search.split('&');

  const metodo = search[0].slice(8);

  useEffect(() => {
    getMips();
  }, []);

  async function getMips() {
    try {
      setIsLoading(true);

      const response = await api.post(
        `/Mapas/MIP${metodo}/getMips`,
        { mips: selectedMips?.map(({ mip }) => mip) },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data) {
        toastWarning({
          message:
            'Dados não encontrados. Verificar localização no apontamento!',
        });

        history.goBack();
        return;
      }

      const { geoMips, areas } = response?.data;

      setAreas(areas);

      const result = await convertGeoMips(
        geoMips,
        selectedMips?.map(({ mip }) => mip)
      );

      const subareas = [];

      const newMips = result?.map((mip) => {
        const subarea = mip?.data?.[0]?.subarea || '';

        const alreadyExists = subareas
          ?.filter((sub) => sub === subarea)
          .find((value) => value);

        if (!alreadyExists) {
          subareas.push(subarea);
          return {
            ...mip,
            checked: true,
          };
        }

        return {
          ...mip,
          checked: false,
        };
      });

      if (!newMips.length) {
        alert('Não encontramos nenhum dado!');
        history.goBack();
        return;
      }

      if (!areas.length) {
        alert(
          'Não encontramos nenhuma área, verifique as áreas da sua fazenda!'
        );
        history.goBack();
        return;
      }

      setTrees(newMips);
      setCenter(areas?.[0]?.geo?.[0]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function convertGeoMips(response, mips) {
    let result = [];
    let coords = [];

    mips.map((mip) => {
      coords = [];

      const aux = response.filter((value) => {
        const formatedDate = value?.data?.slice(0, 10);

        if (
          `${value?.os}${value?.praga}${value?.subarea}${formatedDate}`.toLowerCase() ===
          mip.toLowerCase()
        ) {
          coords.push([value?.latitude, value?.longitude]);
          return value;
        }
        return null;
      });

      result.push({ data: aux, coords });
      return null;
    });

    const notMips = result.filter((mip) => mip?.data?.length > 0);
    return notMips;
  }

  function getColor(tree) {
    const mipData = selectedMips?.filter(
      ({ data }) =>
        data?.subarea === tree?.data[0]?.subarea &&
        data?.praga === tree?.data[0]?.praga &&
        data?.os === tree?.data[0]?.os
    )[0];

    if (mipData?.data?.result === 'ALTO') return 'var(--bad)';
    if (mipData?.data?.result === 'MÉDIO') return 'var(--medium)';

    return 'var(--good)';
  }

  function getName(tree) {
    const style = `style="background: ${getColor(tree)};"`;
    const subarea = tree?.data[0].subarea;
    const praga = tree?.data[0].praga;

    const percent = tree?.data
      ?.reduce((prev, { pragaPercent }) => prev + pragaPercent, 0)
      .toFixed(2);

    return `<div class="identifier" ${style}></div>${subarea} - ${praga} - ${percent}%`;
  }

  if (isLoading) {
    return <LoadPage />;
  }

  function filtered(data) {
    const parsedData = data?.map((tree) => {
      return tree;
    });

    return parsedData;
  }

  return (
    <Container>
      <ToastContainer />

      <GlobalStyle />

      <Map props={{ center, zoom: 13 }}>
        {areas.map((area) => (
          <Subarea
            key={area.id}
            positions={area.geo}
            name={area.subarea}
          />
        ))}

        {/* <Filters>
          <input
            style={{
              width: 13,
              height: 13,
            }}
            type="checkbox"
            onChange={() => setAllChecked(!allChecked)}
            checked={allChecked}
          />
          <h3>Todos</h3>
        </Filters> */}

        <LayersControl position="topleft">
          {trees.map((tree, index) => (
            <LayersControl.Overlay
              checked={tree?.checked}
              key={`${tree?.data?.[0]?.subarea}${tree?.data?.[0]?.praga}${index}`}
              name={getName(tree)}
            >
              <LayerGroup key={tree.data[0].latitude}>
                {metodo === '1' &&
                  tree?.data?.map(({ result, ...rest }, index) => {
                    const treeCoord = tree?.coords[index];
                    const treeCoord2 = tree?.coords[index + 1];

                    if (index === tree?.coords.length - 1) {
                      return null;
                    }

                    const mipData = selectedMips?.filter(
                      ({ data }) =>
                        data?.subarea === rest?.subarea &&
                        data?.praga === rest?.praga &&
                        data?.os === rest?.os
                    )[0];

                    return (
                      <Line
                        key={`${treeCoord}${index}`}
                        positions={[treeCoord, treeCoord2]}
                        color={mipData?.data?.result} //ou resultado (se for por mip)
                      />
                    );
                  })}

                {filtered(tree.data).map((value, index) => {
                  return (
                    <Tree
                      key={index}
                      qtd={value?.qtd}
                      center={[value?.latitude, value?.longitude]}
                      id={value?.id}
                      props={{
                        data: value?.data,
                        subarea: value?.subarea,
                        praga: value?.praga,
                        metodo,
                        status: value?.status,
                      }}
                    />
                  );
                })}
              </LayerGroup>
            </LayersControl.Overlay>
          ))}
        </LayersControl>
      </Map>
    </Container>
  );
}

export default MIP1;
