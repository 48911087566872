import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Header from '@/Components/Header';
import cepea from '@/Services/cepea';

import { Container, Main, Content } from './styles';

const Farm = () => {
  const [authorized, setAuthorized] = useState(false);

  const logo = localStorage.getItem('logo');
  const history = useHistory();
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (!token) history.push('/Login');
    else setAuthorized(true);
  }, [history, token]);

  useEffect(() => {
    cepea.get('/indicador/boi-gordo.aspx').then((response) => {
      const Str = response.data;
      const index = Str.indexOf('imagenet-indicador1');

      const valorBoiPosition = Str.slice(index, index + 1000).split(
        'td'
      );

      const valorBoi = valorBoiPosition[3]
        .split('>')
        .join('')
        .split('</')
        .join('')
        .split(',')
        .join('.');

      localStorage.setItem('valorBoi', parseFloat(valorBoi));
    });
  }, []);

  if (!authorized) return null;

  return (
    <Container>
      <Header />
      <Content>
        <Main background={logo} />
      </Content>
    </Container>
  );
};

export default Farm;
