import styled from 'styled-components';

import StyledButton from '../Buttons';
import { IoMdCloseCircle } from '../../Styles/Icons';

export const Container = styled.div`
  padding: 32px 15px;
  height: 85vh;
  width: ${(props) => props.variation === 'loading' && '285px'};
  min-width: 275px;

  display: ${(props) =>
    props.display === 'true' ? 'block' : 'none'};

  overflow-y: auto;
  background: #141414;
  color: var(--white);

  border-radius: 10px;

  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10000;
  animation: appear 0.4s;

  > hr {
    background: #ccc;
    margin: 20px 0;
  }

  ::-webkit-scrollbar {
    width: 20px;
    background: #141414;

    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #2e5e485e;
    border-radius: 20px;
  }

  @media (max-width: 500px) {
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;

    border-radius: 0;
  }

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Header = styled.h2`
  display: flex;
  justify-content: center;

  font-size: 18px;
  font-weight: bold;

  margin-bottom: 20px;
  padding: 10px 0;
  border-radius: 5px;
  background: #313131;
`;

export const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 10px;

  border-radius: 5px;
  border: ${(props) =>
    props.outlined === 'true' ? '1px dotted green' : 'none'};
`;

export const RowDiv = styled.div`
  display: flex;
  justify-content: space-between;

  && + div {
    margin-top: 10px;
  }
`;

export const Button = styled(StyledButton)`
  && {
    width: 100%;
    margin-top: 20px;

    color: #fff;
    font-weight: bold;
  }
`;

export const CloseIcon = styled(IoMdCloseCircle)`
  position: absolute;
  top: 5px;
  left: 5px;

  width: 25px;
  height: 25px;
  color: #fff;

  transition: 0.2s;

  &:hover {
    transform: scale(1.3);
  }
`;
