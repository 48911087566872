import styled, { keyframes } from 'styled-components';

import { FaMapMarkedAlt } from '../../../../Styles/Icons';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  overflow-y: auto;

  padding: 50px 25px;
  width: 100%;
  height: 100%;
`;

const appear = keyframes`
  0%{opacity: 0;}
  100%{opacity: 1;}
`;

export const Op = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  min-width: 506px;

  padding: 15px;
  border-radius: 5px;
  border: 1px solid var(--light-green-hover);
  box-shadow: 3px 3px 12px #ddd, -3px -3px 12px #ddd;

  animation: ${appear} 0.2s linear;

  & + div {
    margin-top: 25px;
  }

  > span {
    font-weight: 600;
    margin-right: 20px;

    max-width: 367px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  > button {
    > span {
      > svg {
        display: none;
        width: 20px;
        height: 20px;
      }
    }
  }

  @media (max-width: 570px) {
    width: 100%;
    min-width: auto;

    > span {
      max-width: 72%;
    }

    > button {
      > span {
        > svg {
          display: block;
        }
      }
    }
    > button {
      > span {
        > span {
          display: none;
        }
      }
    }
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 506px;
  margin: 0 auto 50px;
  padding: 20px;

  border-radius: 5px;
  border: 1px solid #aaa;
  background: rgb(236, 240, 238);
  background: linear-gradient(
    19deg,
    rgba(236, 240, 238, 1) 0%,
    rgba(245, 245, 245, 1) 49%,
    rgba(237, 238, 238, 1) 99%
  );
  box-shadow: 0 0 8px rgb(0, 0, 0, 0.4);

  -ms-touch-select: none;
`;

export const Filter = styled.select`
  border: 1px solid var(--light-green-hover);
  border-radius: 3px;
  padding: 10px;
`;

export const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > strong {
    margin-bottom: 5px;
  }
`;

export const MapIcon = styled(FaMapMarkedAlt)``;
