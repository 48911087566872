import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description = styled.h1`
  display: flex;
  justify-content: center;

  text-align: center;
  color: #333;

  margin: 50px auto;
  padding: 0 20px;
  width: 100%;

  @media (max-width: 425px) {
    font-size: 20px;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-area: auto;
  gap: 80px;

  width: 100%;

  padding: 30px 40px;
  margin-bottom: 20px;

  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 630px) {
    grid-template-columns: 1fr;
  }
`;
