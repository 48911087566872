import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Container from '../../../../Components/PreMapContainer';
import FarmsContainer from '../../../../Components/FarmsContainer';
import Button from '../../../../Components/Buttons';
import { setSelectedFarms } from '../../../../Store/Actions/selectedFarms';
import { toastWarning } from '../../../../Utils/toast';

import { SubmitArea, Date, CalendarIcon } from './styles';

function Armadilhas() {
  const [data1, setData1] = useState('');
  const [data2, setData2] = useState('');

  const selectedFarms = useSelector(
    (state) => state.selectedFarms.farms
  );
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedFarms([]));
  }, [dispatch]);

  function handleSubmit() {
    if (selectedFarms.length === 0) {
      toastWarning({ message: 'Selecione uma fazenda!' });
    } else if (data1 === '' || data2 === '') {
      toastWarning({ message: 'Selecione um intervalo de data!' });
    } else {
      history.push(
        `/Mapas/Armadilhas?data1=${data1}&data2=${data2}&farms=${selectedFarms}`
      );
    }
  }

  return (
    <Container>
      <ToastContainer />

      <FarmsContainer>
        <SubmitArea>
          <Date>
            <h2>
              <CalendarIcon />
              Inicial
            </h2>
            <input
              type="date"
              onChange={(e) => setData1(e.target.value)}
            />
          </Date>
          <Date>
            <h2>
              <CalendarIcon />
              Final
            </h2>
            <input
              type="date"
              onChange={(e) => setData2(e.target.value)}
            />
          </Date>
        </SubmitArea>

        <Button onClick={handleSubmit}>Carregar</Button>
      </FarmsContainer>
    </Container>
  );
}

export default Armadilhas;
