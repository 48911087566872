import styled from 'styled-components';

import InfoContainer from '../../../../../Components/InfoContainer';

export const Container = styled(InfoContainer)``;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
`;

export const RowDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + div {
    margin-top: 10px;
  }

  > strong {
    margin-right: 20px;
    color: #fff;
  }
`;
