import React from 'react';

import logo from '../../Assets/Logo.png';

import { Container, Logo } from './styles';

const HeaderEditable = ({ children }) => {
  return (
    <Container>
      <Logo src={logo} />

      {children}
    </Container>
  );
};

export default HeaderEditable;
