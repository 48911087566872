import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Container } from './styles';

function Tractor({ props, color }) {
  const coords = props[0];

  const situation = useSelector((state) => state.tractor.situation);

  const [position, setPosition] = useState(0);

  useEffect(() => {
    if (situation === 'PLAY') {
      setTimeout(() => {
        position < coords.length - 1 && setPosition(position + 1);
      }, 500);
    } else if (situation === 'PAUSE') {
      setPosition(0);
    }
    //eslint-disable-next-line
  }, [position, situation]);

  return (
    <Container
      center={coords[position]}
      duration={200}
      color={color}
      radius={9}
    />
  );
}

export default Tractor;
