import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #eee;
`;

export const Loading = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 30px;
  font-weight: 600;

  @media (max-width: 320px) {
    font-size: 26px;
  }
`;
