import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setDisplay2 } from '@/Store/Actions/infoDisplayAction';

import IntNumber from '@/Components/IntNumber';
import DecimalNumber from '@/Components/DecimalNumber';

import {
  Container,
  RowDiv,
  Title,
  CloseIcon,
  Content,
  Header,
} from './styles';

function GeralInfo({ data }) {
  const dispatch = useDispatch();
  const display = useSelector((state) => state.infoDisplay.display2);
  const valorBoi = localStorage.getItem('valorBoi');

  const { geralInfo, especie, raca } = data;

  const racaLength = raca.split(',');

  return (
    <Container visible={display}>
      <Header>
        <CloseIcon onClick={() => dispatch(setDisplay2(false))} />
        <strong>Dados Gerais</strong>
      </Header>

      <Content>
        <RowDiv>
          <strong>Espécie</strong>
          <span>{especie}</span>
        </RowDiv>
        <RowDiv>
          <strong>Raça</strong>
          <span>{racaLength.length > 2 ? 'Todas' : raca}</span>
        </RowDiv>

        <hr />

        <Title>Animais</Title>

        <RowDiv>
          <strong>Total</strong>
          <IntNumber type="colored">{geralInfo.qtdTotal}</IntNumber>
        </RowDiv>
        <RowDiv>
          <strong>Fêmeas</strong>
          <IntNumber type="colored">{geralInfo.qtdFemea}</IntNumber>
        </RowDiv>
        <RowDiv>
          <strong>Machos</strong>
          <IntNumber type="colored">{geralInfo.qtdMacho}</IntNumber>
        </RowDiv>
        <RowDiv>
          <strong>Peso Total</strong>
          <DecimalNumber type="colored">
            {geralInfo.totalPeso}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>UA Total</strong>
          <DecimalNumber type="colored">
            {geralInfo.totalUA}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>@ Total</strong>
          <DecimalNumber type="colored">
            {geralInfo.totalArroba}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>Média de Peso</strong>
          <DecimalNumber type="colored">
            {geralInfo.mediaPeso}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>Média de UA</strong>
          <DecimalNumber type="colored">
            {geralInfo.mediaUA}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>Média @</strong>
          <DecimalNumber type="colored">
            {geralInfo.mediaArroba}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>Cotação @</strong>
          <DecimalNumber type="colored">
            {parseFloat(valorBoi)}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>Valor Médio</strong>
          <DecimalNumber type="colored">
            {geralInfo.mediaValor}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>Valor Total</strong>
          <DecimalNumber type="colored">
            {geralInfo.valorTotal}
          </DecimalNumber>
        </RowDiv>
      </Content>
    </Container>
  );
}

export default GeralInfo;
