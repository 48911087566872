import React, { useEffect, useState } from 'react';

import api from '@/Services/api';
import formatDate from '@/Utils/formatDate';
import LogoAgrosUrl from '@/Assets/greenAgrosLogo.png';

import LoadPage from '@/Components/LoadPage';

import {
  Container,
  Period,
  RowDiv,
  Header,
  Content,
  Table,
  TitleValue,
  Title,
  Value,
  LogoAgros,
  LogoFarm,
  Praga,
  Button,
} from './styles';

function ArmadilhasResumo(props) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const logo = localStorage.getItem('logo');

  const search = props.location.search.split('&');
  const token = localStorage.getItem('token');

  const data1 = search[0].slice(7);
  const data2 = search[1].slice(6);
  const dias = search[2].slice(5);
  const farms = decodeURIComponent(search[3].slice(6));

  useEffect(() => {
    setIsLoading(true);

    api
      .get(
        `/Armadilhas/getResume?data1=${data1}&data2=${data2}&farms=${farms}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setData(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [token, data1, data2, farms]);

  if (isLoading) {
    return <LoadPage />;
  }

  return (
    <Container>
      <Header>
        <LogoAgros src={LogoAgrosUrl} />

        <Period>
          <RowDiv>
            <strong>Período:</strong>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {formatDate(data1)} - {formatDate(data2)}
            </span>
          </RowDiv>

          <RowDiv>
            <strong>Dias:</strong>
            <span>{dias}</span>
          </RowDiv>
        </Period>

        <LogoFarm src={logo} />
      </Header>

      <Button className="no-print" onClick={() => window.print()}>
        Imprimir
      </Button>

      <Content>
        <Table>
          <TitleValue>
            <Title>Fazenda</Title>

            {data.map((value) => (
              <Value key={value.armId}>{value.fazenda}</Value>
            ))}
          </TitleValue>

          <TitleValue>
            <Title>Subarea</Title>

            {data.map((value) => (
              <Value key={value.armId}>{value.subarea}</Value>
            ))}
          </TitleValue>

          <TitleValue>
            <Title>Armadilha</Title>

            {data.map((value) => (
              <Value key={value.armId}>{value.armId}</Value>
            ))}
          </TitleValue>

          <TitleValue>
            <Title>Praga</Title>

            {data.map((value) => (
              <Praga key={value.armId}>{value.praga}</Praga>
            ))}
          </TitleValue>

          <TitleValue>
            <Title>Encontradas</Title>

            {data.map((value) => (
              <Value key={value.armId}>{value.qtd}</Value>
            ))}
          </TitleValue>
        </Table>
      </Content>
    </Container>
  );
}

export default ArmadilhasResumo;
