import styled, { css, createGlobalStyle } from 'styled-components';
import { Polyline, Tooltip } from 'react-leaflet';

import { FaStop, FaPause, FaPlay } from '@/Styles/Icons';

export const GlobalStyle = createGlobalStyle`
  .leaflet-control-layers{
    top: 129px;
  }

  #tractorIdentifier{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #333;

    margin-left: 15px;
  }
`;

export const Container = styled.div`
  height: 100vh;
`;

export const StyledPolyline = styled(Polyline).attrs((props) => {})`
  stroke: ${(props) =>
    props.lineColor === 'SGR' ? '#42a420' : '#da3030'};

  &:hover {
    animation: animation 2s infinite;
  }

  @keyframes animation {
    0% {
      stroke: #1b01eb;
    }

    50% {
      stroke: #09e5eb;
    }
    100% {
      stroke: #1b01eb;
    }
  }
`;

export const Tractor = styled(Tooltip).attrs((props) => {
  props.direction = 'center';
  props.opacity = 1;
  props.permanent = true;
})`
  width: 20px;
  height: 20px;

  background: red;
  border-radius: 50%;
  border: none;

  opacity: 1;

  ::before {
    border: none;
  }
`;

export const DivIcon = styled.div`
  width: 30px;
  height: 30px;

  z-index: 10000;
  position: relative;
  background: #ff0000;
`;

export const Control = styled.div`
  z-index: 1000;
  overflow-y: auto;

  position: absolute;
  top: 70px;
  left: 10px;

  background: #101010;
  color: #eee;
  padding: 10px;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
`;

export const TractorGroup = styled.div.attrs((props) => {})`
  .no-display {
    display: none;
  }
`;

export const TractorControl = styled.div`
  display: flex;

  justify-content: space-around;
  align-items: center;

  padding: 8px;
  margin-top: 90px;

  > .tractor-present {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 20px;

    > img {
      margin-right: 8px;
    }

    > span {
      font-size: 18px;
      font-weight: 600;
    }
  }

  > input {
    margin-left: 10px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: #2f302f;
  padding: 8px;

  font-size: 16px;
  font-weight: 600;
  color: var(--white);
`;

export const HeaderPlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
`;

export const PlayControlBox = styled.div`
  position: absolute;
  top: 81px;
  left: 12px;

  padding: 10px 8px;
  background: #fff;
  background-size: 100%;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  z-index: 10000;

  > div + div {
    margin-top: 5px;
  }
`;

export const Filters = styled.div`
  position: absolute;
  top: 180px;
  left: 10px;
  padding: 10px 8px 10px 6px;
  height: 62px;
  background: #fff;

  border-radius: 5px;
  gap: 4px;

  display: flex;

  overflow: hidden;
  z-index: 900;

  h3 {
    font-size: 15px;
    margin-top: -4px;
  }
`;

export const Play = styled.div``;
export const Stop = styled.div``;
export const Pause = styled.div``;

const iconCSS = css`
  width: 15px;
  height: 15px;

  transition: 0.3s;
  cursor: pointer;

  &:hover {
    transform: scale(1.3);
  }
`;

export const StopIcon = styled(FaStop)`
  ${iconCSS}
`;
export const PauseIcon = styled(FaPause)`
  ${iconCSS}
`;
export const PlayIcon = styled(FaPlay)`
  ${iconCSS}
`;

export const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 1rem;
  gap: 0.6rem;

  main {
    display: flex;
    flex-direction: column;
    z-index: 1000;
    gap: 0.2rem;
  }

  div {
    display: flex;
    flex-direction: column;
    z-index: 1000;
    width: 2rem;
    height: 4.2rem;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 4px;
    border: 2px solid var(--dark-green) !important;
    cursor: pointer;

    gap: 0.5rem;
  }

  select {
    width: 16rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    border: 2px solid var(--dark-green) !important;
    padding: 5px;

    border-radius: 4px;
    border: 2px solid #fff;
    background: #fff;

    option {
      width: 12rem;
    }
  }

  @media (max-width: 425px) {
    top: 60px;

    select {
      width: 10rem;
    }
  }
`;
