import styled, { css } from 'styled-components';
import { TableRow } from '@material-ui/core';

export const Container = styled(TableRow)`
  && {
    ${(props) =>
      props.clickable &&
      css`
        cursor: pointer;

        &:hover {
          background: #bbb;
        }
      `}
  }
`;
