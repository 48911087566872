import styled, { keyframes } from 'styled-components';
import { CircleMarker } from 'react-leaflet';

export const pulse = keyframes`
 0%{
    stroke-width: 0px;
  }

  10%{
    stroke-width: 2px;
  }

  20%{
    stroke-width: 4px;
  }
  30%{
    stroke-width: 6px;
  }

  40%{
    stroke-width: 8px;
  }
  50%{
    stroke-width: 6px;
  }

  60%{
    stroke-width: 4px;
  }

  70%{
    stroke-width: 2px;
  }
  80%{
    stroke-width: 1px;
  }

  100%{
    stroke-width: 0px;
  }
`;

export const Container = styled(CircleMarker)`
  && {
    fill: ${(props) => props.color};
    fill-opacity: 1;
    stroke: ${(props) => props.color};
    stroke-width: 1px;
    transition: 0.9s;
    animation: ${pulse} 1.3s infinite;
  }
`;
