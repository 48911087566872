import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background: rgb(15, 15, 15);
  padding: 8px;
  border-radius: 5px;

  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const Identifier = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + div {
    margin-top: 10px;
  }

  > div {
    width: 25px;
    height: 25px;

    border-radius: 5px;
    background: ${(props) => props.color};

    margin-right: 20px;
  }

  > span {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  & + div {
    margin-left: 15px;
  }
`;
