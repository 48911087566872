import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Container from '../../../../Components/PreMapContainer';
import MIPContainer from '../../../../Components/MIPContainer';
import LoadSpinner from '../../../../Components/LoadSpinner';
import api from '../../../../Services/api';
import { setSelectedMips } from '../../../../Store/Actions/setSelectedMips';
import { toastWarning } from '../../../../Utils/toast';

import { Content, Button, BackIcon, LoadContainer } from './styles';

function MIP1Results(props) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const token = localStorage.getItem('token');
  const history = useHistory();
  const search = decodeURIComponent(props.location.search).split('&');
  const dispatch = useDispatch();

  const selectedMips = useSelector(
    (state) => state.selectedMips.mips
  );

  const farms = search[0].slice(7);
  const data1 = search[1].slice(6);
  const data2 = search[2].slice(6);
  const result = search[3].slice(7);
  const praga = search[4].slice(6);
  const metodo = search[5].slice(7);
  const status = search[6].slice(7);

  const query = `${
    result !== 'null' ? `&result=${result}` : '&result='
  }${praga !== 'null' ? `&praga=${praga}` : '&praga='}${
    status !== 'null' ? `&status=${status}` : '&status='
  }`;

  useEffect(() => {
    dispatch(setSelectedMips([]));

    setIsLoading(true);

    api
      .get(
        `/MIP${metodo}?farms=${farms}&data1=${data1}&data2=${data2}${query}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setData(response.data);

        if (!response.data.length) {
          toastWarning({ message: 'Não encontramos nenhum dado!' });
        }

        setIsLoading(false);
      });
  }, [token, farms, data1, data2, query, dispatch]);

  function handleSubmit() {
    if (selectedMips.length === 0) {
      toastWarning({ message: 'Selecione um mip!' });
    } else {
      history.push(`/Mapas/MIP?metodo=${metodo}`);
    }
  }

  if (isLoading) {
    return (
      <Container>
        <ToastContainer />

        <LoadContainer>
          <LoadSpinner props={{ size: 80 }} />
        </LoadContainer>
      </Container>
    );
  }

  return (
    <Container>
      <ToastContainer />

      <BackIcon onClick={() => history.goBack()} />
      <Content>
        {data.map((mip, index) => (
          <MIPContainer props={mip} key={index} />
        ))}
      </Content>
      <Button onClick={handleSubmit}>Carregar Mapa</Button>
    </Container>
  );
}

export default MIP1Results;
