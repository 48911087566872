import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  .leaflet-control-layers{
    top: 40px;
  }

  .leaflet-control-layers-toggle{
    background-image: url(${require('../../../Assets/appIcons/Person.png')});
    background-size: 27px;
  }
`;

export const Container = styled.div`
  height: 100vh;
`;
