import styled from 'styled-components';
import SButton from '../../../../Components/Buttons';
import { FaArrowLeft } from '../../../../Styles/Icons';

export const Content = styled.div`
  width: 100%;
  height: 70%;
  margin-top: 50px;

  overflow-y: auto;
`;

export const LoadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const Button = styled(SButton)`
  && {
    margin-top: 20px;
    color: #ffff;
    padding: 10px;
    width: 20vw;
    min-width: 200px;
  }
`;

export const BackIcon = styled(FaArrowLeft)`
  position: absolute;
  top: 10px;
  left: 10px;

  width: 25px;
  height: 25px;

  color: var(--light-green-hover);
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    transform: scale(1.3);
  }
`;
