import styled, { createGlobalStyle } from 'styled-components';
import { Map } from 'react-leaflet';

export const GlobalStyle = createGlobalStyle`
  .leaflet-top.leaflet-right{
    z-index: 1000000;
  }
`;

export const Container = styled(Map).attrs({})`
  height: 100vh;
  position: relative;
`;

export const Logo = styled.img`
  position: absolute;
  left: -735px;
  top: -206px;
  opacity: 0.7;
  z-index: 1000;

  transform: scale(0.15);

  @media (max-width: 425px) {
    display: none;
  }
`;

export const MapTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10rem;
  cursor: pointer;

  div > svg {
    color: rgba(3, 43, 11, 0.82);
  }

  div {
    display: flex;
    position: absolute;
    bottom: 11px;

    width: 2.4rem;
    height: 2.4rem;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    border-radius: 4px;
    border: 2px solid #fff;
    background: #fff;

    @media (max-width: 425px) {
      top: 10px;
    }
  }

  div:first-child {
    left: 5rem;

    @media (max-width: 425px) {
      left: 4rem;
    }
  }

  div {
    left: 8rem;

    @media (max-width: 425px) {
      left: 7rem;
    }
  }

  div:last-child {
    left: 11rem;

    @media (max-width: 425px) {
      left: 10rem;
    }
  }
`;
