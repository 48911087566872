import styled from 'styled-components';

export const SubmitArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  > button {
    width: 100%;
    color: var(--white);
  }
`;

export const InfoArea = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  width: 100%;
  padding: 6px;

  @media (max-width: 375px) {
    flex-direction: column;
  }
`;

export const Date = styled.div`
  display: flex;
  align-items: center;

  > span {
    margin-right: 6px;
    font-weight: bold;
  }

  @media (max-width: 375px) {
    margin-bottom: 6px;
  }
`;

export const Plagues = styled.div`
  display: flex;
  align-items: center;

  > span {
    margin-right: 6px;
    font-weight: bold;

    display: flex;
    align-items: center;

    > svg {
      margin-right: 5px;
    }
  }
`;
