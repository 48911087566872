import styled from 'styled-components';
import { IoMdCloseCircle } from '@/Styles/Icons';

export const Container = styled.div`
  display: ${(props) => (props.visible ? 'flex' : 'none')};

  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: ${(props) => props.variation === 'loading' && '285px'};
  max-height: 75vh;
  background: #141414;
  color: var(--white);

  border-radius: 10px;
  position: absolute;
  top: 90px;
  right: 10px;
  z-index: 10000;
  animation: appear 0.4s;

  > svg {
    position: absolute;
    top: 5px;
    left: 5px;

    width: 20px;
    height: 20px;

    transition: 0.2s;
    cursor: pointer;

    &:hover {
      transform: scale(1.3);
    }
  }

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 500px) {
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    max-height: 100vh;

    border-radius: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;
  width: 100%;
  padding: 0 15px;

  position: relative;

  border: 1px solid var(--light-green-hover);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  > strong {
    font-size: 18px;
    font-weight: bold;
  }
`;

export const RowDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + div {
    margin-top: 15px;
  }

  > strong,
  > span {
    font-size: 14px;
  }

  > strong {
    margin-right: 25px;
  }

  > button {
    width: 100%;
  }
`;

export const Content = styled.div`
  padding: 20px 15px;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 20px;
    background: #141414;

    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #2e5e485e;
    border-radius: 20px;
  }
`;

export const CloseIcon = styled(IoMdCloseCircle)`
  position: absolute;
  left: 5px;

  width: 20px;
  height: 20px;

  transition: 0.2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.3);
  }
`;
