import React from 'react';
import Lottie from 'react-lottie';

import Header from '@/Components/Header';
import BuildingLottie from '@/Assets/lottie/building.json';

import { Container, Content, LottieContainer } from './styles';

export default function Compras() {
  return (
    <Container>
      <Header />

      <Content>
        <LottieContainer>
          <h2>Em desenvolvimento</h2>

          <Lottie
            width={600}
            options={{ animationData: BuildingLottie, loop: true }}
          />
        </LottieContainer>
      </Content>
    </Container>
  );
}
