import { GiCow, FaTractor, MdPerson, FaBug } from '@/Styles/Icons';
import React from 'react';

export default function getAppIcon({ iconName }) {
  switch (iconName) {
    case 'MECANIZADO':
      return () => <FaTractor size={20} color="#5ace0e" />;
    case 'MOVIMENTAÇÃO':
      return () => <GiCow size={25} color="#5ace0e" />;
    case 'MIPD':
      return () => <FaBug size={25} color="#5ace0e" />;
    default:
      return () => <MdPerson size={20} color="#5ace0e" />;
  }
}
