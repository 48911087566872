export default function polygonToJson(polygon) {
  let parsedPolygon = polygon
    .replace('POLYGON(', '')
    .replace('))', '')
    .replace('(', '')
    .split(' ')
    .join(', ');

  let value1 = parsedPolygon.split(',');

  //eslint-disable-next-line
  let value2 = value1.map((str, position) => {
    if (value1.indexOf(str) % 2 === 1) {
      return [
        parseFloat(value1[position - 1]),
        parseFloat(value1[position]),
      ];
    }
  });

  let value3 = value2.filter((value) => value !== undefined);

  return value3;
}
