import React from 'react';

import {
  Container,
  Header,
  CloseIcon,
  Content,
  Loader,
} from './styles';

function InfoContainer({
  children,
  visible,
  top,
  left,
  right,
  onClose,
  loading,
}) {
  return (
    <Container visible={visible} top={top} left={left} right={right}>
      <Header>
        <CloseIcon onClick={onClose} />
        Dados técnicos
      </Header>

      <Content>
        {loading ? <Loader props={{ size: 60 }} /> : children}
      </Content>
    </Container>
  );
}

export default InfoContainer;
