import styled from 'styled-components';

import StyledButton from '../../Components/Buttons';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  flex-direction: column;

  position: relative;

  @media print {
    .no-print {
      display: none;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  padding: 15px 30px;
`;

export const Period = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowDiv = styled.div`
  & + div {
    margin-top: 10px;
  }

  > strong {
    margin-right: 6px;
  }

  > strong,
  > span {
    font-size: 24px;
  }

  @media (max-width: 430px) {
    > strong,
    > span {
      font-size: 18px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
`;

export const Table = styled.div`
  overflow-x: auto;

  display: flex;
  width: 100%;

  border-radius: 10px;
  border: 1px solid #555;
  margin: 50px 10px 0 10px;

  @media (min-width: 800px) {
    max-width: 95vw;
  }
`;

export const TitleValue = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: relative;

  width: 100%;
`;

export const Praga = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 8px 15px;

  &:nth-child(even) {
    background: #bbb;
  }

  @media (min-width: 800px) {
    font-size: 22px;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;

  padding: 15px;

  background: var(--dark-green);
  color: #fff;
  font-size: 16px;
  font-weight: bold;

  @media (min-width: 800px) {
    font-size: 22px;
  }
`;

export const Value = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;

  display: flex;
  justify-content: center;

  padding: 8px 15px;

  &:nth-child(even) {
    background: #bbb;
  }

  @media (min-width: 800px) {
    font-size: 22px;
  }
`;

export const LogoAgros = styled.img`
  && {
    width: 255px;
    height: 74px;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

export const LogoFarm = styled.img`
  && {
    width: 155px;
    height: 140px;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

export const Button = styled(StyledButton)`
  && {
    width: 150px;
    margin: 0 auto;
    color: #fff;
  }
`;
