import styled, { createGlobalStyle } from 'styled-components';
import { Polyline } from 'react-leaflet';

export const Container = styled.div``;

export const Rastro = styled(Polyline)`
  transition: 0.3s;
  stroke: ${(props) => props.color};

  &:hover {
    stroke: #fff;
  }
`;

export const GlobalStyle = createGlobalStyle`
  .leaflet-control-layers{
    top: 0;
  }

  .leaflet-touch.leaflet-touch .leaflet-control-layers {
    margin-top: 35px;
  }

  .leaflet-control-layers-overlays{
    >label div{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    >label + label{
      margin-top: 8px;
    }
  }

  .identifier {
    width: 30px;
    height: 30px;

    margin-left: 8px;
    border-radius: 6px;
  }
`;

export const TractorPopup = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  > #tractor {
    background: ${(props) => props.background};
    border-radius: 50%;
    width: 20px;
    height: 20px;

    margin-bottom: 5px;
  }

  > span {
    font-weight: 600;
    color: var(--white);
  }
`;

export const Filters = styled.div`
  position: absolute;
  top: 80px;
  left: 10px;
  padding: 10px 8px 10px 6px;
  height: 62px;
  background: #fff;

  border-radius: 5px;
  gap: 4px;

  display: flex;

  overflow: hidden;
  z-index: 900;

  h3 {
    font-size: 15px;
    margin-top: -4px;
  }
`;

export const colors = [
  '#d31109',
  '#80ee38',
  '#3f0eb9',
  '#e44841',
  '#ee9838',
  '#6e45d3',
  '#5ace0e',
  '#2b5e0a',
  '#eb830f',
  '#0fc4b7',
  '#290c70',
  '#0a5e58',
  '#63390a',
  '#630e0a',
  '#fff900',
  '#fff',
  '#555',
  '#773506',
  '#a22c13',
  '#1b3a12',
  '#124630',
  '#3365bd',
  '#9a33bd',
  '#350644',
  '#440636',
];
