import styled from 'styled-components';

export const Container = styled.span`
  color: ${(props) => {
    if (props.type === 'colored') {
      if (props.variation >= 0) return '#2cff05';
      else if (props.variation < 0) return '#ff2424';
      else return '#0dd7ec';
    } else return props.color;
  }};
`;
