import api from '../api';
import { useQueryFetch } from '../use-query-fetch';

export function useFetchFarms(token) {
  const { data, ...query } = useQueryFetch({
    queryKey: ['FARMS', token],
    handler,
    config: {
      staleTime: 2 * 60000,
      refetchInterval: 2 * 60000,
      refetchIntervalInBackground: true,
      enabled: Boolean(token),
    },
  });

  async function handler() {
    const { data } = await api.get('/Farms', {
      headers: { authorization: `Bearer ${token}` },
    });

    return data;
  }

  return { ...query, data: data || [] };
}
