import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: fit-content;
`;

export const Title = styled.h3`
  display: flex;
  justify-content: center;

  text-align: center;
  font-size: 24px;

  margin-bottom: 8px;
  width: 100%;
`;

const downToUp = keyframes`
  0%{top: 0;}
  50%{top: 25%;}
  100%{top: 50%;}
`;

export const Image = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 238px;
  height: 238px;

  border-radius: 7px;
  border: 2px solid #222;
  background: black;
  background-image: url(${(props) => props.bg_image});
  background-size: 130%;
  background-position: center;
  background-repeat: no-repeat;

  box-shadow: -5px 5px 5px rgb(0, 0, 0, 0.6);

  cursor: pointer;
  transition: 0.2s;

  &:hover {
    filter: brightness(50%);
    background-size: 200%;
  }

  &:hover {
    > svg {
      display: block;
      animation: ${downToUp} 0.2s linear;
    }
  }

  > svg {
    display: none;

    width: 45px;
    height: 45px;

    position: absolute;

    margin: 0 auto;
    color: #fff;
  }
`;
