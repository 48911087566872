import React from 'react';

import { Container } from './styles';

function IntNumber({ children, variation, color, type }) {
  return (
    <Container variation={variation} color={color} type={type}>
      {(children * 1).toLocaleString('de-DE', {
        maximumFractionDigits: 0,
      })}
    </Container>
  );
}

export default IntNumber;
