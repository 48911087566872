import styled, { css } from 'styled-components';

import { FaMobile } from '../../Styles/Icons';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FarmsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 50px;
  justify-content: space-around;

  @media (max-width: 510px) {
    padding: 20px;
  }
`;

export const Farm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transition: 0.3s;
  margin: 30px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;

  &:hover {
    transform: scale(1.2);

    img {
      filter: blur(5px);
      opacity: 0.7;
    }

    button {
      opacity: 1;
    }
  }

  h3 {
    display: flex;
    justify-content: center;
    text-align: center;
    color: #aaa;
    font-weight: 500;
    font-size: 36px;
    margin: 0;
  }

  img {
    width: 330px;
  }

  button {
    position: absolute;

    margin: 0 auto;
    width: 40%;
    height: 45px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 10px;
    border: none;
    color: var(--white);
    font-weight: 600;
    opacity: 0;
    transition: all 0.2s;
  }

  button:hover {
    opacity: 1;
  }

  @media (max-width: 510px) {
    & {
      margin: 20px 0;
    }

    img {
      width: 75vw;
    }
  }
`;

export const FormLogin = styled.form`
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  background: rgba(42, 45, 42, 0.9);
  box-shadow: 0 0 18px rgb(0, 0, 0, 0.8);
  border-radius: 10px;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: ${(props) => props.display};

  svg {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 25px;
    color: #c10000;
    transition: 0.3s;
    cursor: pointer;
  }

  svg:hover {
    transform: scale(1.4);
  }

  h3 {
    font-size: 32px;
    color: var(--white);
    display: flex;
    justify-content: center;
    margin-top: 0;
  }

  div {
    width: 100%;
    margin: 30px 0;

    input {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      padding: 6px 8px;
      transition: 0.3s;
      color: ${(props) =>
        props.error === true ? '#f00' : 'var(--white)'};

      ::placeholder {
        color: var(--white);
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  button {
    color: var(--white);
    width: 100%;
    padding: 10px 0;
    margin-top: 20px;
  }

  @media (max-width: 535px) {
    padding: 30px 20px;
    width: 80vw;
  }
`;

export const Apps = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 15px;
  float: right;

  &:hover {
    > span {
      color: var(--light-green-hover);
    }

    > svg {
      fill: var(--light-green-hover);
    }
  }

  > svg {
    margin-right: 8px;
  }

  > span {
    color: var(--white);
    font-size: 18px;
    transition: all 0.2s;
  }

  @media (max-width: 375px) {
    > span {
      display: none;
    }
  }
`;

export const iconCSS = css`
  font-size: 22px;
  fill: var(--white);
  margin-right: 5px;
`;

export const MobileIcon = styled(FaMobile)`
  ${iconCSS}
`;
