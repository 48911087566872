import React from 'react';
// import { Polygon } from 'react-leaflet';

import { Tooltip, PolygonArea } from './styles';

export default function Subarea({ positions, name, color }) {
  return (
    <PolygonArea
      positions={positions}
      fillColor="#d3d3d3"
      color="#d3d3d3"
    >
      <Tooltip>{name}</Tooltip>
    </PolygonArea>
  );
}
