import React from 'react';
import { useHistory } from 'react-router-dom';

import { Container, Title, Image } from './styles';

function MapContainer({ title, image, to, icon }) {
  const history = useHistory();

  return (
    <Container>
      <Title>{title}</Title>
      <Image bg_image={image} onClick={() => history.push(to)}>
        {icon}
      </Image>
    </Container>
  );
}

export default MapContainer;
