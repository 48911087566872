import React from 'react';
import { Marker } from 'react-leaflet';
import { icon } from 'leaflet';

function Bica({ props }) {
  return (
    <Marker
      position={[props.latitude, props.longitude]}
      icon={icon({
        iconUrl: require(`../../Assets/water-drop.png`),
        iconSize: [25, 25],
      })}
    />
  );
}

export default Bica;
