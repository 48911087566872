import React from 'react';

import { Container, LoadIcon } from './styles';

function LoadSpinner({ props }) {
  return (
    <Container>
      <LoadIcon size={props.size} />
    </Container>
  );
}

export default LoadSpinner;
