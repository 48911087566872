import styled from 'styled-components';

export const SubmitArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > button {
    width: 100%;
    margin-top: 8px;
    color: var(--white);
  }
`;

export const DateArea = styled.div`
  margin-top: 8px;

  > div > span {
    margin-right: 6px;
    font-weight: bold;
  }
`;
