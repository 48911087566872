import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 85vh;
  background: #141414;
  color: var(--white);

  border-radius: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10000;
  animation: appear 0.4s;

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 500px) {
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;

    border-radius: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  padding: 8px;

  border-radius: 3px;
  border: 1px dotted var(--light-green-hover);

  > div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;

    > span {
      margin: 0 10px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  > svg {
    width: 20px;
    height: 20px;

    transition: 0.2s;
    cursor: pointer;

    &:hover {
      transform: scale(1.3);
    }
  }
`;

export const Number = styled.span.attrs((props) => {})`
  color: ${(props) =>
    props.variation >= 0
      ? '#2cff05'
      : props.variation === undefined
      ? '#0dd7ec'
      : '#ff2424'};

  font-weight: 600;
  margin-right: 5px;

  > span {
    margin-left: 3px;
  }
`;

export const Content = styled.div`
  padding: 10px 5px;
  overflow-y: auto;
  height: 100%;

  ::-webkit-scrollbar {
    width: 15px;
    background: #141414;

    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #2e5e485e;
    border-radius: 20px;
  }
`;

export const InfoContainer = styled.div`
  padding: 10px 0;

  & + div {
    border-top: 1px solid #00e9e930;
  }

  > h3 {
    color: #777;
    text-align: center;
    margin-bottom: 10px;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  > div + div {
    margin-top: 6px;
  }

  > div span {
    margin-left: 28px;
  }
`;
