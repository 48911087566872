import styled from 'styled-components';
import { Accordion } from '@material-ui/core';

import { IoIosArrowDown } from '../../Styles/Icons';

export const Container = styled.div`
  width: 100%;

  padding: 0 15px;

  & + div {
    margin-top: 20px;
  }
`;

export const Content = styled(Accordion)`
  && {
    background: var(--dark-green);
    color: #fff;
    border: 2px solid var(--light-green-hover);
  }
`;

export const Mip = styled.div`
  width: 100%;

  overflow-x: auto;
`;

export const MipHeader = styled.div`
  display: flex;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 15px;

  border-bottom: 1px dotted var(--light-green-hover);

  > span {
    display: flex;
    justify-content: center;
    min-width: 120px;
    text-align: center;
  }
`;

export const MipContent = styled.div`
  display: flex;
  align-items: center;

  width: fit-content;
  padding: 8px 15px;
  margin: 0 auto;
  margin-bottom: 10px;

  border: 2px solid
    ${(props) => {
      if (props.status === 'BAIXO') return 'var(--good)';
      else if (props.status === 'MÉDIO') return 'var(--medium)';
      else if (props.status === 'ALTO') return 'var(--bad)';
    }};
  border-radius: 5px;

  & + div {
    margin-top: 20px;
  }

  > span,
  > input {
    display: flex;
    justify-content: center;
    min-width: 120px;
    max-width: 120px;
  }

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

export const ArrowUp = styled(IoIosArrowDown)`
  && {
    color: var(--light-green-hover);
  }
`;

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 15px solid
    ${(props) => {
      if (props.status === 'BAIXO') return 'var(--good)';
      else if (props.status === 'MÉDIO') return 'var(--medium)';
      else if (props.status === 'ALTO') return 'var(--bad)';
      else return '#fff';
    }};
`;

export const Circle = styled.div`
  width: 17px;
  height: 17px;
  border-radius: 50px;
  background-color: ${(props) => {
    if (props.status === 'BAIXO') return 'var(--good)';
    else if (props.status === 'MÉDIO') return 'var(--medium)';
    else if (props.status === 'ALTO') return 'var(--bad)';
    else return '#fff';
  }};
`;
