import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../Buttons';

import { Container, Identifier, LPopup } from './styles';

function Trap({ props }) {
  return (
    <Container
      radius={15}
      center={props.position}
      status={props.status}
    >
      <LPopup>
        <div>
          <strong>Pragas:</strong>
          <span>{props.status}</span>
        </div>
        <Link
          to={`/Mapas/Armadilhas/Resumo/${props.id}?data1=${props.data1}&data2=${props.data2}`}
          target="blank"
        >
          <Button>Resumo</Button>
        </Link>
      </LPopup>

      <Identifier permanent direction="center">
        {props.armadilha}
      </Identifier>
    </Container>
  );
}

export default Trap;
