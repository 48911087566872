import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Container from '../../../../Components/PreMapContainer';
import Button from '../../../../Components/Buttons';
import api from '../../../../Services/api';
import formatDate from '../../../../Utils/formatDate';
import { toastWarning } from '../../../../Utils/toast';

import {
  ChooseDate,
  Date,
  CalendarIcon,
  DateGroup,
  OsGroup,
  Os,
  Content,
  LoadButton,
  OsContent,
  CheckArea,
} from './styles';

function OS() {
  const [search, setSearch] = useState(true);
  const [selectedOs, setSelectedOs] = useState([]);
  const [os, setOs] = useState([]);
  const [data1, setData1] = useState('');
  const [data2, setData2] = useState('');

  const history = useHistory();
  const token = localStorage.getItem('token');

  function handleSelectedOs(osNumber) {
    const alreadySelected = selectedOs.findIndex(
      (os) => os === osNumber
    );

    if (alreadySelected >= 0) {
      const filteredOs = selectedOs.filter((os) => os !== osNumber);

      setSelectedOs(filteredOs);
    } else {
      setSelectedOs([...selectedOs, osNumber]);
    }
  }

  function handleSubmit() {
    if (selectedOs.length === 0) {
      toastWarning({ message: 'Selecione uma OS!' });
    } else {
      history.push(
        `/Mapas/Os?os=${selectedOs}&data1=${data1}&data2=${data2}`
      );
    }
  }

  function getOs() {
    if (data1 === '' || data2 === '') {
      toastWarning({ message: 'Selecione um intervalo de data!' });
    } else {
      setSearch(!search);

      api
        .get(`/getOs?data1=${data1}&data2=${data2}`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setOs(response.data);
        });
    }
  }

  return (
    <Container>
      <ToastContainer />

      <Content>
        <ChooseDate visible={String(search)}>
          <DateGroup visible={String(search)}>
            <Date>
              <h2>
                <CalendarIcon />
                Data Inicial
              </h2>
              <input
                type="date"
                onChange={(e) => setData1(e.target.value)}
              />
            </Date>
            <Date>
              <h2>
                <CalendarIcon />
                Data Final
              </h2>
              <input
                type="date"
                onChange={(e) => setData2(e.target.value)}
              />
            </Date>
          </DateGroup>

          <Button
            onClick={() => {
              getOs();
            }}
          >
            {search === true ? 'Buscar OS' : 'Escolher nova data'}
          </Button>

          <LoadButton
            visible={String(!search)}
            onClick={handleSubmit}
          >
            Carregar mapa
          </LoadButton>
        </ChooseDate>

        <OsGroup visible={String(!search)}>
          {os.map((os) => (
            <Os key={String(os.os)}>
              <OsContent>
                <div>
                  <strong>OS:</strong>
                  <span>{os.os}</span>
                </div>

                <div>
                  <strong>Data inicial da OS:</strong>
                  <span>{formatDate(os.dataInicial)}</span>
                </div>

                <div>
                  <strong>Data final da OS:</strong>
                  <span>{formatDate(os.dataFinal)}</span>
                </div>

                <div>
                  <strong>Operação:</strong>
                  <span>{os.operacao}</span>
                </div>

                <div>
                  <strong>Situação:</strong>
                  <span>{os.situacao}</span>
                </div>

                {os.observacao !== null && (
                  <div>
                    <strong>Observação:</strong>
                    <span>{os.observacao}</span>
                  </div>
                )}

                {os.alvos.length > 0 && (
                  <div>
                    <strong>Alvos:</strong>
                    {os.alvos.map((alvo) => (
                      <span
                        key={String(`${alvo.alvo}${alvo.controle}`)}
                      >
                        {alvo.alvo}
                      </span>
                    ))}
                  </div>
                )}
              </OsContent>

              <CheckArea>
                <input
                  type="checkbox"
                  value={os.os}
                  onClick={(e) => {
                    handleSelectedOs(e.target.value);
                  }}
                />
              </CheckArea>
            </Os>
          ))}
        </OsGroup>
      </Content>
    </Container>
  );
}

export default OS;
