import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;

  background: var(--dark-green);
  box-shadow: 0 0 8px rgb(0, 0, 0, 0.4);

  flex-shrink: 0;
`;

export const Logo = styled.img`
  width: 200px;
  filter: brightness(150%);
`;
