import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setDisplay } from '@/Store/Actions/infoDisplayAction';

import Container from '@/Components/InfoContainer';
import DecimalNumber from '@/Components/DecimalNumber';
import BreakLine from '@/Components/BreakLine';

import { RowDiv, Content } from './styles';

function InfoArea({ props }) {
  const display = useSelector((state) => state.infoDisplay.display);
  const dispatch = useDispatch();

  function handleDisplay() {
    dispatch(setDisplay(false));
  }

  const isCitrus = ['Citrus', 'Laranja'].includes(props.Cultura);

  return (
    <Container
      visible={display}
      top={120}
      right={10}
      onClose={handleDisplay}
      loading={props.length === 0 ? true : false}
    >
      <Content>
        <RowDiv>
          <strong>Sub-Área</strong>
          <span style={{ color: '#fff' }}>{props.subarea}</span>
        </RowDiv>
        <RowDiv>
          <strong>Tamanho</strong>
          <span style={{ color: '#fff' }}>
            {props.tamanhoSubarea}
          </span>
        </RowDiv>

        <BreakLine />

        <RowDiv>
          <strong>R$</strong>
          <DecimalNumber type="colored" minimum={2}>
            {props.custoSubarea}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>R$/ha</strong>
          <DecimalNumber type="colored" minimum={2}>
            {props.custoHaSubarea}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>R$ Bruto - {props.unidadeComercial}</strong>
          <DecimalNumber type="colored" minimum={2}>
            {isCitrus
              ? props.resultadoUnidadeComercialCitrus ||
                props.resultadoUnidadeComercial
              : props.resultadoUnidadeComercial}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>R$ Líquido - {props.unidadeComercial}</strong>
          <DecimalNumber type="colored" minimum={2}>
            {isCitrus
              ? props.resultadoUnidadeComercialLiquidoCitrus ||
                props.resultadoUnidadeComercialLiquido
              : props.resultadoUnidadeComercialLiquido}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>Descontos</strong>

          <RowDiv>
            <DecimalNumber type="colored" minimum={2}>
              {isCitrus
                ? props.descontosPercentCitrus ||
                  props.descontosPercent
                : props.descontosPercent}
            </DecimalNumber>
            <strong style={{ margin: 2 }}>%</strong>
          </RowDiv>
        </RowDiv>

        <BreakLine />

        <RowDiv>
          <strong style={{ color: '#00bcd4' }}>
            Cálculos com o peso líquido:
          </strong>
        </RowDiv>

        <RowDiv>
          <strong>
            {props.unidade === 'Quilograma' ? 'Kg' : props.unidade}
          </strong>
          <DecimalNumber type="colored" minimum={2}>
            {isCitrus
              ? props.totalKgLiquidoCitrus || props.totalKgLiquido
              : props.totalKgLiquido}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>
            {props.unidade === 'Quilograma' ? 'Kg' : props.unidade}/ha
          </strong>
          <DecimalNumber type="colored" minimum={2}>
            {isCitrus
              ? props.totalKghaLiquidoCitrus || props.totalKghaLiquido
              : props.totalKghaLiquido}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>{props.unidadeComercial}</strong>
          <DecimalNumber type="colored" minimum={2}>
            {isCitrus
              ? props.resultadoLiquidoCitrus || props.resultadoLiquido
              : props.resultadoLiquido}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>{props.unidadeComercial} / ha</strong>
          <DecimalNumber type="colored" minimum={2}>
            {isCitrus
              ? props.producaoHaLiquidoCitrus ||
                props.producaoHaLiquido
              : props.producaoHaLiquido}
          </DecimalNumber>
        </RowDiv>

        <BreakLine />

        <RowDiv>
          <strong style={{ color: '#00bcd4' }}>
            Cálculos com o peso bruto:
          </strong>
        </RowDiv>

        <RowDiv>
          <strong>
            {props.unidade === 'Quilograma' ? 'Kg' : props.unidade}
          </strong>
          <DecimalNumber type="colored" minimum={2}>
            {isCitrus
              ? props.totalKgCitrus || props.totalKg
              : props.totalKg}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>
            {props.unidade === 'Quilograma' ? 'Kg' : props.unidade}/ha
          </strong>
          <DecimalNumber type="colored" minimum={2}>
            {isCitrus
              ? props.totalKghaCitrus || props.totalKgha
              : props.totalKgha}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>{props.unidadeComercial}</strong>
          <DecimalNumber type="colored" minimum={2}>
            {isCitrus
              ? props.resultadoCitrus || props.resultado
              : props.resultado}
          </DecimalNumber>
        </RowDiv>
        <RowDiv>
          <strong>{props.unidadeComercial} / ha</strong>
          <DecimalNumber type="colored" minimum={2}>
            {isCitrus
              ? props.producaoHaCitrus || props.producaoHa
              : props.producaoHa}
          </DecimalNumber>
        </RowDiv>

        <BreakLine />

        <RowDiv>
          <strong style={{ color: '#00bcd4' }}>Descontos:</strong>
        </RowDiv>

        <RowDiv>
          <strong>
            {props.unidade === 'Quilograma' ? 'Kg' : props.unidade}
          </strong>

          <RowDiv>
            <DecimalNumber type="colored" minimum={2}>
              {isCitrus
                ? props.descontosCitrus || props.descontos
                : props.descontos}
            </DecimalNumber>
          </RowDiv>
        </RowDiv>

        <RowDiv>
          <strong>
            {props.unidade === 'Quilograma' ? 'Kg' : props.unidade}/ha
          </strong>

          <RowDiv>
            <DecimalNumber type="colored" minimum={2}>
              {isCitrus
                ? props.descontosTotalKgHaCitrus ||
                  props.descontosTotalKgHa
                : props.descontosTotalKgHa}
            </DecimalNumber>
          </RowDiv>
        </RowDiv>

        <RowDiv>
          <strong>{props.unidadeComercial}</strong>

          <RowDiv>
            <DecimalNumber type="colored" minimum={2}>
              {isCitrus
                ? props.descontosUnCitrus || props.descontosUn
                : props.descontosUn}
            </DecimalNumber>
          </RowDiv>
        </RowDiv>

        <RowDiv>
          <strong>{props.unidadeComercial} / ha</strong>

          <RowDiv>
            <DecimalNumber type="colored" minimum={2}>
              {isCitrus
                ? props.descontosUnHaCitrus || props.descontosUnHa
                : props.descontosUnHa}
            </DecimalNumber>
          </RowDiv>
        </RowDiv>
      </Content>
    </Container>
  );
}

export default InfoArea;
