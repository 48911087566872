import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import api from '../../../../Services/api';
import { setSelectedFarms } from '../../../../Store/Actions/selectedFarms';
import Container from '../../../../Components/PreMapContainer';
import FarmsContainer from '../../../../Components/FarmsContainer';
import StyledButton from '../../../../Components/Buttons';
import { toastWarning } from '../../../../Utils/toast';

import { SubmitArea, DateArea } from './styles';

const FarmMecanizado = () => {
  const [date, setDate] = useState('');

  const history = useHistory();
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  const farmsSelected = useSelector(
    (state) => state.selectedFarms.farms
  );

  useEffect(() => {
    dispatch(setSelectedFarms([]));
  }, [dispatch]);

  async function handleSubmit() {
    if (farmsSelected.length === 0) {
      toastWarning({ message: 'Selecione uma fazenda!' });
    } else {
      await api
        .get(
          `/Mapas/Mecanizado/VerifyData?data1=${date}&farms=${farmsSelected}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data === '') {
            toastWarning({ message: 'Não encontramos nenhum dado!' });
          } else {
            history.push(
              `/Mapas/Mecanizado?data1=${date}&farms=${farmsSelected}`
            );
          }
        });
    }
  }

  return (
    <Container>
      <ToastContainer />

      <FarmsContainer>
        <SubmitArea>
          <DateArea>
            <div>
              <span>Data</span>
              <input
                type="date"
                name="initial-date"
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
          </DateArea>

          <StyledButton onClick={handleSubmit}>Carregar</StyledButton>
        </SubmitArea>
      </FarmsContainer>
    </Container>
  );
};

export default FarmMecanizado;
