const INITIAL_STATE = {
  speed: 1000,
  situation: 'PAUSE',
};

export default function tractorReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case 'setSituation':
      return {
        ...state,
        situation: action.situation,
      };

    default:
      return state;
  }
}
