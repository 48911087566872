import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import api from '../../../../Services/api';

import Container from '../../../../Components/PreMapContainer';
import FarmsContainer from '../../../../Components/FarmsContainer';
import Button from '../../../../Components/Buttons';
import { toastWarning } from '../../../../Utils/toast';

import {
  SubmitArea,
  Date,
  ChooseDate,
  ChooseOthers,
  Option,
} from './styles';
import LoadPage from '@/Components/LoadPage';

function MIP2() {
  const [data1, setData1] = useState('');
  const [data2, setData2] = useState('');
  const [pragas, setPragas] = useState([]);
  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPraga, setSelectedPraga] = useState(null);

  const token = localStorage.getItem('token');
  const history = useHistory();
  const selectedFarms = useSelector(
    (state) => state.selectedFarms.farms
  );

  useEffect(() => {
    getPragas();
  }, []);

  async function getPragas() {
    try {
      const response = await api.get('/Pragas', {
        headers: { authorization: `Bearer ${token}` },
      });

      setPragas(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  function handleSubmit() {
    const query = `${
      result !== null ? `&result=${result}` : '&result='
    }${
      selectedPraga !== null ? `&praga=${selectedPraga}` : '&praga='
    }`;

    if (selectedFarms.length === 0) {
      toastWarning({ message: 'Selecione uma fazenda!' });
    } else if (data1 === '' || data2 === '') {
      toastWarning({ message: 'Selecione um intervalo de data!' });
    } else {
      setIsLoading(true);

      api
        .get(
          `/Mapas/MIP2/verifyData?farms=${selectedFarms}&data1=${data1}&data2=${data2}${query}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (!response.data) {
            toastWarning({ message: 'Não encontramos nenhum dado!' });
          } else {
            history.push(
              `/Farm/MIP/Results?farms=${selectedFarms}&data1=${data1}&data2=${data2}&result=${result}&praga=${selectedPraga}&metodo=2`
            );
          }
        })
        .finally(() => setIsLoading(false));
    }
  }

  if (isLoading) {
    return <LoadPage message="Aguarde alguns minutos..." />;
  }

  return (
    <Container>
      <ToastContainer />

      <FarmsContainer>
        <SubmitArea>
          <ChooseDate>
            <Date>
              <strong>Inicial</strong>
              <input
                type="date"
                onChange={(e) => setData1(e.target.value)}
              />
            </Date>
            <Date>
              <strong>Final</strong>
              <input
                type="date"
                onChange={(e) => setData2(e.target.value)}
              />
            </Date>
          </ChooseDate>

          <ChooseOthers>
            <Option>
              <strong>Praga</strong>
              <select
                onChange={(e) => setSelectedPraga(e.target.value)}
              >
                <option value="">Todos</option>

                {pragas.map((praga) => (
                  <option key={praga.nome} value={praga.nome}>
                    {praga.nome}
                  </option>
                ))}
              </select>
            </Option>

            <Option>
              <strong>Resultado</strong>
              <select onChange={(e) => setResult(e.target.value)}>
                <option value="">Todos</option>
                <option value="Baixo">Baixo</option>
                <option value="Medio">Médio</option>
                <option value="Alto">Alto</option>
              </select>
            </Option>
          </ChooseOthers>
        </SubmitArea>
        <Button onClick={handleSubmit}>Carregar dados</Button>
      </FarmsContainer>
    </Container>
  );
}

export default MIP2;
