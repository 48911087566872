import React, { useContext } from 'react';
import formatDate from '../../Utils/formatDate';
import { IoMdCloseCircle as Close } from '../../Styles/Icons';

import { Container, Header, Content, InfoContainer } from './styles';
import { pulverizadoContext } from '@/Contexts/pulverizadoContext';
import LoadSpinner from '../LoadSpinner';
import DecimalNumber from '../DecimalNumber';

const PulverizadoInfo = ({ props }) => {
  const {
    isLoading,
    data,
    turnNotActive,
    getLastInfo,
    getInfoData,
  } = useContext(pulverizadoContext);

  const dateChoosen = formatDate(props.date);
  const dataFinal = formatDate(data.datafinal);

  if (data === false || data.length === 0) {
    return (
      <Container>
        <Header>
          <Close onClick={turnNotActive} />

          <div>
            <span onClick={getInfoData}>Execução</span>
            <span onClick={getLastInfo}>Finalizada</span>
          </div>
        </Header>

        <Content
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <strong>OS sem apontamento</strong>
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <Close onClick={turnNotActive} />

        <div>
          <span onClick={getInfoData}>Execução</span>
          <span onClick={getLastInfo}>Finalizada</span>
        </div>
      </Header>

      {isLoading ? (
        <LoadSpinner props={{ size: 70 }} />
      ) : (
        <Content>
          <InfoContainer>
            <div>
              <strong>Data escolhida</strong>
              <span>{dateChoosen}</span>
            </div>
            <div>
              <strong>Data finalizada</strong>
              {dataFinal !== 'NULL' ? (
                <span>{dataFinal}</span>
              ) : (
                <span>00/00/0000</span>
              )}
            </div>
            <div>
              <strong>Alvo</strong>
              <span>{data.alvo}</span>
            </div>
            <div>
              <strong>Pulverização</strong>
              <span>{data.pulverizacao} dias</span>
            </div>
            <div>
              <strong>Tolerância</strong>
              <span>{data.tolerancia} dias</span>
            </div>
          </InfoContainer>

          <InfoContainer>
            <h3>Informações da OS</h3>

            <div>
              <strong>Subárea</strong>
              <span>{data.subarea}</span>
            </div>
            <div>
              <strong>Situação</strong>
              <span>{data.situacao}</span>
            </div>
            <div>
              <strong>Ordem de serviço</strong>
              <span>{data.os}</span>
            </div>
            <div>
              <strong>Dias</strong>
              <span>{(data.dias * 1).toFixed(0)}</span>
            </div>
          </InfoContainer>

          <InfoContainer>
            <h3>Área de apontamento</h3>

            <div>
              <strong>Tamanho</strong>
              <DecimalNumber unity="ha">
                {data.areaTotal}
              </DecimalNumber>
            </div>
            <div>
              <strong>Área apontada</strong>
              <DecimalNumber unity="ha">
                {data.areaApontada}
              </DecimalNumber>
            </div>
            <div>
              <strong>Percentual</strong>
              <DecimalNumber unity="%">
                {(data.areaApontada / data.areaTotal) * 100}
              </DecimalNumber>
            </div>
            <div>
              <strong>Variação</strong>
              <DecimalNumber
                type="colored"
                unity="%"
                variation={
                  (data.areaApontada / data.areaTotal) * 100 - 100
                }
              >
                {(data.areaApontada / data.areaTotal) * 100 - 100}
              </DecimalNumber>
            </div>
          </InfoContainer>

          <InfoContainer>
            <h3>Insumos</h3>

            {data.insumos !== undefined &&
              data.insumos.map((insumo) => (
                <div key={insumo.insumo}>
                  <strong>{insumo.insumo}</strong>
                  <DecimalNumber>{insumo.qtde}</DecimalNumber>
                </div>
              ))}
          </InfoContainer>

          <InfoContainer>
            <h3>Calda por subárea</h3>

            <div>
              <strong>Sugerida</strong>
              <DecimalNumber unity="L/ha">
                {data.caldahaSugerida}
              </DecimalNumber>
            </div>
            <div>
              <strong>Apontada</strong>
              <DecimalNumber unity="L/ha">
                {data.caldahaApontada}
              </DecimalNumber>
            </div>
            <div>
              <strong>Realizada</strong>
              <DecimalNumber unity="L/ha">
                {data.caldahaRealizada}
              </DecimalNumber>
            </div>
            <div>
              <strong>Porcentagem</strong>
              <DecimalNumber unity="%">
                {data.porcentagemCaldaha}
              </DecimalNumber>
            </div>
            <div>
              <strong>Variação</strong>
              <DecimalNumber
                unity="%"
                variation={data.variacaoCaldaha}
                type="colored"
              >
                {data.variacaoCaldaha}
              </DecimalNumber>
            </div>
          </InfoContainer>

          <InfoContainer>
            <h3>Calda geral</h3>

            <div>
              <strong>Sugerida</strong>
              <DecimalNumber unity="L">
                {data.caldaSugerida}
              </DecimalNumber>
            </div>
            <div>
              <strong>Apontada</strong>
              <DecimalNumber unity="L">
                {data.caldaApontada}
              </DecimalNumber>
            </div>

            <div>
              <strong>Porcentagem</strong>
              <DecimalNumber unity="%">
                {data.porcentagemCalda}
              </DecimalNumber>
            </div>
            <div>
              <strong>Variação</strong>
              <DecimalNumber
                unity="%"
                variation={data.variacaoCalda}
                type="colored"
              >
                {data.variacaoCalda}
              </DecimalNumber>
            </div>
          </InfoContainer>
        </Content>
      )}
    </Container>
  );
};

export default PulverizadoInfo;
