import styled, { css, keyframes } from 'styled-components';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import {
  GiHamburgerMenu,
  FaHome,
  FaSignInAlt,
  FaMobile,
  FaMapMarkedAlt,
  Manage,
} from '../../Styles/Icons';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: var(--dark-green);
  box-shadow: 0 0 8px rgb(0, 0, 0, 0.4);

  position: relative;
  z-index: 10;
  flex-shrink: 0;
`;

export const LogoUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 5px;

  > img {
    margin-right: 62px;
  }

  > span {
    color: white;
    font-size: 18px;
    padding: 8px;
    border: 1px dotted var(--light-green-hover);
    text-transform: uppercase;
  }

  @media (max-width: 572px) {
    > img {
      margin-right: 15px;
    }
  }

  @media (max-width: 383px) {
    > span {
      display: none;
    }
  }
`;

export const Logo = styled.img`
  width: 175px;
  filter: brightness(150%);
`;

export const MenuBurger = styled(Button)`
  && {
    display: flex;
    flex-direction: center;
    align-items: center;

    background: var(--dark-green);
    padding: 8px;

    width: 30px;
    height: 30px;
  }
`;

const appear = keyframes`
  0%{
    bottom: 0px;
  }
  50%{
    bottom: -121px
  }
  100%{
    bottom: -243px;
  }
`;

export const MenuOptions = styled.div`
  display: ${(props) => (props.toggle ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 300px;
  padding: 20px 10px;

  background: var(--dark-green);
  border-top: 1px solid #00b43640;

  position: absolute;
  bottom: -243px;
  right: 0;
  z-index: 2;

  animation: ${appear} 0.1s linear;

  @media (max-width: 425px) {
    width: 100%;
  }
`;

export const BurgerIcon = styled(GiHamburgerMenu)`
  width: 28px;
  height: 28px;

  color: white;
  transition: 0.2s;

  &:hover {
    transform: scale(1.2);
  }
`;

export const Title = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 24px;
  font-weight: 600;
  color: #fff;
  border-radius: 5px;

  padding: 4px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: var(--light-green-hover);
    transform: scale(1.2);
  }

  & + a {
    margin-top: 18px;
  }
`;

const iconCss = css`
  margin-right: 8px;
`;

export const HomeIcon = styled(FaHome)`
  ${iconCss}
`;
export const ExitIcon = styled(FaSignInAlt)`
  ${iconCss}
`;
export const AppIcon = styled(FaMobile)`
  ${iconCss}
`;
export const MapIcon = styled(FaMapMarkedAlt)`
  ${iconCss}
`;
export const ManageIcon = styled(Manage)`
  ${iconCss}
`;
