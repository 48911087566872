import styled from 'styled-components';

import InfoContainer from '@/Components/InfoContainer';

export const Container = styled(InfoContainer)``;

export const RowDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: #fff;
  font-size: 14px;

  & + div {
    margin-top: 8px;
  }

  > strong {
    margin-right: 20px;
  }
`;

export const Operator = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 20px;
  color: #fff;

  > strong {
    font-size: 20px;
    max-width: 300px;
    text-align: center;
  }

  > span {
    text-align: center;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  color: #fff;

  margin-bottom: 20px;
  padding: 10px 0;
  border-radius: 5px;
  background: #313131;
`;

export const HeaderApp = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  background: #313131;

  > h2 {
    color: #fff;
  }
`;

export const BoxBordered = styled.div`
  display: flex;
  flex-direction: column;

  box-shadow: 0 0 5px inset rgb(0, 0, 0);
  padding: 5px;
  margin: 15px 0;
  border-right: 2px solid aqua;

  color: #fff;

  div + div {
    margin-top: 10px;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 7px;
    }
  }
`;
