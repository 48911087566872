import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  TableCell,
  TableContainer,
  Paper,
  TableHead,
  TableBody,
  TableRow,
} from '@material-ui/core';

import api from '@/Services/api';
import formatDate from '@/Utils/formatDate';

import LoadPage from '@/Components/LoadPage';

import {
  Container,
  Title,
  Header,
  LeftIcon,
  Back,
  Content,
} from './styles';

function AnimaisDetalhes(props) {
  const [data, setData] = useState([]);

  const search = decodeURIComponent(props.location.search).split('&');
  const token = localStorage.getItem('token');
  const history = useHistory();

  const animal = search[0].slice(8);

  useEffect(() => {
    api
      .get(`/Mapas/Animais/getAnimalDetails?animal=${animal}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((response) => setData(response.data));
  }, [token, animal]);

  if (data.length === 0) {
    return <LoadPage />;
  }

  return (
    <Container>
      <Header>
        <Back onClick={() => history.goBack()}>
          <LeftIcon />
          <span>Voltar</span>
        </Back>
        <h2>{animal}</h2>
      </Header>

      <Content>
        <Title>Genealogia</Title>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Mãe</TableCell>
                <TableCell align="center">Sigla Mãe</TableCell>
                <TableCell align="center">Manejo Mãe</TableCell>
                <TableCell align="center">Pai</TableCell>
                <TableCell align="center">Sigla Pai</TableCell>
                <TableCell align="center">Manejo Pai</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  {animal}
                </TableCell>
                <TableCell align="center">
                  {data.genealogia.siglamae}
                </TableCell>
                <TableCell align="center">
                  {data.genealogia.manejomae}
                </TableCell>
                <TableCell align="center">
                  {data.genealogia.pai}
                </TableCell>
                <TableCell align="center">
                  {data.genealogia.siglapai}
                </TableCell>
                <TableCell align="center">
                  {data.genealogia.manejopai}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Title style={{ marginTop: '100px' }}>Reprodutivo</Title>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Doadora</TableCell>
                <TableCell align="center">Acasalamento</TableCell>
                <TableCell align="center">Semen</TableCell>
                <TableCell align="center">Touro</TableCell>
                <TableCell align="center">Prenha</TableCell>
                <TableCell align="center">Vazia</TableCell>
                <TableCell align="center">Parto</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  {data.reprodutivo.doadora}
                </TableCell>
                <TableCell align="center">
                  {data.reprodutivo.acasalamento}
                </TableCell>
                <TableCell align="center">
                  {data.reprodutivo.semen}
                </TableCell>
                <TableCell align="center">
                  {data.reprodutivo.touro}
                </TableCell>
                <TableCell align="center">
                  {data.reprodutivo.prenha}
                </TableCell>
                <TableCell align="center">
                  {data.reprodutivo.vazia}
                </TableCell>
                <TableCell align="center">
                  {formatDate(data.reprodutivo.partofim)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Content>
    </Container>
  );
}

export default AnimaisDetalhes;
