import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import api from '@/Services/api';
import {
  setDisplay,
  setDisplay2,
} from '@/Store/Actions/infoDisplayAction';

import Map from '@/Components/Map';
import LoadPage from '@/Components/LoadPage';
import GeralInfo from './Components/GeralInfo';
import InfoArea from './Components/InfoArea';
import Search from './Components/Search';
import IntervalValues from './Components/IntervalValues';

import {
  Area,
  Identifier,
  Eye,
  EyeIcon,
  Marcador,
  Container,
} from './styles';

function Animais(props) {
  const [data, setData] = useState([]);
  const [center, setCenter] = useState([0, 0]);
  const [geralInfo, setGeralInfo] = useState({});
  const [subareaInfo, setSubareaInfo] = useState([]);
  const [zoom, setZoom] = useState(13);

  const token = localStorage.getItem('token');
  const valorBoi = localStorage.getItem('valorBoi');

  const dispatch = useDispatch();
  const display = useSelector((state) => state.infoDisplay.display2);
  const selectedAnimal = useSelector(
    (state) => state.selectedAnimal.subarea
  );
  const search = decodeURIComponent(props.location.search).split('&');

  const especie = search[0].slice(9);
  const raca = search[1].slice(5);
  const farms = search[2].slice(6);

  useEffect(() => {
    api
      .get(
        `/Mapas/Animais/getGeralInfo?especie=${especie}&raca=${raca}&farms=${farms}&valorBoi=${valorBoi}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => setGeralInfo(response.data));
  }, [token, especie, raca, farms, valorBoi]);

  useEffect(() => {
    dispatch(setDisplay(false));
    dispatch(setDisplay2(false));

    api
      .get(
        `/Mapas/Animais/getAreas?especie=${especie}&raca=${raca}&farms=${farms}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setData(response.data);
        setCenter(response.data[0].geo[0]);
      });
  }, [token, dispatch, especie, raca, farms]);

  useEffect(() => {
    data.map((value) => {
      if (value.subarea === selectedAnimal) {
        if (center !== value.geo[0]) {
          setCenter(value.geo[0]);
          setZoom(16);
        }
      }
      return true;
    });
    //eslint-disable-next-line
  }, [selectedAnimal, data]);

  function getSubareaInfo(subarea) {
    setSubareaInfo([]);

    api
      .get(
        `/Mapas/Animais/getSubareaInfo?subarea=${subarea}&especie=${especie}&raca=${raca}&valorBoi=${valorBoi}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => setSubareaInfo(response.data));
  }

  if (data.length === 0) {
    return <LoadPage />;
  }

  return (
    <Container>
      <Map props={{ center, zoom }}>
        <Eye
          visible={!display}
          onClick={() => dispatch(setDisplay2(true))}
        >
          <EyeIcon />
        </Eye>

        {data.map((value) => (
          <Area
            key={value.subarea}
            positions={value.geo}
            media={value.media}
            onClick={() => {
              getSubareaInfo(value.subarea);
              dispatch(setDisplay(true));
            }}
          >
            <Identifier>{value.subarea}</Identifier>
            {value.subarea === selectedAnimal && (
              <Marcador position={value.geo[0]} />
            )}
          </Area>
        ))}
      </Map>

      <IntervalValues />
      <GeralInfo data={{ geralInfo, raca, especie }} />
      <InfoArea props={subareaInfo} />
      <Search />
    </Container>
  );
}

export default Animais;
