import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import formatDate from '@/Utils/formatDate';
import api from '@/Services/api';

import IntNumber from '@/Components/IntNumber';
import DecimalNumber from '@/Components/DecimalNumber';
import LoadPage from '@/Components/LoadPage';

import {
  Container,
  Header,
  Content,
  Amostra,
  Resumo,
  Description,
  Title,
} from './styles';

function AnimaisResumo(props) {
  const [headerData, setHeaderData] = useState([]);
  const [data, setData] = useState([]);

  const search = decodeURIComponent(props.location.search).split('&');
  const token = localStorage.getItem('token');
  const valorBoi = localStorage.getItem('valorBoi');
  const history = useHistory();

  const especie = search[0].slice(9);
  const raca = search[1].slice(5);
  const subarea = search[2].slice(8);

  useEffect(() => {
    api
      .get(
        `/Mapas/Animais/getSubareaInfo?especie=${especie}&raca=${raca}&subarea=${subarea}&valorBoi=${valorBoi}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => setHeaderData(response.data));
  }, [token, especie, raca, subarea, valorBoi]);

  useEffect(() => {
    api
      .get(`/Mapas/Animais/getAnimais?subarea=${subarea}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((response) => setData(response.data));
  }, [token, subarea]);

  if (data.length === 0) {
    return <LoadPage />;
  }

  return (
    <Container>
      <Title>
        {headerData.fazenda} | {headerData.subarea}
      </Title>
      <Description>
        <Header>
          <strong>Animais</strong>
          <strong>Machos</strong>
          <strong>Fêmeas</strong>
          <strong>Total Peso</strong>
          <strong>Total UA</strong>
          <strong>Total @</strong>
          <strong>Média Peso</strong>
          <strong>Média UA</strong>
          <strong>Média @</strong>
          <strong>Cotação @</strong>
          <strong>Valor Médio</strong>
          <strong>Valor Total</strong>
        </Header>

        <Content>
          <Amostra
            key={`${headerData.namostra}${headerData.praga}${headerData.subarea}`}
          >
            <IntNumber>{headerData.qtdTotal}</IntNumber>
            <IntNumber>{headerData.qtdMacho}</IntNumber>
            <IntNumber>{headerData.qtdFemea}</IntNumber>
            <DecimalNumber>{headerData.totalPeso}</DecimalNumber>
            <DecimalNumber>{headerData.totalUA}</DecimalNumber>
            <DecimalNumber>{headerData.totalArroba}</DecimalNumber>
            <DecimalNumber>{headerData.mediaPeso}</DecimalNumber>
            <DecimalNumber>{headerData.mediaUA}</DecimalNumber>
            <DecimalNumber>{headerData.mediaArroba}</DecimalNumber>
            <span>R$ {valorBoi}</span>
            <DecimalNumber>{headerData.mediaValor}</DecimalNumber>
            <DecimalNumber>{headerData.valorTotal}</DecimalNumber>
          </Amostra>
        </Content>
      </Description>

      <Resumo>
        <Header>
          <strong>Categoria</strong>
          <strong>Subcategoria</strong>
          <strong>Animal</strong>
          <strong>Manejo</strong>
          <strong>Peso</strong>
          <strong>Sigla</strong>
          <strong>Sexo</strong>
          <strong>Nascimento</strong>
        </Header>
        <Content>
          {data.map((value) => (
            <Amostra
              key={value.nome}
              title="ver mais detalhes"
              onClick={() =>
                history.push(
                  `/Mapas/Animais/Resumo/Detalhes?animal=${value.nome}`
                )
              }
            >
              <span>{value.categoria}</span>
              <span>{value.subcategoria}</span>
              <span>{value.nome}</span>
              <span>{value.manejo}</span>
              <span>{value.peso} Kg</span>
              <span>{value.sigla}</span>
              <span>{value.sexo}</span>
              <span>{formatDate(value.nascimento)}</span>
            </Amostra>
          ))}
        </Content>
      </Resumo>
    </Container>
  );
}

export default AnimaisResumo;
