import React from 'react';

import { Container } from './styles';

function DecimalNumber({
  children,
  variation,
  color,
  type,
  unity,
  minimum = 1,
}) {
  return (
    <Container variation={variation} color={color} type={type}>
      {(children * 1).toLocaleString('de-DE', {
        maximumFractionDigits: 2,
        minimumFractionDigits: minimum,
      })}
      {unity && ` ${unity}`}
    </Container>
  );
}

export default DecimalNumber;
