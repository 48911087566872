import React from 'react';
import DecimalNumber from '@/Components/DecimalNumber';

import { UICell } from './styles';

function Cell({ children }) {
  return (
    <UICell align="center" style={{ whiteSpace: 'nowrap' }}>
      {typeof children === 'number' ? (
        <DecimalNumber>{children}</DecimalNumber>
      ) : (
        children
      )}
    </UICell>
  );
}

export default Cell;
