import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import api from '@/Services/api';
import formatDate from '@/Utils/formatDate';
import LogoAgrosUrl from '@/Assets/greenAgrosLogo.png';

import LoadPage from '@/Components/LoadPage';

import {
  RowDiv,
  Container,
  Header,
  Content,
  Table,
  TitleValue,
  Title,
  Value,
  LogoAgros,
  LogoFarm,
  Praga,
  Button,
} from './styles';

function ArmadilhaResumo(props) {
  const [data, setData] = useState([]);

  const search = props.location.search.split('&');

  const data1 = search[0].slice(7);
  const data2 = search[1].slice(6);

  const logo = localStorage.getItem('logo');

  const token = localStorage.getItem('token');
  const history = useHistory();
  const id = props.match.params.id;

  useEffect(() => {
    api
      .get(
        `/Armadilhas/getResume/${id}?data1=${data1}&data2=${data2}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => setData(response.data));
  }, [token, id, data1, data2]);

  if (data.length === 0) {
    return <LoadPage />;
  }

  return (
    <Container>
      <Header>
        <LogoAgros src={LogoAgrosUrl} />
        <LogoFarm src={logo} />
      </Header>

      <RowDiv>
        <Button className="no-print" onClick={() => history.goBack()}>
          Voltar
        </Button>

        <Button className="no-print" onClick={() => window.print()}>
          Imprimir
        </Button>
      </RowDiv>

      <Content>
        <Table>
          <TitleValue>
            <Title className="print">Armadilha</Title>

            {data.length > 0 &&
              data.map((value) => (
                <Value className="print" key={value.hora}>
                  {value.armadilha}
                </Value>
              ))}
          </TitleValue>

          <TitleValue>
            <Title className="print">Funcionário</Title>

            {data.length > 0 &&
              data.map((value) => (
                <Value className="print" key={value.hora}>
                  {value.funcionario}
                </Value>
              ))}
          </TitleValue>

          <TitleValue>
            <Title className="print">Data</Title>

            {data.length > 0 &&
              data.map((value) => (
                <Value className="print" key={value.hora}>
                  {formatDate(value.data)}
                </Value>
              ))}
          </TitleValue>

          <TitleValue>
            <Title className="print">Fazenda</Title>

            {data.length > 0 &&
              data.map((value) => (
                <Praga key={value.hora}>{value.fazenda}</Praga>
              ))}
          </TitleValue>

          <TitleValue>
            <Title className="print">Subarea</Title>

            {data.length > 0 &&
              data.map((value) => (
                <Value className="print" key={value.hora}>
                  {value.subarea}
                </Value>
              ))}
          </TitleValue>

          <TitleValue>
            <Title className="print">Praga</Title>

            {data.length > 0 &&
              data.map((value) => (
                <Value className="print" key={value.hora}>
                  {value.praga}
                </Value>
              ))}
          </TitleValue>

          <TitleValue>
            <Title className="print">Quantidade</Title>

            {data.length > 0 &&
              data.map((value) => (
                <Value className="print" key={value.hora}>
                  {value.qtd}
                </Value>
              ))}
          </TitleValue>
        </Table>
      </Content>
    </Container>
  );
}

export default ArmadilhaResumo;
