import React, { useEffect, useState } from 'react';
import { IoMdCloseCircle } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import { Input } from '@material-ui/core';
import { Link } from 'react-router-dom';

import api from '@/Services/api';

import StyledButtons from '@/Components/Buttons';
import HeaderEditable from '@/Components/HeaderEditable';

import {
  Container,
  Farm,
  FormLogin,
  FarmsContainer,
  MobileIcon,
  Apps,
} from './styles';

const Login = () => {
  const [farms, setFarms] = useState([]);
  const [formDisplay, setFormDisplay] = useState('none');
  const [activeFarm, setActiveFarm] = useState('');
  const [error, setError] = useState(false);

  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');

  const history = useHistory();

  useEffect(() => {
    getFarms();
  }, []);

  async function getFarms() {
    const { data } = await api.get('/Login');

    if (data && data instanceof Array) setFarms(data);
  }

  function setDisplayBlock(farm) {
    setFormDisplay('block');
    setActiveFarm(farm);
  }

  function setDisplayNone() {
    setFormDisplay('none');
  }

  async function handleSubmit(event) {
    try {
      event.preventDefault();

      localStorage.setItem('user', user);

      const data = {
        userLogin: user,
        password,
        farmName: activeFarm,
      };

      const response = await api.post('Login', data);

      if (!response.data) {
        setError(true);
      } else {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('farmName', activeFarm);
        localStorage.setItem('logo', response.data.logo);
        history.push('/Farm');
      }
    } catch (error) {
      setError(true);
    }
  }

  return (
    <Container>
      <HeaderEditable>
        <Link to="/Aplicativos">
          <Apps>
            <MobileIcon />
            <span>Aplicativos</span>
          </Apps>
        </Link>
      </HeaderEditable>
      <FarmsContainer>
        {(farms || []).map((farm) => (
          <Farm key={String(farm.fazenda)} className="farms">
            <h3>{farm.fazenda}</h3>
            <img src={farm?.logo} alt="logo" />
            <StyledButtons
              onClick={() => setDisplayBlock(farm.fazenda)}
            >
              Entrar
            </StyledButtons>
          </Farm>
        ))}
      </FarmsContainer>
      <FormLogin
        display={formDisplay}
        onSubmit={handleSubmit}
        error={error}
      >
        <IoMdCloseCircle onClick={setDisplayNone} />
        <h3>{activeFarm.toUpperCase()}</h3>

        <Input
          required
          placeholder="Usuário"
          autoComplete="off"
          onChange={(event) => {
            setUser(event.target.value);
            setError(false);
          }}
        />
        <Input
          required
          placeholder="Senha"
          autoComplete="off"
          type="password"
          onChange={(event) => {
            setPassword(event.target.value);
            setError(false);
          }}
        />

        <StyledButtons type="submit">Entrar</StyledButtons>
      </FormLogin>
    </Container>
  );
};

export default Login;
