import React, { useEffect, useState } from 'react';

import formatDate from '@/Utils/formatDate';
import api from '@/Services/api';

import {
  Container,
  Header,
  Content,
  Amostra,
  Resumo,
} from './styles';

function MipResumo(props) {
  const [data, setData] = useState([]);

  const search = decodeURIComponent(props.location.search).split('&');
  const token = localStorage.getItem('token');

  const id = search[0].slice(4);
  const subarea = search[1].slice(8);
  const date = search[2].slice(5, 15);
  const praga = search[3].slice(6);
  const metodo = search[4].slice(7);

  useEffect(() => {
    api
      .get(
        `/Mapas/MIP${metodo}/Resumo?id=${id}&subarea=${subarea}&data=${date}&praga=${praga}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => setData(response.data));
  }, [token, subarea, date, id, praga]);

  return (
    <Container>
      <Resumo>
        <Header>
          <strong>Amostra</strong>
          <strong>Praga</strong>
          <strong>Quantidade</strong>
          <strong>Data</strong>
          <strong>Hora</strong>
          <strong>Funcionário</strong>
          <strong>Fazenda</strong>
          <strong>Subarea</strong>
        </Header>
        <Content>
          {data.map((value) => (
            <Amostra
              key={`${value.namostra}${value.praga}${value.subarea}`}
            >
              <span>{value.namostra}°</span>
              <span>{value.praga}</span>
              <span>{value.qtd}</span>
              <span>{formatDate(value.data)}</span>
              <span>{value.hora}</span>
              <span>{value.funcionario}</span>
              <span>{value.fazenda}</span>
              <span>{value.subarea}</span>
            </Amostra>
          ))}
        </Content>
      </Resumo>
    </Container>
  );
}

export default MipResumo;
