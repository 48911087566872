import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import formatDate from '@/Utils/formatDate';
import { setDisplay } from '@/Store/Actions/infoDisplayAction';

import DecimalNumber from '@/Components/DecimalNumber';

import {
  Container,
  Header,
  RowDiv,
  ColumnDiv,
  Tractor,
  Bug,
  Operator,
  BoxBordered,
} from './styles';

function InfoContainer({ props }) {
  const display = useSelector((state) => state.infoDisplay.display);
  const dispatch = useDispatch();

  function handleDisplay() {
    dispatch(setDisplay(false));
  }

  if (props.length === 0) {
    return (
      <Container
        visible={display}
        top={90}
        right={15}
        onClose={handleDisplay}
        loading={true}
      />
    );
  }

  const ha_hour = ((props.areaApontada * 1) / props.totalHoras)
    .toFixed(2)
    .replace('.', ',');

  return (
    <Container
      visible={display}
      top={90}
      right={15}
      onClose={handleDisplay}
    >
      <Header>
        <RowDiv>
          <strong style={{ marginRight: '10px' }}>Data:</strong>
          <span>{formatDate(props.data)}</span>
        </RowDiv>

        <RowDiv>
          <strong style={{ marginRight: '10px' }}>OS:</strong>
          <span>{props.os || '-'}</span>
        </RowDiv>

        <RowDiv>
          <strong style={{ marginRight: '10px' }}>
            Apontamento:
          </strong>
          <span>{props.apontamento}</span>
        </RowDiv>
      </Header>

      <RowDiv>
        <span>{props.operacao}</span>
      </RowDiv>

      <ColumnDiv>
        {props.areas.map((area) => (
          <RowDiv key={area.subarea}>
            <strong style={{ fontSize: '10px' }}>
              {area.fazenda}
            </strong>
            <span>
              {area.subarea} - {area.totalArea} ha
            </span>
          </RowDiv>
        ))}
      </ColumnDiv>

      <BoxBordered>
        {props.alvos.length > 0 &&
          props.alvos.map((alvo) => (
            <div key={String(alvo.alvo)}>
              <Bug />
              <strong>{alvo.alvo}</strong>
            </div>
          ))}
      </BoxBordered>

      <Tractor tractorBackground={props.color} direction="row">
        <h2>Recursos</h2>
        <div id="tractor" />
      </Tractor>

      <Operator>
        <strong>{props.ajudante}</strong>
        <span>
          {props.inicio}hrs - {props.final}hrs {`--> `}
          {props.totalTrabalhado}hrs
        </span>
      </Operator>

      <RowDiv>
        <strong>CAPACIDADE DE CAMPO EFETIVA:</strong>
        <div>
          <span style={{ color: '#0dd7ec' }}>{ha_hour}</span>
          <span> ha/h</span>
        </div>
      </RowDiv>

      {props.implementos.length > 0 &&
        props.implementos.map((implemento) => (
          <RowDiv key={String(implemento.implemento)}>
            <strong>{implemento.implemento}:</strong>
            <div>
              <DecimalNumber type="colored">
                {implemento.total}
              </DecimalNumber>
              <span> h</span>
            </div>
          </RowDiv>
        ))}

      {props.insumos.length > 0 && (
        <BoxBordered>
          <RowDiv style={{ justifyContent: 'space-between' }}>
            <p>Nome</p>
            <p>Qtde/ha</p>
            <p>Total</p>
          </RowDiv>

          {props.insumos.map(
            (insumo) =>
              insumo !== undefined && (
                <RowDiv
                  key={String(insumo.insumo)}
                  style={{ justifyContent: 'space-between' }}
                >
                  <strong style={{ width: '7px' }}>
                    {insumo.insumo}:
                  </strong>
                  <strong style={{ width: '7px' }}>
                    {((insumo.qtde * 1) / props.areaApontada)
                      .toFixed(2)
                      .replace('.', ',')}
                  </strong>
                  <div>
                    <DecimalNumber type="colored">
                      {insumo.qtde * 1}
                    </DecimalNumber>
                  </div>
                </RowDiv>
              )
          )}
        </BoxBordered>
      )}

      {props.caldaTotal !== null && (
        <>
          <RowDiv>
            <strong>Calda Apontada:</strong>
            <div>
              <div>
                <DecimalNumber type="colored">
                  {props.caldaTotal}
                </DecimalNumber>{' '}
                <span>L</span>
              </div>
            </div>
          </RowDiv>

          <RowDiv>
            <strong>Área Coberta:</strong>

            <div>
              <DecimalNumber type="colored">
                {props.areaCoberta}
              </DecimalNumber>
              <span> ha</span>
            </div>
          </RowDiv>

          <RowDiv>
            <strong>Área Apontada:</strong>
            <div>
              <DecimalNumber type="colored">
                {props.areaApontada * 1}
              </DecimalNumber>
              <span> ha</span>
            </div>
          </RowDiv>
          <RowDiv>
            <strong>Calda/ha Sugerida:</strong>
            <div>
              <DecimalNumber type="colored">
                {props.caldaha * 1}
              </DecimalNumber>

              <span> L/ha</span>
            </div>
          </RowDiv>
          <RowDiv>
            <strong>Calda/ha Apontada:</strong>
            <div>
              <DecimalNumber type="colored">
                {props.caldahaApontada * 1}
              </DecimalNumber>
              <span> L/ha</span>
            </div>
          </RowDiv>
          <RowDiv>
            <strong>Porcentagem</strong>
            <div>
              <DecimalNumber type="colored">
                {props.porcentagemCaldaha * 1}
              </DecimalNumber>
              <span>%</span>
            </div>
          </RowDiv>
          <RowDiv>
            <strong>Variação</strong>
            <div>
              <DecimalNumber
                type="colored"
                variation={props.porcentagemCaldaha - 100}
              >
                {props.porcentagemCaldaha - 100}
              </DecimalNumber>
              <span>%</span>
            </div>
          </RowDiv>
        </>
      )}

      <RowDiv>
        <strong>Obs:</strong>
        <span>{props.observacao}</span>
      </RowDiv>
    </Container>
  );
}

export default InfoContainer;
