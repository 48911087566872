import React from 'react';

import { Container } from './styles';

function Row({ children, clickable, onClick }) {
  return (
    <Container onClick={onClick} clickable={clickable}>
      {children}
    </Container>
  );
}

export default Row;
