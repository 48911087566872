import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from './Pages/Home';
import Login from './Pages/Login';
import Farm from './Pages/Farm';
import FarmMecanizado from './Pages/Farm/Maps/Mecanizado';
import FarmPulverizado from './Pages/Farm/Maps/Pulverizado';
import FarmMyAreas from './Pages/Farm/Maps/MyAreas';
import FarmOS from './Pages/Farm/Maps/OS';
import FarmArmadilhas from './Pages/Farm/Maps/Armadilhas';
import FarmMIP1 from './Pages/Farm/Maps/MIP1';
import FarmMIP2 from './Pages/Farm/Maps/MIP2';
import FarmMIPResults from './Pages/Farm/Maps/MIPResults';
import FarmAnimais from './Pages/Farm/Maps/Animais';
import FarmCochos from './Pages/Farm/Maps/Cochos';
import FarmAgros from './Pages/Farm/Maps/Agros';

import Mecanizado from './Pages/Maps/Mecanizado';
import Realtime from './Pages/Maps/Realtime';
import MyAreas from './Pages/Maps/MyAreas';
import Aplicativos from './Pages/Aplicativos';
import PoliticaPrivacidade from './Pages/PoliticaPrivacidade';
import Pulverizado from './Pages/Maps/Pulverizado';
import OS from './Pages/Maps/OS';
import Armadilhas from './Pages/Maps/Armadilhas';
import MIP from './Pages/Maps/MIP';
import Animais from './Pages/Maps/Animais';
import Cochos from './Pages/Maps/Cochos';
import Agros from './Pages/Maps/Agros';

import ArmadilhasResumo from './Pages/ArmadilhasResumo';
import ArmadilhaResumo from './Pages/ArmadilhaResumo';
import MipResumo from './Pages/MipResumo';
import AnimaisResumo from './Pages/AnimaisResumo';
import ChooseMap from './Pages/ChooseMap';
import AnimaisDetalhes from './Pages/AnimaisDetalhes';
import Manage from './Pages/Manage';
import ManageProduto from './Pages/ManageProduto';
import PulverizadoProvider from './Contexts/pulverizadoContext';
import Compras from './Pages/Compras';
import FarmRealtime from './Pages/Farm/Maps/Realtime';

const Routes = () => {
  return (
    <BrowserRouter>
      <PulverizadoProvider>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/Login" component={Login} />
          <Route path="/Aplicativos" component={Aplicativos} />
          <Route path="/compras" component={Compras} />
          <Route
            path="/PoliticaPrivacidade"
            component={PoliticaPrivacidade}
          />
          <Route path="/Gestao" exact component={Manage} />
          <Route path="/Gestao/Produto" component={ManageProduto} />
          <Route path="/Mapeamentos" component={ChooseMap} />
          <Route path="/Farm" exact component={Farm} />
          <Route path="/Farm/Mecanizado" component={FarmMecanizado} />
          <Route path="/Farm/Realtime" component={FarmRealtime} />
          <Route
            path="/Farm/Pulverização"
            component={FarmPulverizado}
          />
          <Route path="/Farm/Minhas-Areas" component={FarmMyAreas} />
          <Route path="/Farm/Os" component={FarmOS} />
          <Route path="/Farm/Armadilhas" component={FarmArmadilhas} />
          <Route path="/Farm/MIP1" exact component={FarmMIP1} />
          <Route path="/Farm/MIP2" exact component={FarmMIP2} />
          <Route
            path="/Farm/MIP/Results"
            component={FarmMIPResults}
          />
          <Route path="/Farm/Animais" component={FarmAnimais} />
          <Route path="/Farm/Cochos" component={FarmCochos} />
          <Route path="/Farm/Agros" component={FarmAgros} />

          <Route path="/Mapas/Mecanizado" component={Mecanizado} />
          <Route path="/Mapas/Realtime" component={Realtime} />
          <Route path="/Mapas/Minhas-Areas" component={MyAreas} />
          <Route path="/Mapas/OS" component={OS} />
          <Route path="/Mapas/MIP" exact component={MIP} />
          <Route
            path="/Mapas/MIP/Resumo"
            exact
            component={MipResumo}
          />
          <Route
            path="/Mapas/Armadilhas"
            exact
            component={Armadilhas}
          />
          <Route
            path="/Mapas/Armadilhas/Resumo"
            exact
            component={ArmadilhasResumo}
          />
          <Route
            path="/Mapas/Armadilhas/Resumo/:id"
            component={ArmadilhaResumo}
          />
          <Route
            path="/Mapas/Diário-de-Pulverização"
            component={Pulverizado}
          />
          <Route path="/Mapas/Animais" exact component={Animais} />
          <Route
            path="/Mapas/Animais/Resumo"
            exact
            component={AnimaisResumo}
          />
          <Route
            path="/Mapas/Animais/Resumo/Detalhes"
            exact
            component={AnimaisDetalhes}
          />
          <Route path="/Mapas/Cochos" component={Cochos} />
          <Route path="/Mapas/Agros" component={Agros} />
        </Switch>
      </PulverizadoProvider>
    </BrowserRouter>
  );
};

export default Routes;
