import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { setDisplay } from '@/Store/Actions/infoDisplayAction';
import api from '@/Services/api';
import Map from '@/Components/Map';
import LoadPage from '@/Components/LoadPage';
import Info from './Components/InfoContainer';
import Subarea from '@/Components/Subarea';
import AppControl from './Components/AppControl';
import { toastWarning } from '@/Utils/toast';

import { Container, GlobalStyle } from './styles';
import { useHistory } from 'react-router-dom';

let interval;

const Realtime = (props) => {
  const [data, setData] = useState([null]);
  const [apps, setApps] = useState([]);
  const [count, setCount] = useState([]);
  const [farms, setFarms] = useState([]);
  const [center, setCenter] = useState([0, 0]);
  const [realtimeData, setRealtimeData] = useState([]);
  const [load, setLoad] = useState(true);
  const [areas, setAreas] = useState([]);

  const token = localStorage.getItem('token');
  const search = props.location.search;

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    verifyData();

    interval = setInterval(() => {
      verifyData();
    }, 50000);

    // eslint-disable-next-line
  }, []);

  async function verifyData() {
    const current_url = window.location.href;
    const isMap = current_url.indexOf('Mapas/Realtime');

    if (isMap < 0) {
      clearInterval(interval);
    }

    try {
      const { data } = await api.get(
        `/Mapas/Realtime/verifyData${search}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      const { realtime, areas } = data;

      setAreas(areas);
      setCenter(areas[0].geo[0][0]);

      setData(realtime);

      if (!realtime.length) {
        toastWarning({
          message: 'Não encontramos dados em tempo real!',
        });
      }

      realtime.map((data) => handleApp(data.Nome_aplicativo));
      realtime.map((data) => handleFarms(data.fazenda));
    } catch (error) {
      console.error(error);

      toastWarning({
        message: 'Não encontramos dados em tempo real!',
      });

      history.push('/Farm/Realtime');
      return;
    }

    setLoad(false);
  }

  async function handleApp(nameApp) {
    const alreadyExists = apps.includes(nameApp);

    if (!alreadyExists) {
      apps.push(nameApp);

      count.push({
        name: nameApp,
        count: 1,
      });
    } else {
      count.map((count) => {
        if (count.name === nameApp) {
          count.name = nameApp;
          count.count = count.count + 1;
        }
      });
    }
  }

  async function handleFarms(nameFarm) {
    const alreadyExists = farms.includes(nameFarm);

    if (!alreadyExists) {
      farms.push(nameFarm);
    }
  }

  function getInfoData(point) {
    setRealtimeData([]);
    handleDispatch(setDisplay(true));
    setRealtimeData(point);
  }

  function handleDispatch(action) {
    dispatch(action);
  }

  if (load) {
    return <LoadPage />;
  }

  return (
    <Container>
      <GlobalStyle />
      <ToastContainer />

      <Map props={{ center, zoom: 13 }}>
        {areas.map((area) => (
          <Subarea
            key={area.id}
            positions={area.geo}
            name={area.subarea}
          />
        ))}

        {data.length && (
          <AppControl
            onClick={(point) => getInfoData(point)}
            realtimeData={data}
            apps={apps}
            appsCount={count}
          />
        )}

        {/* <FarmControl
          onClick={(point) => getInfoData(point)}
          realtimeData={data}
          farms={farms}
        /> */}
      </Map>
      <Info props={realtimeData} />
    </Container>
  );
};

export default Realtime;
