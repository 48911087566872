export const setDisplay = (display) => {
  return {
    type: 'setDisplay',
    display,
  };
};

export const setDisplay2 = (display) => {
  return {
    type: 'setDisplay2',
    display2: display,
  };
};

export const setLoading = (loading) => {
  return {
    type: 'setLoading',
    loading,
  };
};

export const setError = (error) => {
  return {
    type: 'setError',
    error,
  };
};
