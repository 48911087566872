import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

  * {
    padding: 0;
    margin: 0;
    outline: none;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    text-decoration: none;
    user-select:none;
  }

  html,
  body {
    background: #fff;
  }

  html, body, #root{
    max-width: 100vw;
    max-height: 100vh;

    width: 100%;
    height: 100%;
  }

  *::-webkit-scrollbar {
    width: 0.8rem;
    height: 0.8rem;
  }

  *::-webkit-scrollbar-track {
    background-color: #C0C0C0;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #2b5e0a;
  }

  button,
  input {
    font-family: 'Poppins', sans-serif;
  }

  .icon-pulse{
    border-radius: 50%;
    animation: pulse 0.9s linear infinite;
  }

  .icon-pulse-realtime{
    background-color: white;
    border-radius: 50%;
    animation: pulse 0.9s linear infinite;
  }

  .icon-pulse-realtime[alt=ACASALAMENTO]{
    border: 4px solid red;
  }
  .icon-pulse-realtime[alt=ARMADILHA]{
    border: 4px solid #A19DAE;
  }
  .icon-pulse-realtime[alt=COLHEITA]{
    border: 4px solid #FF8D00;
  }
  .icon-pulse-realtime[alt=ERRADICACAO]{
    border: 4px solid #682B2B;
  }
  .icon-pulse-realtime[alt=ESTRIA]{
    border: 4px solid yellow;
  }
  .icon-pulse-realtime[alt=LEITERIA]{
    border: 4px solid #3AADFF;
  }
  .icon-pulse-realtime[alt=MECANIZADO]{
    border: 4px solid #558227;
  }
  .icon-pulse-realtime[alt=METEOROLOGIA]{
    border: 4px solid pink;
  }
  .icon-pulse-realtime[alt=MIPD]{
    border: 4px solid #DA0000;
  }
  .icon-pulse-realtime[alt=MOVIMENTACAO]{
    border: 4px solid #FF6A6A;
  }
  .icon-pulse-realtime[alt=PESAGEM]{
    border: 4px solid brown;
  }
  .icon-pulse-realtime[alt=TRATOS]{
    border: 4px solid gray;
  }

  @keyframes pulse{
    0%{
      box-shadow: 0 0 3px 2px rgba(12, 12, 12, 0.8);    
    }

    50%{
      box-shadow: 0 0 3px 7px rgba(12, 12, 12, 0.4);    
    }

    100%{
      box-shadow: 0 0 3px 10px rgba(12, 12, 12, 0.2);
    }
  }

  .leaflet-control-layers-overlays{
    >label{
      >div{
        display: flex;
        justify-content: center;
        align-items: center;

        >span{
          font-size: 14px;
          font-weight: 600; 
          color: #000;

          margin-left: 6px;
          margin-top: 3px;
        }
      }
    }
  }

  .leaflet-control-layers-overlays >label >div {
    display: flex;
    justify-content: flex-start; 

    >span{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width:100%;

      >img{
        width: 22px;
        height: 22px;
        margin-left: 8px;
      }
    }
  }

  .leaflet-touch.leaflet-touch .leaflet-control-layers {
    border: none;
    align-self: center;
    margin-top: 5px;
  }
  
  .leaflet-touch .leaflet-control-layers-toggle{
    width: 68px;
    height: 30px;
  }

  .leaflet-control-layers-toggle{
    background-image: url(${require('../Assets/tractor.png')});
    background-size: 27px;
  }


  .leaflet-popup-content-wrapper, .leaflet-popup-tip {
    background: #141414;
  }

  /* Resolve problema com ios */
  input,
  input:before,
  input:after {
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }

  :root{
    --primary: #eee;
    --dark-green: rgba(1,13,3,1);
    --green-hover: rgba(3,43,11,0.82);
    --light-green-hover: #00b436;
    --white: #eee;
    --gray: #928f8f;
    --black: #000;

    --good: #32CD32;
    --medium: #d1d300;
    --bad: #b41711;
  }
`;
