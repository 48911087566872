import styled from 'styled-components';

import { FaArrowLeft } from '../../Styles/Icons';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ArrowLeftIcon = styled(FaArrowLeft)`
  fill: var(--white);
  width: 26px;
  height: 26px;
  margin-right: 20px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    fill: var(--light-green-hover);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h1`
  margin: 70px 0 50px 0;
`;

export const AppsContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 600px;
  max-height: 100%;

  padding: 10px 20px;
  overflow-y: auto;
`;

export const App = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 65px;

  padding: 5px 10px;
  border-radius: 6px;
  border: 2px solid #05ce08;

  background: rgba(7, 126, 9, 0.1);
  box-shadow: -5px 5px 8px rgb(0, 0, 0, 0.5);
  flex-shrink: 0;

  & + div {
    margin-top: 50px;
  }

  > span {
    color: var(--black);
    font-size: 20px;
    font-weight: 500px;
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 35px;
    width: 80px;
    padding: 8px;
    border-radius: 6px;
    background: #05ce08;

    font-weight: 600;
    color: var(--white);
    transition: 0.3s;

    &:hover {
      background: #036b04;
    }
  }
`;
