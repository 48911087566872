import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TableContainer, Paper, TableHead } from '@material-ui/core';

import api from '@/Services/api';
import Header from '@/Components/Header';
import formatDate from '@/Utils/formatDate';
import LoadPage from '@/Components/LoadPage';
import DecimalNumber from '@/Components/DecimalNumber';

import Cell from './Components/Cell';
import Row from './Components/Row';

import {
  Container,
  Content,
  OpGroup,
  Description,
  TitleCell,
  Table,
  TBody,
  FilterArea,
  Filters,
} from './styles';

function Manage() {
  const [ops, setOps] = useState([]);
  const [total, setTotal] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [data, setData] = useState([]);
  const [selectedCultura, setSelectedCultura] = useState('');
  const [selectedSafra, setSelectedSafra] = useState('');
  const [selectedSituation, setSelectedSituation] = useState('');

  const token = localStorage.getItem('token');
  const history = useHistory();

  useEffect(() => {
    api
      .get(`/Mapas/Agros/getGestao`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => setData(data));
  }, [token]);

  useEffect(() => {
    api
      .get(
        `/Orcamentos?cultura=${selectedCultura}&safra=${selectedSafra}&situation=${selectedSituation}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then(({ data }) => {
        setTotal(data.total);
        setOps(data.ops);
        setProdutos(data.produtos);
      });
  }, [token, selectedCultura, selectedSafra, selectedSituation]);

  function goToDetails(produto) {
    history.push(
      `/Gestao/Produto?produto=${produto}&cultura=${selectedCultura}`
    );
  }

  if (total.length === 0) {
    return <LoadPage />;
  }

  return (
    <Container>
      <Header />
      <Content>
        <Filters>
          <FilterArea>
            <strong>Safra</strong>

            <select
              onChange={(e) => setSelectedSafra(e.target.value)}
            >
              <option value="">Todas</option>
              {data.safras.map(({ safra }) => (
                <option key={safra} value={safra}>
                  {safra}
                </option>
              ))}
            </select>
          </FilterArea>

          <FilterArea>
            <strong>Cultura</strong>

            <select
              onChange={(e) => setSelectedCultura(e.target.value)}
            >
              <option value="">Todas</option>
              {data.culturas.map((cultura) => (
                <option key={cultura.nome} value={cultura.nome}>
                  {cultura.nome}
                </option>
              ))}
            </select>
          </FilterArea>

          <FilterArea>
            <strong>Situação</strong>

            <select
              onChange={(e) => setSelectedSituation(e.target.value)}
            >
              <option value="">Todas</option>
              <option value="aberta">Aberta</option>
              <option value="fechada">Fechada</option>
            </select>
          </FilterArea>
        </Filters>

        <OpGroup>
          <Description>Total</Description>

          <TableContainer component={Paper}>
            <Table
              aria-label="customized table"
              style={{ background: 'rgb(199 199 199 / 15%)' }}
            >
              <TableHead>
                <Row>
                  <TitleCell align="center">Situação</TitleCell>
                  <TitleCell align="center">ha</TitleCell>
                  <TitleCell align="center">Orçado</TitleCell>
                  <TitleCell align="center">Realizado</TitleCell>
                  <TitleCell align="center">Receita</TitleCell>
                  <TitleCell align="center">Resultado</TitleCell>
                  <TitleCell align="center">R$ Orçado / ha</TitleCell>
                  <TitleCell align="center">
                    R$ Realizado / ha
                  </TitleCell>
                  <TitleCell align="center">
                    R$ Receita / ha
                  </TitleCell>
                  <TitleCell align="center">
                    R$ Resultado / ha
                  </TitleCell>
                </Row>
              </TableHead>

              <TBody>
                <Row>
                  <TitleCell align="center">Aberta</TitleCell>
                  <Cell align="center">{total.hectares}</Cell>
                  <Cell align="center">{total.orcado}</Cell>
                  <Cell align="center">{total.custo_realizado}</Cell>
                  <Cell align="center">{total.receita}</Cell>
                  <Cell align="center">
                    {total.receita - total.custo_realizado}
                  </Cell>

                  <Cell align="center">
                    {total.orcado / total.hectares}
                  </Cell>

                  <Cell align="center">
                    {total.custo_realizado / total.hectares}
                  </Cell>

                  <Cell align="center">
                    {total.receita / total.hectares}
                  </Cell>

                  <Cell align="center">
                    {(total.receita - total.custo_realizado) /
                      total.hectares}
                  </Cell>
                </Row>
              </TBody>
            </Table>
          </TableContainer>
        </OpGroup>

        <OpGroup>
          <Description>Ordem de Produção</Description>

          <TableContainer component={Paper}>
            <Table
              aria-label="customized table"
              style={{ background: 'rgb(199 199 199 / 15%)' }}
            >
              <TableHead>
                <Row>
                  <TitleCell align="center">OP</TitleCell>
                  <TitleCell align="center">ha</TitleCell>
                  <TitleCell align="center">Orçado</TitleCell>
                  <TitleCell align="center">Realizado</TitleCell>
                  <TitleCell align="center">Receita</TitleCell>
                  <TitleCell align="center">Resultado</TitleCell>
                  <TitleCell align="center">R$ Orçado / ha</TitleCell>
                  <TitleCell align="center">
                    R$ Realizado / ha
                  </TitleCell>
                  <TitleCell align="center">
                    R$ Receita / ha
                  </TitleCell>
                  <TitleCell align="center">
                    R$ Resultado / ha
                  </TitleCell>
                </Row>
              </TableHead>

              <TBody>
                {ops.map((op) => (
                  <Row key={op.opNome}>
                    <TitleCell align="center">{op.opNome}</TitleCell>
                    <Cell align="center">{op.areaUsada}</Cell>
                    <Cell align="center">{op.totalOrcado}</Cell>
                    <Cell align="center">{op.custoTotal}</Cell>
                    <Cell align="center">{op.totalReceita}</Cell>
                    <Cell align="center">
                      {op.totalReceita - op.custoTotal}
                    </Cell>

                    <Cell align="center">
                      {op.totalOrcado / op.areaUsada}
                    </Cell>

                    <Cell align="center">
                      {op.custoTotal / op.areaUsada}
                    </Cell>

                    <Cell align="center">
                      {op.totalReceita / op.areaUsada}
                    </Cell>

                    <Cell align="center">
                      {(op.totalReceita - op.custoTotal) /
                        op.areaUsada}
                    </Cell>
                  </Row>
                ))}
              </TBody>
            </Table>
          </TableContainer>
        </OpGroup>

        <OpGroup>
          <Description>Produtos</Description>

          <TableContainer component={Paper}>
            <Table
              aria-label="customized table"
              style={{ background: 'rgb(199 199 199 / 15%)' }}
            >
              <TableHead>
                <Row>
                  <TitleCell align="center">Produto</TitleCell>
                  <TitleCell align="center">Produção</TitleCell>
                  <TitleCell align="center">
                    Custo / Produção
                  </TitleCell>
                  <TitleCell align="center">QTD / ha</TitleCell>
                  <TitleCell align="center">Estoque</TitleCell>
                  <TitleCell align="center">Valor Estoque</TitleCell>
                  <TitleCell align="center">Safra</TitleCell>
                  <TitleCell align="center">Encerramento</TitleCell>
                </Row>
              </TableHead>

              <TBody>
                {produtos.map((produto) => (
                  <Row
                    onClick={() => goToDetails(produto.produto)}
                    clickable="true"
                    key={produto.produto}
                  >
                    <Cell>{produto.produtoNome}</Cell>
                    <Cell>
                      <DecimalNumber>
                        {produto.qtdeBrutaComercial}
                      </DecimalNumber>{' '}
                      {produto.unidade === 'Quilograma'
                        ? 'Kg'
                        : produto.unidade}
                    </Cell>

                    <Cell>{produto.custoQtdeComercial}</Cell>
                    <Cell>{produto.qtdeBrutaComercialha}</Cell>
                    <Cell>{produto.unidEstoque}</Cell>
                    <Cell>{produto.total}</Cell>
                    <Cell>{produto.safra}</Cell>
                    <Cell>
                      {produto.encerramento
                        ? formatDate(produto.encerramento)
                        : '---'}
                    </Cell>
                  </Row>
                ))}
              </TBody>
            </Table>
          </TableContainer>
        </OpGroup>
      </Content>
    </Container>
  );
}

export default Manage;
