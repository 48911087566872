import moment from 'moment-timezone';

export function formatHourToSql(date) {
  const hour = moment(date)
    .tz('America/Sao_Paulo')
    .add(3, 'hours')
    .format('HH:mm');

  return hour;
}
