import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Container from '../../../../Components/PreMapContainer';
import StyledButton from '../../../../Components/Buttons';
import FarmsContainer from '../../../../Components/FarmsContainer';
import { toastWarning } from '../../../../Utils/toast';

function MyAreas() {
  const history = useHistory();

  const selectedFarms = useSelector(
    (state) => state.selectedFarms.farms
  );

  function handleSubmit() {
    if (selectedFarms.length === 0) {
      toastWarning({ message: 'Selecione uma fazenda!' });
    }

    history.push(`/Mapas/Minhas-Areas?farms=${selectedFarms}`);

    /* api
      .get(`/Mapas/Minhas-Areas/verifyData?farms=${selectedFarms}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (!response.data) {
          alert('Não encontramos nenhum dado!');
        } else {
          history.push(`/Mapas/Minhas-Areas?farms=${selectedFarms}`);
        }
      }); */
  }

  return (
    <Container>
      <ToastContainer />

      <FarmsContainer>
        <StyledButton onClick={handleSubmit}>Carregar</StyledButton>
      </FarmsContainer>
    </Container>
  );
}

export default MyAreas;
