import styled from 'styled-components';
import { Polyline } from 'react-leaflet';
import { createGlobalStyle } from 'styled-components';

export const Container = styled.div``;

export const Line = styled(Polyline)`
  stroke-width: 8px;
  stroke: ${(props) => {
    if (props.color === 'BAIXO') return 'var(--good)';
    else if (props.color === 'MÉDIO') return 'var(--medium)';
    else return 'var(--bad)';
  }};
`;

export const GlobalStyle = createGlobalStyle`
  .leaflet-control-layers-overlays{
    >label div{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .leaflet-control-layers-toggle{
    background-image: url(${require('../../../Assets/praga.png')});
    background-size: 25px;
  }

  .leaflet-control-layers{
    top: 30px;
  }

  .identifier {
    width: 20px;
    height: 20px;

    margin-right: 8px;
    border-radius: 4px;
  }

  #tractorIdentifier{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #333;

    margin-left: 15px;
  }
`;

export const Filters = styled.div`
  position: absolute;
  top: 80px;
  left: 10px;
  padding: 10px 8px 10px 6px;
  height: 62px;
  background: #fff;

  border-radius: 5px;
  gap: 4px;

  display: flex;

  overflow: hidden;
  z-index: 900;

  h3 {
    font-size: 15px;
    margin-top: -4px;
  }
`;
