import styled from 'styled-components';

const BackButtonMap = styled.button`
  position: absolute;
  bottom: 10px;
  left: 10px;

  z-index: 1000;
  cursor: pointer;
  transition: 0.4s;

  border-radius: 8px;
  padding: 10px;
  border: 2px solid var(--dark-green);
  background: var(--dark-green);
  color: var(--white);
  font-weight: 600;

  &:hover {
    opacity: 0.85;
    border: 2px solid var(--light-green-hover);
  }

  > svg {
    margin-right: 8px;
  }
`;

export default BackButtonMap;
