import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: 70px;

  @media (max-width: 700px) {
    padding: 70px 15px 30px;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;

  margin-bottom: 30px;
  width: 100%;
  min-height: 100px;
`;

export const Resumo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 80vh;
  overflow: auto;
  border-radius: 10px;
  border: 1px solid var(--dark-green);
`;

export const Header = styled.div`
  width: 100%;
  display: flex;

  > strong {
    color: #fff;
    background: var(--dark-green);
    width: 100%;
    min-width: 120px;
    padding: 8px;
    display: flex;
    justify-content: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const Amostra = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;

  > span {
    width: 100%;
    min-width: 120px;
    padding: 8px;
    display: flex;
    justify-content: center;
    text-align: center;
    transition: 0.2s;
  }

  &:hover,
  &:nth-child(even):hover {
    > span {
      background: #444;
      color: #fff;
    }
  }

  &:nth-child(even) {
    > span {
      background: #bbb;
    }
  }
`;

export const Title = styled.h1`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 25px;
`;
