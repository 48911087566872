import React from 'react';

import { Container, Identifier, Column } from './styles';

function IntervalValues() {
  return (
    <Container>
      <Column>
        <Identifier color="#1ee201">
          <div></div>
          <span>+2.01</span>
        </Identifier>

        <Identifier color="#ff7f00">
          <div></div>
          <span>0.51 - 1.0</span>
        </Identifier>
      </Column>

      <Column>
        <Identifier color="#ace201">
          <div></div>
          <span>1.51 - 2.0</span>
        </Identifier>

        <Identifier color="#ff0000">
          <div></div>
          <span>0 - 0.5</span>
        </Identifier>
      </Column>

      <Column>
        <Identifier color="#ff0">
          <div></div>
          <span>1.01 - 1.5</span>
        </Identifier>
      </Column>
    </Container>
  );
}

export default IntervalValues;
