import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import LoadSpinner from '../LoadSpinner';

import { Container, Loading } from './styles';

function LoadPage({ message }) {
  const history = useHistory();
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (!token) history.push('/Login');
  }, [history, token]);

  return (
    <Container>
      <Loading>
        {message || 'Carregando'}
        <LoadSpinner props={{ size: 36 }} />
      </Loading>
    </Container>
  );
}

export default LoadPage;
