import api from '@/Services/api';
import React, { useState } from 'react';
import { createContext } from 'react';

export const pulverizadoContext = createContext({});

export default function PulverizadoProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [filter, setFilter] = useState({});
  const [data, setData] = useState([]);

  function turnActive() {
    setIsActive(true);
  }

  function turnNotActive() {
    setIsActive(false);
  }

  function turnLoadingOn() {
    setIsLoading(true);

    setTimeout(() => {
      turnLoadingOff();
    }, 3000);
  }

  function turnLoadingOff() {
    setIsLoading(false);
  }

  function getInfoData(fazenda, subarea, os, praga, date) {
    turnLoadingOn();
    turnActive();

    const token = localStorage.getItem('token');

    if ((!filter.subarea || filter.subarea !== subarea) && subarea) {
      setFilter({
        fazenda,
        subarea,
        praga,
        date,
        os,
      });
    }

    api
      .get(
        `/Mapas/Pulverizado/getInfoData?fazenda=${
          fazenda ? fazenda : filter.fazenda
        }&subarea=${subarea ? subarea : filter.subarea}&os=${
          os ? os : filter.os
        }&praga=${praga ? praga : filter.praga}&date=${
          date ? date : filter.date
        }`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setData(response.data);
        turnLoadingOff();
      });
  }

  function getLastInfo() {
    turnLoadingOn();
    const token = localStorage.getItem('token');

    api
      .get(
        `/Mapas/Pulverizado/getInfoData?fazenda=${filter.fazenda}&subarea=${filter.subarea}&praga=${filter.praga}&situacao=Finalizada&date=${filter.date}&os=${filter.os}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then(({ data }) => {
        setData(data);
        turnLoadingOff();
      });
  }

  return (
    <pulverizadoContext.Provider
      value={{
        isLoading,
        isActive,
        data,
        filter,
        turnActive,
        getLastInfo,
        turnNotActive,
        getInfoData,
        turnLoadingOn,
        turnLoadingOff,
      }}
    >
      {children}
    </pulverizadoContext.Provider>
  );
}
