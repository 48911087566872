import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Container from '../../../../Components/PreMapContainer';
import FarmsContainer from '../../../../Components/FarmsContainer';
import Button from '../../../../Components/Buttons';
import { toastWarning } from '../../../../Utils/toast';

import { SubmitArea, InputContainer, InputGroup } from './styles';
import api from '../../../../Services/api';

function Animais() {
  const [data, setData] = useState({});
  const [selectedEspecie, setSelectedEspecie] = useState('');
  const [selectedRaca, setSelectedRaca] = useState('');

  const token = localStorage.getItem('token');
  const history = useHistory();
  const selectedFarms = useSelector(
    (state) => state.selectedFarms.farms
  );

  useEffect(() => {
    api
      .get('/Mapas/Animais', {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setData(response.data);
        setSelectedRaca(
          response.data?.racas.map((raca) => raca?.raca)
        );
        setSelectedEspecie(response.data?.especies[0]?.especie);
      });
  }, [token]);

  function handleSubmit() {
    if (selectedFarms.length === 0) {
      toastWarning({ message: 'Selecione uma fazenda!' });
    } else if (selectedEspecie === '') {
      toastWarning({ message: 'Selecione uma espécie!' });
    } else if (selectedRaca === '') {
      toastWarning({ message: 'Selecione uma raça!' });
    } else {
      api
        .get(
          `/Mapas/Animais/verifyData?especie=${selectedEspecie}&raca=${selectedRaca}&farms=${selectedFarms}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (!response.data) {
            toastWarning({ message: 'Não encontramos nenhum dado!' });
          } else {
            history.push(
              `/Mapas/Animais?especie=${selectedEspecie}&raca=${selectedRaca}&farms=${selectedFarms}`
            );
          }
        });
    }
  }

  return (
    <Container>
      <ToastContainer />

      <FarmsContainer>
        <SubmitArea>
          <InputContainer>
            <InputGroup>
              <strong>Espécie</strong>
              <select
                onChange={(e) => setSelectedEspecie(e.target.value)}
              >
                {data.especies &&
                  data.especies.map((especie) => (
                    <option
                      key={especie.especie}
                      value={especie.especie}
                    >
                      {especie.especie}
                    </option>
                  ))}
              </select>
            </InputGroup>
            <InputGroup>
              <strong>Raça</strong>
              <select
                onChange={(e) => setSelectedRaca(e.target.value)}
              >
                <option value="">Todas</option>
                {data.racas &&
                  data.racas.map((raca) => (
                    <option key={raca.raca} value={raca.raca}>
                      {raca.raca}
                    </option>
                  ))}
              </select>
            </InputGroup>
          </InputContainer>
          <Button onClick={handleSubmit}>Carregar Mapa</Button>
        </SubmitArea>
      </FarmsContainer>
    </Container>
  );
}

export default Animais;
