import styled from 'styled-components';
import { TableBody, TableCell } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TBody = styled(TableBody)`
  > tr:nth-child(even) {
    background: #ddd;
  }
`;

export const TitleCell = styled(TableCell)`
  white-space: nowrap;
`;

export const BackArea = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  margin-bottom: 30px;

  justify-content: flex-start;
`;

export const Button = styled.button`
  background: #fff;
  color: var(--light-green-hover);
  font-weight: bold;
  border: 1px solid var(--light-green-hover);
  border-radius: 5px;
  padding: 10px;

  cursor: pointer;

  &:hover {
    background: rgb(0, 180, 54, 0.3);
  }
`;
