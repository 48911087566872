import React from 'react';
import { Popup, Tooltip } from 'react-leaflet';
import { Link } from 'react-router-dom';

import Button from '../Buttons';

import {
  Container,
  Identifier,
  TooltipContainer,
  TooltipTriangle,
} from './styles';

function Tree({ center, id = 0, qtd = 0, props }) {
  return (
    <Container radius={12} center={center} qtd={qtd}>
      <Popup>
        <Link
          to={`/Mapas/MIP/Resumo?id=${id}&subarea=${props?.subarea}&data=${props?.data}&praga=${props?.praga}&metodo=${props?.metodo}`}
          target="blank"
        >
          <Button>Ver Resumo</Button>
        </Link>
      </Popup>

      {props?.status === 'Pós Pulverização' ? (
        <Tooltip permanent direction="center">
          <TooltipContainer>
            <TooltipTriangle qtd={qtd} id={id}>
              <span>{id}</span>
            </TooltipTriangle>
          </TooltipContainer>
        </Tooltip>
      ) : (
        <Identifier permanent direction="center" qtd={qtd}>
          {id}
        </Identifier>
      )}
    </Container>
  );
}

export default Tree;
