import React, { useEffect, useState } from 'react';

import api from '@/Services/api';
import Map from '@/Components/Map';
import LoadPage from '@/Components/LoadPage';

import { Container, Subtitle, Area } from './styles';

function MyAreas(props) {
  const [areas, setAreas] = useState([]);
  const [center, setCenter] = useState([0, 0]);

  const token = localStorage.getItem('token');
  const search = props.location.search;

  useEffect(() => {
    api
      .get(`/Mapas/Minhas-Areas${search}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setCenter(response.data[0].geo[0][0]);
        setAreas(response.data);
      });

    //eslint-disable-next-line
  }, [token]);

  if (areas.length === 0) {
    return <LoadPage />;
  }

  return (
    <Container>
      <Map props={{ zoom: 15, center }}>
        {areas.map((area) => (
          <Area key={String(area.subarea)} positions={area.geo}>
            <Subtitle>{area.subarea}</Subtitle>
          </Area>
        ))}
      </Map>
    </Container>
  );
}

export default MyAreas;
