import styled from 'styled-components';
import { Tooltip, Polygon } from 'react-leaflet';

export const Container = styled.div`
  height: 100vh;
`;

export const Area = styled(Polygon).attrs((props) => ({}))`
  fill: #25054c;
  fill-opacity: 0.6;
  stroke: #190236;
`;

export const Subtitle = styled(Tooltip).attrs((props) => ({
  permanent: true,
  direction: 'center',
}))`
  background: rgb(0, 0, 0, 0.2);
  border: 1px solid #000;
  color: #fff;
  font-weight: bold;
`;
